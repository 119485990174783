import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
} from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";

import { Add, Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import api from "../../../../apis";
import FieldAsyncSelect from "../../../form-fields/FieldAsyncSelect";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import SubmitBtn from "../../../SubmitBtn";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldDatePicker from "../../../form-fields/FieldDatePicker";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import { useQuery } from "react-query";

export default function Hr03VerifyAccountActiveSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  usersData,
  setOpen,
  type,
  filter,
  total,
}) {
  const export_condition = useSelector(
    (state) => state.auth.user.export_condi ?? []
  );
  const verify_request_account_HR03_process = useSelector(
    (state) =>
      state.auth.user.permissions.verify_request_account_HR03_process ?? []
  );
  const [anchorElDowload, setAnchorElDowload] = useState(null);
  const openDowload = Boolean(anchorElDowload);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  // const downloadFile = useCallback(async (fileUrl) => {
  //   try {
  //     setAnchorElDowload(null);
  //     // setIsLoading(true);
  //     const res = await api.get(fileUrl, {
  //       responseType: "blob",
  //     });

  //     const url = window.URL.createObjectURL(new Blob([res.data]));
  //     // Create a temporary link element
  //     const link = document.createElement("a");
  //     link.href = url;
  //     let headerLine = res.headers["content-disposition"];
  //     let rawFilename = headerLine.split("filename=")[1];
  //     let filename = rawFilename.replace(/[^A-Za-z0-9.]/g, "");
  //     link.download = filename;
  //     document.body.appendChild(link);
  //     link.click();
  //     // Clean up
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     setIsError(true);
  //   }
  //   // finally {
  //   //   setIsLoading(false);
  //   // }
  // }, []);

  const previewFiles = useCallback(
    async (fileUrl) => {
      try {
        const res = await api.get(fileUrl, {
          params: filter,
          responseType: "blob",
        });

        // ดึง content-type จาก header
        const contentType = res.headers["content-type"];
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: contentType })
        );

        // ตรวจสอบประเภทไฟล์จาก content-type
        if (contentType === "application/pdf") {
          // กรณีเป็น PDF
          const pdfWindow = window.open(url);
          pdfWindow.onload = () => {
            pdfWindow.print();
          };
        } else {
          // กรณีเป็นไฟล์ประเภทอื่น
          const link = document.createElement("a");
          link.href = url;

          const contentDisposition = res.headers["content-disposition"];
          const filename = /filename=.+/.exec(contentDisposition)
            ? decodeURI(/filename="?([^"]+)"?/.exec(contentDisposition)[1])
            : "download";
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.log("error=>", error);
      }
    },
    [filter]
  );
  const { data: requestTypeOptions } = useQuery(["requestTypeOptions"], () =>
    api.get("/api/openbankaccount/type_request").then((res) =>
      res.data.map((row) => ({
        value: row.create_form_type,
        label: row.name,
      }))
    )
  );
  const { data: statusOptions } = useQuery(["statusOptions"], () =>
    api.get("/api/openbankaccount/status").then((res) =>
      Object.entries(res.data).map((el) => ({
        value: el[0],
        label: el[1],
      }))
    )
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ไม่สามารถดาวน์โหลดไฟล์ได้ เนื่องจากไฟล์ไม่มีอยู่ในระบบ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsError(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {verify_request_account_HR03_process.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item xs={12} md={4} lg={3}>
            <FieldSelect
              name="request_type"
              label="ประเภทคำร้อง"
              options={requestTypeOptions}
              controlProp={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FieldAutoComplete
              name="status"
              label="สถานะ"
              options={statusOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FieldAsyncAutoComplete
              name="policestation_org_code"
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
              inputProps={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <FieldAsyncSelect
              name="bank_id"
              label="ธนาคาร"
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              basePath={`/api/banks`}
              // filter={{ type: "bank" }}
              itemFormat={(row) => {
                return {
                  value: row.id,
                  label: `${row.name} (${row.short_name})`,
                };
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={3} xl={3}>
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={3}>
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={3}>
            <FieldInput
              name="number"
              label="เลขบัญชีธนาคาร / เลขบัตรประชาชน"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={3}>
            <FieldInput
              name="search"
              label="คำค้นหา"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} mb={2}>
        <Grid item md={12} lg={4}>
          {verify_request_account_HR03_process.includes("search") && (
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid item md={12} lg={8}>
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {verify_request_account_HR03_process.includes("download") && (
              <LoadingButton
                variant="outlined"
                startIcon={<Download />}
                color="primary"
                loadingPosition="start"
                onClick={handleClickDowload}
                aria-controls={openDowload ? "dowload-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDowload ? "true" : undefined}
                disabled={total === 0 || total < export_condition.min_download || total > export_condition.max_download}

              >
                ดาวน์โหลด
              </LoadingButton>
            )}
            <Menu
              id="dowload-menu"
              anchorEl={anchorElDowload}
              open={openDowload}
              onClose={handleCloseDownload}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {
                  if (type === "verify") {
                    await previewFiles(
                      `api/openbankaccount/process?export=xlsx`
                    );
                  } else {
                    await previewFiles(
                      `/api/openbankaccount/requests?export=xlsx`
                    );
                  }
                }}
              >
                xlsx
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  if (type === "verify") {
                    await previewFiles(
                      `api/openbankaccount/process?export=csv`
                    );
                  } else {
                    await previewFiles(
                      `/api/openbankaccount/requests?export=csv`
                    );
                  }
                }}
              >
                csv
              </MenuItem>
            </Menu>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}
