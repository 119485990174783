import { HowToReg, Login } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  CssBaseline,
  Divider,
  Grid,
  Link,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { MdAppRegistration } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";

import api, { setAuthorizationToken } from "../../../apis";
import { setAuthorizationToken as setAuthorizationToken2 } from "../../../apis/apigw";
import { authFetch } from "../../../ducks/auth";
import LocalStorageService from "../../../helpers/LocalStorageService";
import AlertIconDialog from "../../dialogs/AlertIconDialog";
import WarningDialog from "../../dialogs/WarningDialog";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";

const schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  rank: Joi.string().max(255).required().messages({
    "string.empty": "กรุณาระบุยศ",
    "any.required": "กรุณาระบุยศ",
  }),
  fname: Joi.string().max(255).required().messages({
    "any.required": "กรุณาระบุชื่อ",
  }),
  lname: Joi.string().max(255).required().messages({
    "any.required": "กรุณาระบุนามสกุล",
  }),
  job_title: Joi.string().max(255).required().messages({
    "string.empty": "กรุณาระบุตำแหน่ง",
    "any.required": "กรุณาระบุตำแหน่ง",
  }),
  policestation_org_code: Joi.string()
    .pattern(/^\d{5}$/)
    .required()
    .messages({
      "string.empty": "กรุณาระบุหน่วยงาน",
      "any.required": "กรุณาระบุหน่วยงาน",
    }),

  pol_tel: Joi.string()
    .pattern(/^\d{10}$/)
    .required()
    .messages({
      "string.empty": "กรุณาระบุเบอร์โทร",
      "any.required": "กรุณาระบุเบอร์โทร",
    }),
  state: Joi.string(),
}); //.error(validationMsg());

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // const allowedEmaiTlds = [
  //   "police.go.th",
  //   "royalthaipolice.go.th",
  //   "rtp.go.th",
  //   "koder3.com",
  //   "gmail.com",
  // ];
  // const email = values.email;

  // if (email != null) {
  //   // Extract the TLD from the email
  //   const emailTldMatch = email.match(/@(.+)$/);
  //   const emailTld = emailTldMatch ? emailTldMatch[1] : null;

  //   if (!errors.email && !allowedEmaiTlds.includes(emailTld)) {
  //     errors.email =
  //       "รองรับอีเมล @police.go.th,@royalthaipolice.go.th หรือ @rtp.go.th เท่านั้น";
  //   }
  // }

  return errors;
};

export default function RegisterPage() {
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const rawQuery = location.search.replace(/&amp;/g, "&");
  const searchParams = new URLSearchParams(rawQuery);

  const [state, setState] = useState();
  searchParams.get("state");
  searchParams.get("email");
  searchParams.get("fname");
  searchParams.get("lname");
  searchParams.get("rank");
  searchParams.get("job_title");
  searchParams.get("policestation_org_code");

  const [error, setError] = useState(false);

  const handleClose = () => {
    setError(false);
    setSuccess(false);
  };

  const handleLogin = () => {
    dispatch(authFetch());
    navigate("/me");
  };

  const onSubmit = (formValues) => {
    return api
      .post(state ? `/api/thaiid/registor ` : `/api/register`, {
        // state,
        ...formValues,
      })
      .then((response) => {
        // setSuccess(formValues.email);
        if (response.data.is_authen) {
          LocalStorageService.setToken(response.data);
          LocalStorageService.setUsername(formValues.email);
          setAuthorizationToken(LocalStorageService.getAccessToken());
          setAuthorizationToken2(LocalStorageService.getIaccessToken());
          setSuccess(true);
        }
        setError(response.data.message);
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  useEffect(() => {
    setState(Object.fromEntries(searchParams).state);
  }, [searchParams]);
  // แปลงเป็น object แล้วลบ key ที่ไม่ต้องการ
  const queryObject = Object.fromEntries(searchParams);
  delete queryObject.birthday;

  return (
    <div className="grid justify-center items-center bg-[#F5F5F5]">
      <CssBaseline />
      <WarningDialog error={error} onClose={handleClose} />
      <AlertIconDialog
        open={success}
        onClose={handleClose}
        title="ลงทะเบียนสำเร็จ"
        maxWidth="sm"
        isHideDialogActions
      >
        <Grid container justifyContent={"center"}>
          <Button
            onClick={handleLogin}
            variant="outlined"
            startIcon={<Login />}
          >
            เข้าสู่ระบบ
          </Button>
        </Grid>
      </AlertIconDialog>
      <div className="grid justify-center items-center lg:mx-64 md:mx-12 md:my-16 bg-white p-12 md:rounded-[32px] relative">
        <div className="md:absolute md:top-0 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-14 md:p-1 grid justify-center items-center">
          <MdAppRegistration className="h-20 w-20 bg-[#1976D3] rounded-full p-3 text-white" />
        </div>
        <h1 className="text-[32px] text-center">ลงทะเบียน</h1>

        <Form
          validate={validate}
          onSubmit={onSubmit}
          initialValues={queryObject}
        >
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            return (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                {submitFailed && (error || submitError) && (
                  <Alert severity="warning" sx={{ width: "100%" }}>
                    {error || submitError}
                  </Alert>
                )}
                <FieldInput
                  name="email"
                  label="Email"
                  required
                  controlProps={{ fullWidth: true, margin: "normal" }}
                  //   inputProps={{
                  //     placeholder: "email@police.go.th",
                  //   }}
                  helperText={
                    <>
                      รองรับอีเมล @police.go.th, @royalthaipolice.go.th หรือ
                      @rtp.go.th เท่านั้น{" "}
                      <Link
                        href="https://register.police.go.th/?q=register"
                        target="_blank"
                        rel="noopener"
                      >
                        (สมัครอีเมล police)
                      </Link>
                    </>
                  }
                  disabled={Object.fromEntries(searchParams).email}
                  readOnly={Object.fromEntries(searchParams).email}
                />
                {/* <FieldInput
                    name="password"
                    label="รหัสผ่าน"
                    required
                    controlProps={{
                      fullWidth: true,
                      margin: "normal",
                      type: "password",
                    }}
                    inputType="password"
                    helperText={
                      <>
                        ต้องกำหนดรหัสผ่านอย่างน้อย 8 ตัวอักษร ประกอบด้วยตัวอักษร{" "}
                        <br />
                        ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่ ตัวเลข
                        และตัวอักขระพิเศษได้
                      </>
                    }
                  />
                  <FieldInput
                    name="confirm_password"
                    label="ยืนยันรหัสผ่าน"
                    required
                    controlProps={{
                      fullWidth: true,
                      margin: "normal",
                      type: "password",
                    }}
                    inputType="password"
                    helperText={
                      "รหัสผ่านใหม่ กับ ยืนยันรหัสผ่านใหม่ ต้องตรงกันเท่านั้น"
                    }
                  /> */}
                <Divider textAlign="center" sx={{ mb: 2, mt: 2 }}>
                  ข้อมูลผู้ใช้
                </Divider>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} md={6}>
                    <FieldInput
                      name="cardId"
                      label="เลขบัตรประชาชน"
                      controlProps={{ fullWidth: true }}
                      inputProps={{ placeholder: "เลขบัตรประชาชน(CardID)" }}
                      required
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <FieldInput
                      name="rank"
                      label="ยศ"
                      controlProps={{ fullWidth: true }}
                      inputProps={{ placeholder: "คำเต็ม เช่น พันตำรวจโท" }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldInput
                      name="fname"
                      label="ชื่อ"
                      controlProps={{ fullWidth: true }}
                      required
                      // disabled={Object.fromEntries(searchParams).fname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldInput
                      name="lname"
                      label="สกุล"
                      controlProps={{ fullWidth: true }}
                      required
                      // disabled={Object.fromEntries(searchParams).lname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldInput
                      name="job_title"
                      label="ตำแหน่ง"
                      controlProps={{ fullWidth: true }}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FieldAsyncAutoComplete
                      name="policestation_org_code"
                      label="หน่วยงาน"
                      required
                      controlProp={{ fullWidth: true }}
                      basePath="/api/registor/policestation"
                      requestParams={{
                        page_size: undefined,
                        page: undefined,
                      }}
                      itemFormat={(row) => {
                        return { value: row.ORG_CODE, label: row.ORG_ABBR };
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FieldInput
                      name="pol_tel"
                      label="โทรศัพท์"
                      required
                      controlProps={{ fullWidth: true }}
                    />
                  </Grid>
                </Grid>
                <SubmitBtn
                  variant="contained"
                  submitting={submitting}
                  fullWidth
                  sx={{ mt: 3, mb: 2 }}
                  startIcon={<HowToReg />}
                  size="large"
                >
                  ลงทะเบียน
                </SubmitBtn>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/login" variant="body2">
                      กลับไปหน้าเข้าสู่ระบบ
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        </Form>
      </div>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </div>
  );
}
