import {
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Form } from "react-final-form";
import validationMsg from "../../../../../helpers/validationMsg";
import Hro3UploadFile from "../../../../dialogs/Hro3UploadFile";
import api from "../../../../../apis";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { validate } from "../../../../dialogs/Hro3UploadFile";
import { useSelector } from "react-redux";

const Hr03ModalUpload = ({
  open,
  setOpen,
  id,
  status,
  statusReject,
  setReload,
  handleCloseModal,
  loading,
  setLoading,
  typeCurrent,
  setAddFile,
  handleAddFile,
}) => {
  const user = useSelector((state) => state.auth.user);
  const handleAddClose = () => setOpen(false);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [titleError, setTitleError] = useState("พบปัญหากรุณาลองใหม่อีกครั้ง");

  const [formRef, setFormRef] = useState(null);

  useEffect(() => {
    if (!open && formRef) {
      formRef.reset(); // รีเซ็ตค่าฟอร์มเมื่อ Modal ถูกปิด
    }
  }, [open, formRef]);

  useEffect(() => {
    console.log("asassds=>", user);
  }, [user]);

  const calculateFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024; // แปลงเป็น KB
    const sizeInMB = sizeInBytes / (1024 * 1024); // แปลงเป็น MB

    // ถ้าขนาดไฟล์น้อยกว่า 1 MB ให้แสดงเป็น KB
    if (sizeInMB < 1) {
      return `${sizeInKB.toFixed(2)} KB`; // ปัดเศษเป็นทศนิยม 2 ตำแหน่ง
    }

    // ถ้าขนาดไฟล์มากกว่าหรือเท่ากับ 1 MB ให้แสดงเป็น MB
    return `${sizeInMB.toFixed(2)} MB`;
  };

  const initialValues = useMemo(
    () => ({
      template_id: "",
      file_name: "",
      original_file_name: "",
      file: [],
    }),
    [open]
  );

  const validateForm = useCallback(
    (values) => validate(values, { typeCurrent }),
    [typeCurrent]
  );

  return (
    <>
      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {titleError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsError(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <Form
        ref={formRef}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(value, form) => {
          setFormRef(form); // เก็บ form ไว้ใน state
          handleAddClose();
          handleAddFile({
            ...value,
            original_file_name: value.file[0].name,
            id: Math.floor(Math.random() * 1000) + 1,
            typeFile: [...typeCurrent].find(
              (item) => item.id === value.template_id
            ),
            file_name: value.file_name,
            size: calculateFileSize(value.file[0].size),
            fname: user?.fname,
            lname: user?.lname,
            policestation: user?.org,
            created_at: new Date().toISOString(),
            is_more: true,
            is_delete: true,
          });
        }}
        component={Hro3UploadFile}
        open={open}
        onClose={ handleAddClose}
        title={"การอัพโหลดเอกสารเพิ่มเติม"}
        size="md"
        typeCurrent={typeCurrent}
        loading={loading}
      />
    </>
  );
};

export default Hr03ModalUpload;
