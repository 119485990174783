import { Save } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Stack,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect } from "react";
import { FORM_ERROR } from "final-form";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import { format, startOfToday } from "date-fns";
import Joi from "joi";
import validationMsg from "../../../helpers/validationMsg";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import FieldAsyncAutoComplete from "../../form-fields/FieldAsyncAutoComplete";
import { defaultJoiDates } from "../../../helpers/dateHelper";

export default function CaseForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  isTpo = false,
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error || submitError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ปิด</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item md={12}>
            <Alert severity="warning" sx={{ width: "100%" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Divider textAlign="center">ข้อมูลทั่วไป</Divider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldDatePicker
            name="date"
            label="วันที่"
            required
            pickerProps={{
              inputFormat: "dd/MM/yyyy",
              disableFuture: true,
              openTo: "year",
              views: ["year", "month", "day"],
              allowSameDateSelection: true,
            }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="tpo_case_id"
            label="เลขอ้างอิง"
            required
            controlProps={{ fullWidth: true, disabled: !readOnly && !!isTpo }}
            readOnly={readOnly}
            inputProps={{
              placeholder: "หมายเลข case จาก thaipoliceonline.com",
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
                <Divider textAlign="center" sx={{ mt: 2 }}>
                  ผู้เสียหาย
                </Divider>
              </Grid> */}
        <Grid item xs={12} md={12}>
          <FieldInput
            name="victim_name"
            label="ชื่อ สกุล ผู้เสียหาย"
            required
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider textAlign="center" sx={{ mt: 2 }}>
            พนักงานสอบสวน
          </Divider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="police_name"
            label="ยศ ชื่อ สกุล"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FieldInput
            name="job_title"
            label="ตำแหน่ง"
            controlProps={{ fullWidth: true }}
            readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FieldAsyncAutoComplete
            name="policestation_org_code"
            label="หน่วยงาน"
            required
            controlProp={{ fullWidth: true }}
            readOnly={readOnly}
            basePath="/api/policestations"
            requestParams={{ page_size: undefined, page: undefined }}
            itemFormat={(row) => {
              return { value: row.ORG_CODE, label: row.ORG_ABBR };
            }}
            inputProps={{ sx: { width: "100%" } }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Divider></Divider>
        </Grid>
        <Grid item xs={12} md={12}>
          <FieldInput
            name="note"
            label="รายละเอียดเบื้องต้น"
            controlProps={{ fullWidth: true }}
            inputProps={{ multiline: true, maxRows: 6, minRows: 3 }}
            readOnly={readOnly}
          />
        </Grid>
        {!readOnly && (
          <>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Divider></Divider>
            </Grid>
            <Grid item md={12}>
              <Stack direction="row" spacing={2}>
                <SubmitBtn
                  variant="contained"
                  startIcon={<Save />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  บันทึก
                </SubmitBtn>
                <Button
                  type="button"
                  variant="outlined"
                  disabled={submitting}
                  onClick={() => {
                    if (onCancel) {
                      onCancel(form);
                      return;
                    }

                    navigate("/cases");
                  }}
                >
                  ยกเลิก
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // console.log(errors);

  return errors;
};

export const validateRules = () => ({
  date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .messages({
      "string.empty": "กรุณาระบุวันที่",
      "any.required": "กรุณาระบุวันที่",
      "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
        defaultJoiDates.minDate,
        "dd/MM/yyyy"
      )}`,
      "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
        defaultJoiDates.forceUTC(startOfToday()),
        "dd/MM/yyyy"
      )}`,
    })
    .required(),
  tpo_case_id: Joi.string().max(15).required(),
  victim_name: Joi.string().max(50).required(),
  police_name: Joi.string().max(255).allow(null),
  // org_id: Joi.number().required(),
  policestation_org_code: Joi.string()
    .pattern(/^\d{5}$/)
    .required(),
  job_title: Joi.string().max(255).allow(null),
  // police_station_name: Joi.string().max(255).allow(null),
  note: Joi.string().allow(null),
});

const schema = () => Joi.object(validateRules()).error(validationMsg());
