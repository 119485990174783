import { Download, Search, UploadFile } from "@mui/icons-material";
import { Button, Grid, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import letterTypes from "../../../data/selects/letterTypes";
import requestStatusSelect from "../../../data/selects/requestStatus";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import FieldInput from "../../form-fields/FieldInput";
import SubmitBtn from "../../SubmitBtn";
import { LoadingButton } from "@mui/lab";
import api from "../../../apis";
import fileDownload from "js-file-download";

export default function RequestLetterSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  handleDownload,
  rows,
  filter,
  total
}) {

  const export_condition = useSelector(
    (state) => state.auth.user.export_condi ?? []
  );
  const extorg_request_list = useSelector(
    (state) => state.auth.user?.permissions?.extorg_request_list ?? []
  );
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [anchorElDowload, setAnchorElDowload] = useState(null);
  const openDowload = Boolean(anchorElDowload);
  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  const DownloadBtn = (
    <LoadingButton
      id="export-button-excel"
      type="button"
      variant="outlined"
      startIcon={<Download />}
      loadingPosition="start"
      // onClick={() => handleDownload("/api/", setPdfDownloading)}
      aria-controls={openDowload ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={openDowload ? "true" : undefined}
      onClick={handleClickDowload}
      loading={isLoadingExcel || isLoadingCsv}
      disabled={total === 0 || total < export_condition.min_download || total > export_condition.max_download}

    >
      ดาวน์โหลด
    </LoadingButton>
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      {extorg_request_list.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          {showStatusField && (
            <Grid item xs={12} md={6} lg={4} xl={2}>
              <FieldAutoComplete
                name="status"
                label="สถานะ"
                options={requestStatusSelect.filter((el) =>
                  ["Sent", "Success", "Email Success"].includes(el.label)
                )}
                controlProp={{
                  sx: {
                    width: "100%",
                  },
                }}
                // inputProps={{ sx: { fullWidth: true } }}
                // controlProp={{ sx: { fullWidth: true } }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <FieldAutoComplete
              name="type_id"
              label="ประเภทคำขอ"
              options={letterTypes}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              //   inputProps={{ sx: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={12} xl={4}>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              }}
              spacing={2}
            >
              <FieldDatePicker
                name="start_date"
                label="ตั้งแต่วันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
              <FieldDatePicker
                name="end_date"
                label="ถึงวันที่"
                pickerProps={{
                  inputFormat: "dd/MM/yyyy",
                  disableFuture: true,
                  openTo: "year",
                  views: ["year", "month", "day"],
                  allowSameDateSelection: true,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4} xl={3}>
            <FieldInput
              name="search"
              label="คำค้นหา"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
        </Grid>
      )}
      {/* <Grid item xs={12}>
        <Stack direction="row" spacing={2} alignItems="center">
          <SubmitBtn
            variant="contained"
            startIcon={<Search />}
            submitting={loading}
            pristine={pristine && !modifiedSinceLastSubmit}
          >
            ค้นหา
          </SubmitBtn>
          <Button
            variant="outlined"
            disabled={loading || (pristine && !modifiedSinceLastSubmit)}
            onClick={(event) => {
              form.reset();
              if (onReset) onReset(event);
            }}
          >
            Reset
          </Button>
        </Stack>
      </Grid> */}
      <Grid container spacing={2} mb={2}>
        <Grid item md={12} lg={4}>
          {extorg_request_list.includes("search") && (
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid item md={12} lg={8}>
          {(extorg_request_list.includes("download") ||
            extorg_request_list.includes("upload")) && (
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              {extorg_request_list.includes("download") && (
                <>
                  {!values.start_date || !values.end_date ? (
                    <Tooltip title="กรุณาเลือกวันที่ ที่ค้นหาก่อนใช้งานปุ่มดาวน์โหลด">
                      <span>{DownloadBtn}</span>
                    </Tooltip>
                  ) : (
                    DownloadBtn
                  )}
                </>
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorElDowload}
                open={openDowload}
                onClose={handleCloseDownload}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={async () => {
                    setIsLoadingExcel(true);
                    handleCloseDownload();
                    try {
                      const res = await handleDownload("excel");

                      let fileName =
                        res?.headers["content-disposition"].split(
                          "filename="
                        )[1];

                      fileDownload(res.data, fileName);
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoadingExcel(false);
                    }
                  }}
                >
                  Excel
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    try {
                      handleCloseDownload();
                      setIsLoadingCsv(true);
                      const res = await handleDownload("csv");

                      let fileName =
                        res?.headers["content-disposition"].split(
                          "filename="
                        )[1];

                      fileDownload(res.data, fileName);
                    } catch (error) {
                      console.log(error);
                    } finally {
                      setIsLoadingCsv(false);
                    }
                  }}
                >
                  CSV
                </MenuItem>
              </Menu>
              {extorg_request_list.includes("upload") && (
                <Button
                  variant="contained"
                  startIcon={<UploadFile />}
                  color="primary"
                  component={Link}
                  to="/external/responses/upload"
                >
                  Upload หลายรายการ
                </Button>
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
    </form>
  );
}
// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
