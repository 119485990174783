import { Alert, Box, Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Login } from "@mui/icons-material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import { Form } from "react-final-form";
import { MdLockOutline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { authFetch, authLogin } from "../../ducks/auth";
import WarningDialog from "../dialogs/WarningDialog";
import FieldInput from "../form-fields/FieldInput";
import SubmitBtn from "../SubmitBtn";

const schema = Joi.object({
  email: Joi.string().min(8).required(),
  password: Joi.string().min(8).required(),
});

const validate = (values) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    errors[FORM_ERROR] = "email หรือ password ไม่ถูกต้อง";
  }

  return errors;
};

export default function LoginPage() {
  const params = useParams();
  const secretCode = params.secret;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const handleChPass = () =>
    navigate("/forcechangepassword", { state: { email, token } });

  const onSubmit = (formValues) => {
    const user = { email: formValues.email, password: formValues.password };
    setEmail(formValues.email);
    // return navigate("/twofactor", { state: { user } });
    return dispatch(authLogin({ ...formValues, secretCode: secretCode }))
      .then((token) => {
        if (token.isVerify_2fa_type === "sms") {
          navigate("/twofactor", { state: { user, token } });
        } else if (token.isVerify_2fa_type === "google2fa") {
          navigate("/google2fa", { state: { user, token } });
        } else {
          dispatch(authFetch());
          navigate("/me");
        }
      })
      .catch(({ response }) => {
        if (response?.status === 429) {
          return {
            [FORM_ERROR]:
              "พบการเรียกใช้งานระบบเป็นจำนวนมาก กรุณากลับมาใช้งานใหม่ในอีก 5 นาที",
          };
        } else if (response?.status === 401) {
          if (response.data.password_is_expire) {
            setToken(response.data.token);
            setError(
              "รหัสผ่านของท่านหมดอายุแล้ว กรุณาเปลี่ยนรหัสผ่านใหม่ เพื่อสามารถเข้าใช้งานได้ระบบได้อีกครั้ง"
            );
          } else {
            return {
              [FORM_ERROR]: response.data.message,
            };
          }
        } else if (response.status >= 400 && response.status < 500) {
          return {
            [FORM_ERROR]: "email หรือ password ไม่ถูกต้อง",
          };
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  // // for fetch error
  // const [fetchError, SetFetchError] = useState(false);
  // const handleToastClose = (event, reason) => {
  //   SetFetchError(false);
  // };

  // useEffect(async () => {
  //   await api
  //     .get(`/api/me`)
  //     .then(({ data }) => {
  //       // navigate("/me");
  //       // console.log(data.permisstions.map((v) => v.option.map((v) => v.info)));
  //     })
  //     .catch(({ response }) => {
  //       if (response && response.status === 404) {
  //         SetFetchError("เกิดข้อผิดพลาด");
  //       }
  //       SetFetchError("เกิดข้อผิดพลาด");
  //     });
  // }, []);

  useEffect(() => {
    if (!secretCode) window.location.href = "/login";
    //TO-DO: Check secert code is valid
  }, [secretCode]);

  return (
    <div className="bg-[#F5F5F5] h-screen grid justify-center items-center ">
      <WarningDialog error={error} onClose={handleChPass} />
      {/* <CssBaseline /> */}
      <div className="bg-white rounded-[32px] p-12 relative mx-4 shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]">
        {/* <Typography component="h1" variant="h4">
          Bank Portal
        </Typography> */}
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-10 p-1">
          <MdLockOutline className="h-16 w-16 bg-[#1976D3] rounded-full p-3 text-white" />
        </div>
        <Typography className="text-center" component="h1" variant="h5">
          Login - เข้าสู่ระบบ
        </Typography>
        <Form validate={validate} onSubmit={onSubmit}>
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            return (
              <Box
                component="form"
                noValidate
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
              >
                {submitFailed && (error || submitError) && (
                  <Alert severity="warning" sx={{ width: "100%" }}>
                    {error || submitError}
                  </Alert>
                )}
                <FieldInput
                  name="email"
                  label="Email"
                  required
                  controlProps={{ fullWidth: true, margin: "normal" }}
                />
                <FieldInput
                  name="password"
                  label="Password"
                  required
                  controlProps={{
                    fullWidth: true,
                    margin: "normal",
                    type: "password",
                  }}
                  inputType="password"
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} md={12}>
                    <SubmitBtn
                      variant="contained"
                      submitting={submitting}
                      fullWidth
                      sx={{ mt: 3, mb: 2 }}
                      startIcon={<Login />}
                    >
                      เข้าสู่ระบบ
                    </SubmitBtn>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs>
                    <Link
                      component={RouterLink}
                      to="/forgotpassword"
                      variant="body2"
                    >
                      ลืมรหัสผ่าน?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/register" variant="body2">
                      ลงทะเบียนใช้งาน
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        </Form>
      </div>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </div>
  );
}
