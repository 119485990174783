import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import api from "../../../apis";
import PersonnelPidSearchForm, { validate } from "./PidSearchForm";
import PersonnelSearchForm, { mapToInit } from "./SearchForm";

export default function PersonnelSearchPage() {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [modalError, setModalError] = useState(false);

  const search_ptm = useSelector(
    (state) => state.auth.user?.permissions?.search_ptm ?? []
  );
  const onSubmit = async (formValues, form) => {
    setLoading(true);
    try {
      const res = await api.get(`/api/ptm`, { params: formValues });
      if (Object.keys(res.data).length > 0) {
        setItem(res.data);
      } else {
        setModalError(true);
      }
    } catch ({ response }) {
      return {
        [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
      };
    } finally {
      setLoading(false);
    }
  };

  const onReset = () => {
    setItem({});
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">ค้นหากำลังพล</Typography>
        {/* <Typography variant="subtitle1" gutterBottom color="warning.main">
          กรอกข้อมูลบัญชี HR03-1 หรือ HR03-2
        </Typography> */}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {search_ptm.includes("search") && (
            <>
              <Form
                onSubmit={onSubmit}
                component={PersonnelPidSearchForm}
                validate={validate}
                onReset={onReset}
                loading={loading}
              />
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Divider></Divider>
              </Grid>
            </>
          )}
          <Form
            onSubmit={() => {}}
            initialValues={mapToInit(item)}
            component={PersonnelSearchForm}
          />
        </Paper>
      </Grid>
      <Dialog
        open={modalError}
        onClose={() => setModalError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          แจ้งเตือน
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ไม่พบข้อมูลกำลังพลด้วยเลขบัตรประชาชนนี้ !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalError(false)}>รับทราบ</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
