import { TextSnippet } from "@mui/icons-material";
import { Alert, Button, Grid, Stack ,Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React from "react";
import validationMsg from "../../../helpers/validationMsg";
import FieldSingleFileDropZone from "../../form-fields/FieldSingleFileDropZone";
import SubmitBtn from "../../SubmitBtn";

export default function UploadForm({
  handleSubmit,
  error,
  submitError,
  submitting,
  pristine,
  submitFailed,
  form,
  values,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
        <Typography variant="subtitle1">Excel File *</Typography>
          <FieldSingleFileDropZone
            ishowContext
            name="template_file"
            accept="text/csv,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              application/vnd.ms-excel.sheet.binary.macroEnabled.12,
              .xlsb"
            placeholder={
              <>
                Drag 'n' drop some files here, or click to select files <br />
                (*.csv, *.xls, *.xlsx,*.xlsb)(max 10MB)
              </>
            }
            // 10 MB
            dropZoneProps={{ maxSize: 1048576 * 15 }}
          />
        </Grid>

        <Grid item md={12}>
          <Stack direction="row" spacing={2}>
            <SubmitBtn
              variant="contained"
              startIcon={<TextSnippet />}
              submitting={submitting}
              pristine={pristine}
            >
              Process
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={submitting || _.isEmpty(values)}
              onClick={(event) => {
                form.restart();
              }}
            >
              clear
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const schema = Joi.object(validateRules()).error(validationMsg());
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  return errors;
};

export const validateRules = () => ({
  template_file: Joi.any()
});
