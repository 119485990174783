import { Box, Grid, Typography } from "@mui/material";
import { format, startOfToday } from "date-fns";
import React,{useEffect} from "react";
import { useQuery } from "react-query";
import api from "../../../../apis";
import Express from "./components/Express";
import Hr03Type from "./components/hr03Type";
import SumCard from "./components/sumCard";
import BlackHorse from "./components/blackHorse";
import GrayHorse from "./components/GrayHorse";
import DashboardSkeleton from "./components/DashboardSkeleton";

export default function Dashboard() {
  const [date, setDate] = React.useState(format(startOfToday(), "yyyy-MM-dd"));
  const [type, setType] = React.useState("h");

  const {
    data: hr03ChartData,
    refetch: hr03ChartDataRefetch,
    isLoading: Rhr03ChartDataIsLoading,
    isError: hr03ChartDataDataIsError,
  } = useQuery(
    ["hr03_chart"], // Added dependencies here
    () => api.get(`/api/hr03_chart`).then((res) => res.data),
    { staleTime: 3 * 60 * 1000 }
  );

  useEffect(() => {
    console.log("hr03ChartData", hr03ChartData);
  }, [hr03ChartData])
  

  return (
    <>
      {!Rhr03ChartDataIsLoading && !hr03ChartDataDataIsError ? (
        <>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                ภาพรวมสรุปคำขอเปิด HR03/ม้าเทา
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              backgroundColor: "white",
              padding: 4,
              borderRadius: 2,
              mt: 2,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={5}>
                <Express data={hr03ChartData?.part1} />
              </Grid>
              <Grid item md={7}>
                <Hr03Type data={hr03ChartData?.part2} />
              </Grid>
              <Grid item md={12}>
                <SumCard data={hr03ChartData?.part3} />
              </Grid>
              <Grid item md={6}>
                <BlackHorse data={hr03ChartData?.part4} />
              </Grid>
              <Grid item md={6}>
                <GrayHorse data={hr03ChartData?.part5} />
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <DashboardSkeleton />
      )}
    </>
  );
}
