import { Search } from "@mui/icons-material";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React from "react";
import SubmitBtn from "../../SubmitBtn";

import FieldInput from "../../form-fields/FieldInput";

export default function PersonnelPidSearchForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  onReset,
  loading,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Typography variant="h6">
        ระบุเลขบัตรประชาชนเพื่อค้นหาข้อมูลกำลังพลและตรวจสอบ
      </Typography>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={4}>
          <FieldInput
            name="card_id"
            label="เลขบัตรประชาชนของพนักงาน"
            required
            controlProps={{ fullWidth: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={pristine && !modifiedSinceLastSubmit}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

const validateRules = () => ({
  card_id: Joi.string()
    .pattern(/^\d{13}$/)
    .required()
    .messages({
      "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
    }),
});

const schema = () => Joi.object(validateRules());

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema().validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};
