import { Grid, Paper, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import BarChart from "./chart";

const BlackHorse = ({ data }) => {
  const titleChart = "ข้อมูลสรุปจำนวนรายการ ประเภท ม้าดำ";

  const options = useMemo(
    () => ({
      chart: {
        type: "bar",
        height: 350,
        stacked: false,
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#000"],
          fontSize: "12px",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: data?.map((item) => item.name),
      },
      yaxis: {
        title: {
          text: "จำนวนรายการ",
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
      },
      colors: ["#90CAF9", "#66BB6A"], // สีของแต่ละชุดข้อมูล
      tooltip: {
        y: {
          formatter: (value) => `${value} รายการ`,
        },
      },
    }),
    [data]
  );

  const series = useMemo(
    () => [
      {
        name: "On Process",
        data: data.map((item) => item.process),
      },
      {
        name: "Success",
        data: data.map((item) => item.success),
      },
    ],
    [data]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6"> {titleChart}</Typography>
        </Grid>

        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            marginLeft: 2,
            marginTop: 2,
          }}
        >
          <BarChart options={options} series={series} />
        </Paper>
      </Grid>
    </>
  );
};

export default BlackHorse;
