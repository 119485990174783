import {
  Check,
  Description,
  InsertChart,
  SystemUpdateAlt,
} from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoadingButton from "@mui/lab/LoadingButton";
import toast from "react-hot-toast";

import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../../apis";
import countryOptions from "../../../../../data/selects/countries";
import { hr03ApproveRequest } from "../../../../../ducks/hr03List";
import { getTextMask } from "../../../../../helpers/imask";
import thaiIdValidator from "../../../../../helpers/ThaiIdValidator";
import FieldAsyncAutoComplete from "../../../../form-fields/FieldAsyncAutoComplete";
import FieldAsyncSelect from "../../../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../../../form-fields/FieldDatePicker";
import FieldInput from "../../../../form-fields/FieldInput";
import FieldSelect from "../../../../form-fields/FieldSelect";
import SignaturePreview from "../../../requests/request-forms/SignaturePreview";
import Hr03Stepper from "../../Hr03VerifyAccountActive/component/Hr03Stepper";
import { IconButton, Tooltip } from "@material-ui/core";
import TableOtherFiles from "../component/TableOtherFiles";
import { defaultJoiDates } from "../../../../../helpers/dateHelper";
import { format, startOfToday } from "date-fns";

// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";

const TextMaskCustom = getTextMask({
  mask: "00000000000000",
  unmask: true,
  overwrite: true,
});

export default function Hr03RequestAccountActiveForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  page,
  id,
  idFromHr03,
  isViewOnly,
  handleCancel,
  handleOpenTableHistory,
  loading,
  setLoading,
}) {
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };
  const [errorDialog, setErrorDialog] = React.useState("");

  const [openDialogError, setOpenDialogError] = React.useState(false);

  const navigate = useNavigate();
  const [isErrorDowload, setIsErrorDowLoad] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [titleError, setTitleError] = useState(
    "ไม่สามารถดาวน์โหลดไฟล์ได้ เนื่องจากไฟล์ไม่มีอยู่ในระบบ"
  );

  const [type1, setType1] = useState([]);
  const [type2, setType2] = useState([]);
  const [typeCurrent, setTypeCurrent] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  useEffect(() => {
    if (values.req_type_request && page === "add") {
      form.change("type_request", values.create_form_type);
      form.change("hr03_fullName", `${values.name} ${values.surname}`);
      form.change("type", values.req_type_request);
      form.change("files", {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.req_type_request]);

  useEffect(() => {
    const isHaveAllType =
      Object.keys(type1).length > 0 && Object.keys(type2).length > 0;
    if (!isHaveAllType && values.type) {
      refetchDataRequestsFiletemplate(values.type);
    } else {
      setTypeCurrent(values.type === 1 ? type1 : type2);
    }
  }, [values.type]);

  useEffect(() => {
    if (page === "add" && Object.keys(typeCurrent).length > 0) {
      form.change("attachments", typeCurrent);
    }
  }, [typeCurrent]);
  const { isLoading: isLoadingRequestTypeOptions, data: requestTypeOptions } =
    useQuery(
      [values.hr_type],
      () =>
        (values.type_account || values.hr_type) &&
        api
          .get("/api/openbankaccount/type_request", {
            params: {
              type_request: values.hr_type,
              hr03_id: values.hr03_id,
            },
          })
          .then((res) =>
            res.data.map((row) => ({
              value: row.create_form_type,
              type: row.request_type,
              label: row.name,
            }))
          ),

      { staleTime: 60 * 1000 }
    );

  const groupAttachments = useMemo(() => {
    return values?.template_attachment?.map((template) => {
      // Find all matching attachments for this template
      const matchingAttachments = values?.attachments?.filter(
        (attachment) => attachment.template_file_id === template.id.toString()
      );
      // Return combined object
      return {
        ...template,
        attachments: matchingAttachments,
      };
    });
  }, [values]);

  const downloadFile = useCallback(async (fileUrl) => {
    setIsLoadingDownload(true);
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });
      // ดึงนามสกุลไฟล์จากชื่อไฟล์
      let fileName = (
        res?.headers["content-disposition"] ?? "filename=File"
      ).split("filename=")[1];
      fileName = fileName.at(-1) === '"' ? fileName.slice(1, -1) : fileName;
      let fullFileName = "";
      const contentType = res.headers["content-type"];
      if (contentType.includes("text/csv")) {
        fullFileName = `${fileName}.csv`; // Set the desired file name for the download
      } else if (contentType.includes("application/zip")) {
        fullFileName = `${fileName}.zip`; // Set the desired file name for the download
      } else if (contentType.includes("text/plain")) {
        fullFileName = `${fileName}.txt`; // Set the desired file name for the download
      } else {
        fullFileName = `${fileName}.csv`;
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));

      // สร้าง element <a> สำหรับดาวน์โหลดไฟล์
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fullFileName); // กำหนดชื่อไฟล์ + นามสกุล

      document.body.appendChild(link);

      link.click();

      // ล้างหน่วยความจำ

      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    } finally {
      setIsLoadingDownload(false);
    }
  }, []);

  const refetchDataRequestsFiletemplate = (type) =>
    api
      .get("/api/openbankaccount/requests/filetemplate", {
        params: { type: type },
      })
      .then((res) => {
        type === 1 ? setType1(res?.data) : setType2(res?.data);
        setTypeCurrent(res?.data);
      });
  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(
    [
      "bankSelect",
      "/api/banks/list/bank",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/banks/list/bank").then((res) =>
        res?.data?.map((row) => ({
          value: row.code,
          label: `${row.name} (${row.short_name})`,
        }))
      ),
    { staleTime: 3 * 60 * 1000 }
  );

  useEffect(() => {
    const type =
      page === "add" && requestTypeOptions && requestTypeOptions.length > 0
        ? requestTypeOptions.filter(
            (el) => el?.value === values.request_type
          )[0].type
        : undefined;
    type && form.change("type", type);
  }, [values.request_type]);

  const previewFiles = useCallback(async (fileUrl, file_name) => {
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });

      // ดึง content-type จาก header
      const contentType = res.headers["content-type"];
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: contentType })
      );

      // ตรวจสอบประเภทไฟล์จาก content-type
      if (contentType === "application/pdf") {
        // กรณีเป็น PDF
        const pdfWindow = window.open(url);
        pdfWindow.onload = () => {
          pdfWindow.print();
        };
      } else {
        // กรณีเป็นไฟล์ประเภทอื่น
        const link = document.createElement("a");
        link.href = url;

        // กำหนดนามสกุลไฟล์ตาม content-type
        let extension = "";
        switch (contentType) {
          case "image/jpeg":
            extension = ".jpg";
            break;
          case "image/png":
            extension = ".png";
            break;
          case "application/msword":
            extension = ".doc";
            break;
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            extension = ".docx";
            break;
          case "application/vnd.ms-excel":
            extension = ".xls";
            break;
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            extension = ".xlsx";
            break;
          default:
            extension = "";
        }

        const fileName = `${file_name}`; // สามารถเปลี่ยนชื่อไฟล์ตามต้องการ
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    }
  }, []);

  return (
    <>
      {toastInfo?.type && (
        <Alert
          onClose={handleToastInfoClose}
          severity={toastInfo?.type || "warning"}
          sx={{ width: "100%" }}
        >
          {toastInfo?.message || ""}
        </Alert>
      )}

      <Dialog
        open={isErrorDowload}
        onClose={() => setIsErrorDowLoad(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {titleError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsErrorDowLoad(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit} noValidate>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" color="warning.main">
            Warning
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error || submitError}
            </DialogContentText>
          </DialogContent>
          <DialogActions key="dialog_actions_hr03_request_account_active_form">
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              ปิด
            </Button>
          </DialogActions>
        </Dialog>
        <Paper sx={{ p: 2, mb: 3 }}>
          <Hr03Stepper
            status={values?.status}
            note={values?.current_remark}
            type={values?.type}
          />
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={3}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }} textAlign="center">
              ข้อมูลดั้งเดิม
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldSelect
              name={page === "add" ? "type_request" : "request_type"}
              label="ประเภทคำร้องขอเปิดใช้งาน"
              required
              controlProp={{ fullWidth: true }}
              options={requestTypeOptions}
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={
                values.type_account === "black" && page === "add"
                  ? "ref_code"
                  : values.type_account === "dark" && page === "add"
                  ? "gray_account.ref_code"
                  : "hr03_number"
              }
              label="Ref code"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              inputProps={{
                placeholder: "กรุณากรอกเลข Ref code",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "hr_list.card_id" : "hr03.card_id"}
              label={"เลขบัตรประชาชน"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "hr03_fullName" : "fullName"}
              label={"ชื่อ-สกุล"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกชื่อ-สกุล",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name={page === "add" ? "bankcode" : "hr03.bankcode"}
              label="ธนาคาร"
              required
              controlProp={{ fullWidth: true }}
              inputProps={{
                sx: { width: "100%" },
                loading: isBankOptionsLoading || bankOptionsError,
                loadingText: bankOptionsError ? "Error" : "Loading…",
              }}
              options={bankOptions}
              readOnly={true}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "accountno" : "hr03.accountno"}
              label={"หมายเลขบัญชี"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน",
              }}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              ข้อมูล{" "}
              <Typography
                sx={{ fontSize: 20, fontWeight: 500 }}
                component={"span"}
                color="warning.main"
              >
                {values.type === 1 ? "ขอถอนรายชื่อ" : "ขอเปิดรายบัญชี"}
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <FieldAsyncAutoComplete
              name={page === "add" ? "policestation_org_code" : "request_org"}
              label="จากหน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
              inputProps={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="card_id"
              label={"เลขบัตรประชาชน/เลขพาสปอร์ต"}
              required
              controlProps={{ fullWidth: true }}
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน/เลขพาสปอร์ต",
              }}
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "fullName" : "full_name"}
              label={"ชื่อ-สกุล"}
              required
              controlProps={{ fullWidth: true }}
              inputProps={{
                placeholder: "กรุณากรอกชื่อ-สกุล",
              }}
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name="nationality"
              label="สัญชาติ/ประเทศ"
              required
              options={countryOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldDatePicker
              name="birth_date"
              label="วันเกิด"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "no" : "book_no"}
              label="เลขหนังสือ"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">ตช.</InputAdornment>
                ),
                placeholder: "ตัวอย่างเช่น 0039.12/123",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldDatePicker
              name={page === "add" ? "date" : "request_date"}
              label="ลงวันที่"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              readOnly
              storeFormat="yyyy-MM-dd"
            />
          </Grid>

          {values.type === 2 && (
            <>
              <Grid item xs={12}>
                <FieldAsyncSelect
                  name={"bank_id"}
                  label="ธนาคาร"
                  controlProp={{
                    sx: {
                      width: "100%",
                    },
                  }}
                  basePath={`/api/banks`}
                  itemFormat={(row) => {
                    return {
                      value: row.id,
                      label: `${row.name} (${row.short_name})`,
                    };
                  }}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name={"bank_number"}
                  label="หมายเลขบัญชีที่ต้องการใช้งาน"
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly
                  inputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name="bank_name"
                  label="ชื่อบัญชีที่ต้องการขอเปิด"
                  required
                  controlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder: "ข้อมูลเท่าที่ทราบ",
                  }}
                  readOnly
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FieldAsyncSelect
              name="discription_bank"
              label="เหตุผลขอเปิดใช้บัญชีนี้"
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              basePath={`/api/openbankaccount/requests/description/bank`}
              itemFormat={(row) => {
                return {
                  value: row.id,
                  label: row.name,
                };
              }}
              readOnly
            />
          </Grid>
          {values.is_declare_note === 1 && (
            <Grid item xs={12}>
              <FieldInput
                name="declare_note"
                label="เหตุผลเพิ่มเติม"
                controlProps={{ fullWidth: true }}
                inputProps={{ multiline: true, maxRows: 6, minRows: 3 }}
                readOnly
              />
            </Grid>
          )}
          <Grid item xs={12} mt={3}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }} textAlign="center">
              File Upload ประกอบขอ
              {values.type === 1 ? "ถอนรายชื่อ" : "เปิดรายบัญชี"}
              {values.dowload_zip && (
                <Tooltip title="ดาวน์โหลดเอกสาร zip">
                  <Button
                    type="button"
                    onClick={() => downloadFile(values.dowload_zip)}
                    disabled={isLoadingDownload}
                    color="primary"
                    sx={{ fontSize: 20, fontWeight: 500, ml: 1 }}
                  >
                    <SystemUpdateAlt /> ZIP
                  </Button>
                </Tooltip>
              )}
            </Divider>
          </Grid>
          <Box
            sx={{
              marginX: 2,
              marginTop: 4,
            }}
          >
            <Grid container spacing={2}>
              {groupAttachments?.map((section, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Typography variant="h6" gutterBottom>
                    {section.title}
                  </Typography>
                  <List>
                    {section.attachments.length > 0 ? (
                      section.attachments.map((file, fileIndex) => (
                        <ListItem key={fileIndex}>
                          <ListItemIcon>
                            <Description color="error" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography
                              onClick={async () =>
                                await previewFiles(
                                  file.url_download,
                                  file.file_name
                                )
                              }
                              sx={{
                                cursor: "pointer",
                                color: "primary.main",
                                textDecoration: "underline",
                                "&:hover": {
                                  opacity: 0.8,
                                },
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px", // หรือกำหนดขนาดตามที่ต้องการ
                              }}
                              title={file.file_name} // แสดงชื่อเต็มเมื่อ hover
                            >
                              {file.file_name}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemIcon>
                          <InsertDriveFileIcon color="disabled" />
                        </ListItemIcon>
                        <ListItemText primary="ไม่มีไฟล์อัปโหลด" />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Grid item xs={12}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              เอกสารอื่นๆ
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TableOtherFiles
              typeCurrent={typeCurrent}
              values={values}
              isViewOnly={isViewOnly}
            />
          </Grid>
          {/* {getFileAttachmentsList(
            page === "add" ? typeCurrent : groupAttachments
          )} */}
          <Grid item xs={12}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              ข้อมูลพนักงานสอบสวน
            </Divider>
          </Grid>
          <Grid item xs={12} md={2}>
            <FieldInput
              name={page === "add" ? "user.rank" : "rank"}
              label="ยศ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name={page === "add" ? "user.fname" : "fname"}
              label="ชื่อ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name={page === "add" ? "user.lname" : "lname"}
              label="สกุล"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "user.job_title" : "job_title"}
              label="ตำแหน่ง"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAsyncAutoComplete
              name={page === "add" ? "user.policestation.ORG_CODE" : "org_code"}
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly={readOnly}
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              // itemFilter={(row) =>
              //   !row.ORG_CODE?.startsWith("0") && !row.ORG_CODE?.startsWith("1")
              // }
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "user.pol_tel" : "phone_number"}
              label="โทรศัพท์"
              required
              controlProps={{ fullWidth: true }}
              readOnly
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="subtitle1">ลายเซ็น</Typography>
            <SignaturePreview name="pol_signature" />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }}></Divider>
          </Grid>
          <Grid container spacing={1} sx={{ p: 1, marginTop: 2 }}>
            <Grid item xs={6} sx={{ display: "flex", gap: 1 }}>
              {!isViewOnly &&
              values.approve &&
              request_account_HR03.includes("confirm") ? (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  startIcon={<Check />}
                  onClick={async () => {
                    setLoading(true);
                    dispatch(hr03ApproveRequest(values.id))
                      .then(() => {
                        toast.success("ยืนยันสำเร็จ");
                        handleClose();
                        handleCancel();
                      })
                      .catch(({ response }) => {
                        setErrorDialog(
                          response.data.message ??
                            "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง"
                        );
                        setOpenDialogError(true);
                      })
                      .finally(() => setLoading(false));
                  }}
                  loading={loading}
                  disabled={loading}
                >
                  ยืนยันข้อมูล
                </LoadingButton>
              ) : null}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenTableHistory}
                startIcon={<InsertChart />}
                disabled={loading}
              >
                ประวัติของรายการ
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleCancel("closeModal");
                }}
                disabled={loading}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema(values).validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateRules = (values) => ({
  ...(values.page === "add"
    ? {
        type_request: Joi.alternatives()
          .try(Joi.number(), Joi.string(), Joi.array())
          .required()
          .messages({
            "any.required": "กรุณาระบุประเภทคำร้องขอเปิดใช้งาน",
          }),
        policestation_org_code: Joi.string().required().messages({
          "string.empty": "กรุณาระบุหน่วยงาน",
          "any.required": "กรุณาระบุหน่วยงาน",
        }),
        hr03_id: Joi.alternatives().try(Joi.number(), Joi.string()).required(),
        fullName: Joi.string()
          .messages({
            "string.empty": "กรุณากรอกชื่อ-สกุล",
            "any.required": "กรุณากรอกชื่อ-สกุล",
          })
          .required(),
        card_id: Joi.string().custom(thaiIdValidator).required().messages({
          "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
        }),
        date: Joi.date()
          .min(defaultJoiDates.minDate)
          .max(defaultJoiDates.forceUTC(startOfToday()))
          .required()
          .messages({
            "string.empty": "กรุณาระบุวันที่",
            "any.required": "กรุณาระบุวันที่",
            "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.minDate,
              "dd/MM/yyyy"
            )}`,
            "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.forceUTC(startOfToday()),
              "dd/MM/yyyy"
            )}`,
          }),
        no: Joi.string().required().messages({
          "any.required": "กรุณาระบุหมายเลขหนังสือ",
        }),
        bank_id: Joi.when("type", {
          is: Joi.valid(2).required(),
          then: Joi.number().required().messages({
            "any.required": "กรุณาระบุธนาคาร",
          }),
          otherwise: Joi.number().optional(),
        }),
        bank_number: Joi.when("type", {
          is: Joi.valid(2).required(),
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุหมายเลขบัญชี",
          }),
          otherwise: Joi.string().optional(),
        }),
        bank_name: Joi.when("type", {
          is: Joi.valid(2).required(),
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุชื่อบัญชี",
          }),
          otherwise: Joi.string().optional(),
        }),
      }
    : {
        org_code: Joi.string().required().messages({
          "string.empty": "กรุณาระบุหน่วยงาน",
          "any.required": "กรุณาระบุหน่วยงาน",
        }),
        book_no: Joi.string().required().messages({
          "string.empty": "กรุณาระบุเลขหนังสือ",
          "any.required": "กรุณาระบุเลขหนังสือ",
        }),
        request_date: Joi.date()
          .min(defaultJoiDates.minDate)
          .max(defaultJoiDates.forceUTC(startOfToday()))
          .required()
          .messages({
            "string.empty": "กรุณาระบุวันที่",
            "any.required": "กรุณาระบุวันที่",
            "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.minDate,
              "dd/MM/yyyy"
            )}`,
            "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.forceUTC(startOfToday()),
              "dd/MM/yyyy"
            )}`,
          }),
        bank_number: Joi.when("type", {
          is: 2,
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุหมายเลขบัญชี",
            "string.empty": "กรุณาระบุหมายเลขบัญชี",
          }),
          otherwise: Joi.optional(),
        }),
        bank_name: Joi.when("type", {
          is: 2,
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุชื่อบัญชี",
            "string.empty": "กรุณาระบุชื่อบัญชี",
          }),
          otherwise: Joi.optional(),
        }),
        full_name: Joi.string().required().messages({
          "string.empty": "กรุณากรอกชื่อ-สกุล",
          "any.required": "กรุณากรอกชื่อ-สกุล",
        }),
      }),
  discription_bank: Joi.number().required().messages({
    "any.required": "กรุณาระบุเหตุผลขอเปิดใช้บัญชีนี้",
  }),
  declare_note: Joi.when("is_declare_note", {
    is: 1,
    then: Joi.string().required().messages({
      "any.required": "กรุณาระบุเหตุผล",
      "string.empty": "กรุณาระบุเหตุผล",
      "string.base": "กรุณาระบุเหตุผล",
    }),
    otherwise: Joi.optional(),
  }),
  nationality: Joi.number().required(),
  birthday: Joi.string()
    .messages({
      "string.pattern.base": "กรุณากรอกวันที่ให้ถูกต้อง เช่น 21/07/1996",
      "any.required": "กรุณาระบุวันเกิด",
    })
    .required(),
  ...(values.attachments
    ? {
        files: Joi.object(
          values.attachments.reduce((acc, el, i) => {
            if (i === 1) {
              const tmpId = acc.id;
              const tmp =
                acc.is_required === 1
                  ? Joi.array().items().required().messages({
                      "any.required": "กรุณาอัพโหลดไฟล์",
                    })
                  : Joi.array();
              acc = {};
              acc["file" + tmpId] = tmp;
            }
            acc["file" + el.id] =
              el.is_required === 1
                ? Joi.array().items().required().messages({
                    "any.required": "กรุณาอัพโหลดไฟล์",
                  })
                : Joi.array();

            return acc;
          })
        ),
      }
    : {}),
});

// const schema = () => Joi.object(validateRules()).error(validationMsg());

const schema = (values) => Joi.object(validateRules(values));

export const mapToInit = (item) => {
  return item;
};
