import {
  Stack,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  Close,
  UploadFile,
  FileDownloadDoneRounded,
} from "@mui/icons-material";
import { FileCopy } from "@material-ui/icons";

const FieldComponent = ({
  name,
  required = false,
  readOnly = false,
  resizeImage,
  shouldResizeImage = () => true,
  dropZoneProps = {},
  showFileSize = true,
  placeholder,
  accept = "image/jpeg, image/png,application/pdf,application/zip",
  input,
  meta,
  disabled,
  isStack = false,
  ishowContext = false,
}) => {
  const [loading, setLoading] = useState(false);

  const onDrop = (dropFiles) => {
    input.onBlur();
    if (!dropFiles[0]) {
      return;
    }

    if (
      resizeImage &&
      dropFiles[0].type?.startsWith("image/") &&
      shouldResizeImage(dropFiles[0])
    ) {
      setLoading(true);

      resizeImage(dropFiles[0])
        .then((resizeFile) => {
          input.onChange(resizeFile);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });

      return;
    }

    input.onChange(dropFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept,
    multiple: false,
    ...dropZoneProps,
    disabled: loading || disabled,
  });

  // const [pimg, setPimg] = useState();
  // useEffect(() => {
  //   (async () => {
  //     if (!input.value) {
  //       return undefined;
  //     }
  //     console.log(input.value);
  //     const signatureBase64 = await signatureResize(input.value);
  //     setPimg(signatureBase64);
  //   })();
  // }, [input.value]);

  const preview = useMemo(() => {
    if (loading) {
      return (
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <Box sx={{ textAlign: "center", p: 2 }}>
              <CircularProgress />
            </Box>
            <CardContent>
              <Typography
                variant="subtitle2"
                component="div"
                textAlign={"center"}
                noWrap
              >
                Loading...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }

    if (!input.value) {
      return undefined;
    }

    const fileSizeMB = input.value?.size
      ? (input.value?.size / 1048576).toFixed(2)
      : null;

    return (
      <>
        <Card sx={{ maxWidth: 345 }} style={{ position: "relative" }}>
          <Box
            sx={{
              display: "block",
              position: "absolute",
            }}
          >
            <IconButton
              size="small"
              onClick={(event) => {
                input.onChange(undefined);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          </Box>
          {input.value.type?.startsWith("image/") ||
          (typeof input.value == "string" && input.value.includes("base64")) ? (
            <CardMedia
              component="img"
              height="140"
              src={
                typeof input.value == "string" && input.value.includes("base64")
                  ? input.value
                  : URL.createObjectURL(input.value)
              }
              alt="upload file"
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <UploadFile sx={{ fontSize: 80, mt: 3 }} />
            </Box>
          )}
          <CardContent>
            <Typography
              variant="subtitle2"
              component="div"
              textAlign={"center"}
              noWrap
            >
              {input?.value?.name
                ? input?.value?.name
                : input?.value
                ? ""
                : "upload file"}
            </Typography>
            {showFileSize && fileSizeMB && (
              <Typography
                variant="caption"
                component="div"
                textAlign={"center"}
                noWrap
              >
                ({fileSizeMB} MB)
              </Typography>
            )}
          </CardContent>
        </Card>
      </>
    );
  }, [input, loading, showFileSize]);

  return (
    <>
      {isStack && input.value && (
        <Card sx={{ width: "100%", mb: 2 }}>
          <CardActionArea
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "block",
                position: "absolute",
                top: 2,
                left: 2,
              }}
            >
              <IconButton
                size="small"
                onClick={(event) => {
                  input.onChange(undefined);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            </Box>
            <CardMedia
              component="img"
              sx={{ height: 70, width: "auto", p: 1 }}
              image={
                typeof input.value == "string" && input.value.includes("base64")
                  ? input.value
                  : URL.createObjectURL(input.value)
              }
              alt="upload file"
            />
          </CardActionArea>
        </Card>
      )}
      {!input.value || ishowContext ?  (
        <Stack direction="row" spacing={2}>
          {!isStack && preview}
          <div
            {...getRootProps({
              className: `dropzone ${
                meta.invalid && meta.touched
                  ? "!bg-red-50 !text-[#d32f2f] !border-[#d32f2f]"
                  : ""
              }`,
            })}
          >
            <input {...getInputProps()} />
            <div
              className="text-wrap"
              style={{ width: "100%", textAlign: "center" }}
            >
              {placeholder || (
                <>
                  Drag 'n' drop some files here, or click to select files <br />
                  (*.jpg,*.gif,*.png,*pdf,*zip) only
                </>
              )}
            </div>
          </div>
        </Stack>
      ) : ""}

      {meta.invalid && meta.touched && (
        <Typography variant="caption" color="error.main">
          {meta.error || meta.submitError}
        </Typography>
      )}
    </>
  );
};

export default FieldComponent;
