import { CloudSync } from "@mui/icons-material";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  Typography
} from "@mui/material";
import jsonToFormData from "json-form-data";
import React, { useCallback, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import api from "../../../apis";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import UploadForm, { validate } from "./UploadForm";

export default function UploadPage({ isExternal = false }) {
  const [loading, setLoading] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const [formData, setFormData] = useState(null); // เก็บข้อมูล form ที่ submit
  const [formReference, setFormReference] = useState(null); // เก็บ reference ของ form
  const [response, setResponse] = useState(null);

  const onSubmit = async (formData, form) => {
    setFormData(formData); // เก็บข้อมูล form ที่ submit
    setFormReference(form); // เก็บ reference ของ form
    setConfirmReset(true); // เปิด ConfirmDialog
  };

  const handleConfirm = async () => {
    setConfirmReset(false); // ปิด ConfirmDialog
    if (!formData || !formReference) return;

    const excel_file = formData.template_file;

    try {
      setLoading(true);
      const upload = await api.post(
        "/api/migrate_user",
        jsonToFormData({ excel_file })
      );

      console.log("upload", upload.data);
      if (upload.data.success) {
        setResponse(upload.data);
        toast.success("อัพโหลดข้อมูลสำเร็จ");
        formReference.reset(); // ใช้ form reference เพื่อ reset form
      }
    } catch (error) {
      toast.error("เกิดข้อผิดพลาด");
    } finally {
      setLoading(false);
    }
  };

  const previewFiles = useCallback(async (fileUrl) => {
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });
      // ดึง content-type จาก header
      const contentType = res.headers["content-type"];
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: contentType })
      );

      // ตรวจสอบประเภทไฟล์จาก content-type
      if (contentType === "application/pdf") {
        // กรณีเป็น PDF
        const pdfWindow = window.open(url);
        pdfWindow.onload = () => {
          pdfWindow.print();
        };
      } else {
        // กรณีเป็นไฟล์ประเภทอื่น
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = res.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? decodeURI(/filename="?([^"]+)"?/.exec(contentDisposition)[1])
          : "template";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    }
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5"> นำเข้า admin หน่วยงาน</Typography>
        <Typography variant="subtitle1">
          นำเข้าไฟล์ข้อมูลของรายชื่อพนักงานที่ต้องการให้สิทธิ์ Admin หน่วยงาน
          TPO ต้องใช้ไฟล์อัพโหลดตามข้อมูล{" "}
          <span
            onClick={() => {
              previewFiles("/api/template/admin");
            }}
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
          >
            Template
          </span>{" "}
          เท่านั้น
        </Typography>
        <Typography
          variant="body2"
          sx={{
            wordWrap: "break-word",
          }}
          color="text.secondary"
        >
          ** ข้อควรรู้ : การอัพโหลดระบบจะทำการปลดสิทธิ์ Admin หน่วยงาน TPO
          ของพนักงานเดิมที่มีอยู่ออกก่อน แล้วระบบทำการเพิ่มสิทธิ์ Admin หน่วยงาน
          TPO ให้แก่พนักงานตามข้อมูลในไฟล์อัพโหลด
          
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={UploadForm}
            isExternal={isExternal}
            loading={loading}
          />
        </Paper>
      </Grid>

      <ConfirmDialog
        open={confirmReset}
        onClose={() => setConfirmReset(false)}
        cfBtnProps={{
          label: "ยืนยัน",
          color: "error",
          startIcon: <CloudSync />,
        }}
        onConfirm={handleConfirm}
        title="ยืนยัน"
        width="480px"
        height="220px"
      >
        ยืนยัน การนำข้อมูลของรายชื่อพนักงานที่ต้องการให้สิทธิ์ Admin หน่วยงาน
        TPO ซึ่งสิทธิ์ของพนักงานเดิมจะหายไป
      </ConfirmDialog>

      <ConfirmDialog
        open={response !== null}
        onClose={() => {
          setConfirmReset(false);
          setResponse(null);
        }}
        cfBtnProps={{
          label: "รับทราบ",
          color: "primary",
        }}
        onConfirm={() => {
          setResponse(null);
        }}
        title="สถานะการนำเข้าข้อมูล"
        width="480px"
        height="420px"
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                รายการ
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontWeight: "bold",
                }}
              >
                ค่า
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                rows ใน excel ทั้งหมด (ไม่นับ head)
              </TableCell>
              <TableCell align="right">{response?.total_records}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                จำนวนที่ปลด
              </TableCell>
              <TableCell align="right">{response?.total_dismiss}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                จำนวนที่ไม่สำเร็จ
              </TableCell>
              <TableCell align="right">{response?.total_faild}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                จำนวนที่สำเร็จ
              </TableCell>
              <TableCell align="right">{response?.total_success}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ConfirmDialog>
    </>
  );
}
