import {
  Alert,
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../apis";
import RequestFormReadOnly, {
  mapToInit,
} from "./request-forms/RequestFormReadOnly";
import { Send, Delete } from "@mui/icons-material";
import RequestSendConfirm from "./RequestSendConfirm";
import { getDescChip } from "../../../data/requestStatus";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import RequestStepper from "../../templates/RequestStepper";
import ReqDownloadListBtn from "../../customs/ReqDownloadListBtn";
import ResponseList from "../../customs/ResponseList";
import letterTypes from "../../../data/selects/letterTypes";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

const isStepFail = (step) => step === "reject";

export default function RequestView() {
  const params = useParams();
  const caseId = parseInt(params.id);
  const reqId = parseInt(params.reqId);
  const navigate = useNavigate();
  const requests = useSelector(
    (state) => state.auth.user.permissions.requests ?? []
  );
  const extorg_request_list = useSelector(
    (state) => state.auth.user.permissions.extorg_request_list ?? []
  );
  const [item, setItem] = useState(null);
  // for fetch error
  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  const { data: getDetailUser } = useQuery(
    ["getDetailUser"],
    () => api.get(`/api/me`).then((res) => res?.data),
    {
      refetchOnMount: false,
    }

    // { staleTime: 60 * 1000 }
  );

  useEffect(() => {
    let isSubscribed = true;

    if (item) {
      return undefined;
    }

    api
      .get(`/api/mgt/cases/${caseId}/pol_requests/${reqId}`)
      .then(({ data }) => {
        isSubscribed && setItem(data);
      })
      // .then(() => isSubscribed && setLoading(false))
      .catch(({ response }) => {
        if (response && response.status === 404) {
          SetFetchError("เกิดข้อผิดพลาด");
        }
      });
    return () => {
      isSubscribed = false;
    };
  }, [caseId, item, reqId]);

  const reqCode = useMemo(() => {
    if (!item) return undefined;

    const lt = letterTypes.find((e) => e.value === item?.request_type_id);
    return lt?.label;
  }, [item]);

  // request send confirm state
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);
  const handleConfirm = () => {
    // reload
    setItem(null);
  };

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/mgt/cases/${caseId}/pol_requests/${reqId}`)
      .then(() => {
        navigate(`/cases/${caseId}/mgt`);
      })
      .catch(() => {
        SetFetchError("เกิดข้อผิดพลาด");
      });
  };

  return (
    <>
      <ConfirmDialog
        open={cfDel}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ รายการคำขอนี้่
      </ConfirmDialog>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      {item && (
        <RequestSendConfirm
          open={openModal}
          onClose={handleCloseModal}
          request={item}
          onConfirm={handleConfirm}
        />
      )}
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={9}>
            <Stack direction="row" spacing={1}>
              <Typography variant="h6">
                ข้อมูลคำขอ {reqCode} SET ID:{item?.detail_id}
              </Typography>
              {item?.status && getDescChip(item.status)}
              {item?.status === "new" && (
                <Chip
                  label="กรุณายืนยัน"
                  color="warning"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              )}
            </Stack>
            {item?.status === "new" && (
              <Typography variant="subtitle2" color="error" sx={{ mt: 1 }}>
                กรุณาตรวจสอบและยืนยันข้อมูลก่อนส่งไปยังผู้ประสานงาน(contact
                person)
                หากพบว่าไม่ถูกต้องท่านสามรถลบคำขอนี้และดำเนินการสร้างใหม่
              </Typography>
            )}
          </Grid>
          <Grid item sm={12} md={3} textAlign="right">
            {(extorg_request_list.includes("download") ||
              requests.includes("download")) && (
              <ReqDownloadListBtn links={item?.links} />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {getDetailUser?.isInternal ? (
          <Paper sx={{ p: 2, mb: 3 }}>
            {item?.status ? (
              <RequestStepper status={item.status} note={item.status_note} />
            ) : null}
            {item?.response_list?.length && (
              <>
                <Divider
                  style={{
                    marginBottom: "16px",
                    marginTop: "16px",
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color={isStepFail(item?.status) && "error"}
                  paragraph
                  sx={{ overflowWrap: "break-word" }}
                >
                  หมายเหตุ: {item?.status_note ? item?.status_note : "-"}
                </Typography>

                <Divider sx={{ mb: 2 }}>ข้อมูลตอบกลับ</Divider>
                <ResponseList items={item?.response_list} />
              </>
            )}
          </Paper>
        ) : (
          <Paper sx={{ p: 2, mb: 3 }}>
            <Divider
              style={{
                marginBottom: "16px",
                marginTop: "16px",
              }}
            />
            <Typography
              variant="subtitle1"
              color={isStepFail(item?.status) && "error"}
              paragraph
              sx={{ overflowWrap: "break-word" }}
            >
              หมายเหตุ: {item?.status_note ? item?.status_note : "-"}
            </Typography>
            <Divider sx={{ mb: 2 }}>ข้อมูลตอบกลับ</Divider>
            <ResponseList items={item?.response_list} />
          </Paper>
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {!item ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <Form
                // validate={validate}
                readOnly
                onSubmit={() => undefined}
                component={RequestFormReadOnly}
                initialValues={mapToInit(item)}
              />
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    {item.status === "new" && (
                      <>
                        <Button
                          type="button"
                          variant="contained"
                          startIcon={<Send />}
                          onClick={() => handleOpenModal()}
                        >
                          ตรวจสอบคำขอ
                        </Button>
                        {requests.includes("delete") && (
                          <Button
                            type="button"
                            variant="contained"
                            color="error"
                            startIcon={<Delete />}
                            onClick={() => setCfDel(true)}
                          >
                            ลบ
                          </Button>
                        )}
                      </>
                    )}
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={() => navigate(`/cases/${caseId}/mgt`)}
                    >
                      กลับไปจัดการ Case
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
    </>
  );
}
