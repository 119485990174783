import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { hr03RequestAccountActiveCreate } from "../../../../ducks/hr03List";
import TableHistoryUsedAccount from "./component/TableHistoryUsedAccount";
import Hr03RequestActiveForm, {
  validate,
} from "./Form/Hr03RequestAccountActiveForm";
import Resizer from "react-image-file-resizer";
import Hr03RequestAccountActiveDetailPage from "./Hr03RequestAccountActiveDetailPage";

export default function Hr03RequestAccountActiveDuplicatePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [dataHr03, setDataHr03] = useState();
  const [loading, setLoading] = useState(false);
  const [idFromHr03, setIdFromHr03] = useState(null);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason && reason.type === "error") {
        SetToastInfo(reason);
      }
      setOpenModal(false);
    },
    [dispatch]
  );
  const [openModalHistory, setOpenModalHistory] = React.useState(false);
  const handleOpenModalHistory = (page) => setOpenModalHistory(page);
  const handleCloseModalHistory = () => setOpenModalHistory(false);
  useEffect(() => {
    if (location && location.state) {
      setDataHr03(_.get(location, "state"));
    } else {
      navigate("/hr03/request-account-active");
    }
  }, [location]);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const processFormFiles = (files) => {
    return Object.entries(files)
      .map(([key, fileArray]) => {
        const templateId = parseInt(key.replace("file", ""), 10);
        if (isNaN(templateId)) return null;

        if (!Array.isArray(fileArray)) return null;

        const numbers = fileArray
          .map((item) => {
            const match = item?.url?.match(/(\d+)$/);
            return match ? parseInt(match[1], 10) : null;
          })
          .filter(Boolean);

        const newFile = fileArray.filter((item) => item.path);

        return {
          template_id: templateId,
          numbers,
          newFile,
        };
      })
      .filter(Boolean);
  };

  const processAttachments = async (attachmentsAfter) => {
    const results = await Promise.all(
      attachmentsAfter.map(async (entry) => {
        const files = await Promise.all(
          entry.newFile.map(async (item) => {
            const base64File = await toBase64(item);
            return {
              template_id: entry.template_id,
              file_name: item.name,
              file: base64File,
              status: "CREATE",
            };
          })
        );
        return files;
      })
    );

    return results.flat();
  };

  const handleExistAttachments = (attachmentsAfter, attachmentsBefore) =>
    attachmentsBefore.filter(
      (entry) =>
        !attachmentsAfter.filter((el) => el.template_id === entry.template_id)
          .length > 0
    );

  const signatureResize = (file) => {
    const fileType = file.type === "image/png" ? "PNG" : "JPEG";
    const compress = file.type === "image/png" ? 100 : 90;
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        90,
        50,
        fileType,
        compress,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  };

  const onSubmit = async (data) => {
    const objData = { ...data };

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

      let attachment_more = await Promise.all(
        [...objData?.receiveDataOtherFile?.data].map(async (file) => {
          const base64File = await toBase64(file.file[0]); // แปลงไฟล์เป็น Base64
          return {
            template_id: file.template_id,
            file_name: file.file_name,
            file: base64File, // ใช้ Base64 string
            original_file_name: file.original_file_name, // ชื่อไฟล์เดิม
          };
        })
      );

    const formData = new FormData();

    formData.append("refer_id", objData.id);
    formData.append("type_request", objData.type_request);
    formData.append("type", objData.type);
    formData.append("request_org", objData.policestation_org_code);
    formData.append("hr03_id", objData.hr03_id);
    formData.append("nationality", objData.nationality);
    formData.append("birth_date", objData.birth_date);
    formData.append("attachment_more", JSON.stringify(attachment_more));

    if (objData.req_type_request === 2) {
      formData.append("bank_id", objData.bank_id);
      formData.append("bank_number", objData.bank_number);
      formData.append("bank_name", objData.bank_name);
    }
    formData.append("discription_bank", objData.discription_bank);
    formData.append("is_declare_note", objData.is_declare_note);
    if (objData.is_declare_note === 1) {
      formData.append("declare_note", objData.declare_note);
    }
    formData.append("full_name", objData.fullName);
    formData.append("card_id", objData.card_id);
    formData.append("request_date", objData.date);
    formData.append("book_no", objData.no);
    try {
      if (!objData?.attachments) {
        throw new Error("No attachments found in item");
      }

      const attachmentsBefore = objData.attachments.map((el) => ({
        ...el,
        template_id: objData.template_attachment.find(
          (it) => it.ref_template_id?.toString() === el.template_file_id
        ).id,
      }));

      if (!data?.files) {
        throw new Error("No files found in data");
      }

      const attachmentsAfter = processFormFiles(data.files);

      // Process both new and deleted attachments
      const newAttachments = await processAttachments(attachmentsAfter);
      // const filtedAttachments = handleExistAttachments(
      //   attachmentsAfter,
      //   attachmentsBefore
      // );
      // Combine all attachments into a single array
      const allAttachments = [
        ...newAttachments.map((attachment) => ({
          ...attachment,
        })),
        // ...filtedAttachments.map((attachment) => ({
        //   ...attachment,
        // })),
      ];

      formData.append("attachment", JSON.stringify(allAttachments));

      // TODO: Add your API call here
      console.log("Final form data:", allAttachments);
    } catch (error) {
      console.error("Error processing form submission:", error);
      // Handle error (e.g., show toast notification)
    }

    const base64Signature =
      typeof objData.pol_signature === "string"
        ? objData.pol_signature
        : await signatureResize(objData.pol_signature);
    formData.append("pol_signature", base64Signature);

    return dispatch(hr03RequestAccountActiveCreate(formData))
      .then(() => {
        navigate("/hr03/request-account-active");
        toast.success("สร้างสำเร็จ");
      })
      .catch(({ response }) => {
        if (response?.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  const handleOpenTableReferDetailPage = (id) => {
    handleOpenModal(
      <>
        <Hr03RequestAccountActiveDetailPage
          handleCancel={handleCloseModal}
          itemId={id}
          isViewOnly
        />
      </>
    );
  };

  return (
    <>
      <Modal
        open={openModalHistory ? true : false}
        onClose={() => handleCloseModalHistory()}
        aria-labelledby="modal-modal-title-history"
        aria-describedby="modal-modal-description-history"
      >
        <div>{openModalHistory}</div>
      </Modal>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">
          เพิ่มข้อมูลคำร้องขอเปิดใช้งานบัญชี HR03{" "}
          <Chip
            onClick={() =>
              dataHr03?.total_open_worksheet > 0 &&
              handleOpenModal(
                <TableHistoryUsedAccount
                  handleCancel={handleCloseModal}
                  apiWorksheet={dataHr03?.worksheet}
                  hrNumber={dataHr03?.ref_code}
                  handleOpenModal={handleOpenModalHistory}
                  handleCloseModal={handleCloseModalHistory}
                />
              )
            }
            disabled={dataHr03?.total_open_worksheet < 1}
            label={`เปิดไปเเล้ว ${dataHr03?.total_open_worksheet ?? 0} รายการ`}
            color="default"
            sx={{
              mx: 1,
              height: "25px",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                fontFamily: "Kanit",
                fontSize: "14px",
              },
            }}
          />
          {dataHr03?.id && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenTableReferDetailPage(dataHr03?.id)}
              sx={{
                mx: 1,
                height: "30px",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                  fontFamily: "Kanit",
                  fontSize: "14px",
                },
                boxShadow:"none",
                borderRadius: "20px",
                '&:hover': {
                  boxShadow: "none",
                },
              }}
            >
              Refer. ID {dataHr03?.id ?? "-"}
            </Button>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              initialValues={dataHr03}
              onSubmit={onSubmit}
              component={Hr03RequestActiveForm}
              page="add"
              isDuplicate
              idFromHr03={(id) => {
                setIdFromHr03(id);
              }}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
