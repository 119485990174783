import {
  Cancel,
  Check,
  CheckCircle,
  Description,
  InsertChart,
  SystemUpdateAlt,
} from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../../apis";
import countryOptions from "../../../../../data/selects/countries";
import {
  hr03ApproveRequest,
  hr03ApproveUploadOgtherFilesRequest,
} from "../../../../../ducks/hr03List";
import { getTextMask } from "../../../../../helpers/imask";
import thaiIdValidator from "../../../../../helpers/ThaiIdValidator";
import FieldAsyncAutoComplete from "../../../../form-fields/FieldAsyncAutoComplete";
import FieldAsyncSelect from "../../../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../../../form-fields/FieldDatePicker";
import FieldInput from "../../../../form-fields/FieldInput";
import FieldSelect from "../../../../form-fields/FieldSelect";
import SignaturePreview from "../../../requests/request-forms/SignaturePreview";
import Hr03Stepper from "../component/Hr03Stepper";
import ModalReject from "./Hr03RejectForm";
import TableOtherFiles from "../../Hr03RequestAccountActive/component/TableOtherFiles";
import { defaultJoiDates } from "../../../../../helpers/dateHelper";
import { startOfToday } from "date-fns";

const TextMaskCustom = getTextMask({
  mask: "00000000000000",
  unmask: true,
  overwrite: true,
});

export default function Hr03VerifyAccountActiveFormDetail({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  page,
  id,
  idFromHr03,
  handleCloseModal,
  setReload,
  handleOpenTableHistory,
  loading,
  setLoading,
  isViewOnly,
}) {
  const verify_request_account_HR03_process = useSelector(
    (state) =>
      state.auth.user?.permissions?.verify_request_account_HR03_process ?? []
  );
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [openDialogError, setOpenDialogError] = useState(false);
  const [errorDialog, setErrorDialog] = useState("");
  const [statusReject, setStatusReject] = useState("");
  const [anchorElReject, setAnchorElReject] = useState(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [receiveDataOtherFile, setReceiveDataOtherFile] = useState();

  const openReject = Boolean(anchorElReject);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading: isLoadingRequestTypeOptions, data: requestTypeOptions } =
    useQuery(
      ["requestTypeOptions"],
      () =>
        api.get(`/api/openbankaccount/type_request`).then((res) => {
          return res.data.map((row) => {
            return {
              value: row.create_form_type,
              label: row.name,
            };
          });
        }),
      { staleTime: 60 * 1000, enabled: !!id }
    );

  const handleCloseReject = () => {
    setAnchorElReject(null);
  };

  const handleClickReject = (event) => {
    setAnchorElReject(event.currentTarget);
  };

  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(
    [
      "bankSelect",
      "/api/banks/list/bank",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/banks/list/bank").then((res) =>
        res?.data?.map((row) => ({
          value: row.code,
          label: `${row.name} (${row.short_name})`,
        }))
      ),
    { staleTime: 3 * 60 * 1000 }
  );

  const groupAttachments = useMemo(() => {
    return values?.template_attachment?.map((template) => {
      // Find all matching attachments for this template
      const matchingAttachments = values?.attachments?.filter(
        (attachment) => attachment.template_file_id === template.id.toString()
      );

      // Return combined object
      return {
        ...template,
        attachments: matchingAttachments,
      };
    });
  }, [values]);

  const previewFiles = useCallback(async (fileUrl) => {
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });

      // ดึง content-type จาก header
      const contentType = res.headers["content-type"];
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: contentType })
      );

      // ตรวจสอบประเภทไฟล์จาก content-type
      if (contentType === "application/pdf") {
        // กรณีเป็น PDF
        const pdfWindow = window.open(url);
        pdfWindow.onload = () => {
          pdfWindow.print();
        };
      } else {
        // กรณีเป็นไฟล์ประเภทอื่น
        const link = document.createElement("a");
        link.href = url;

        // กำหนดนามสกุลไฟล์ตาม content-type
        let extension = "";
        switch (contentType) {
          case "image/jpeg":
            extension = ".jpg";
            break;
          case "image/png":
            extension = ".png";
            break;
          case "application/msword":
            extension = ".doc";
            break;
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            extension = ".docx";
            break;
          case "application/vnd.ms-excel":
            extension = ".xls";
            break;
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            extension = ".xlsx";
            break;
          default:
            extension = "";
        }

        const fileName = `file${extension}`; // สามารถเปลี่ยนชื่อไฟล์ตามต้องการ
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    }
  }, []);

  const downloadFile = useCallback(async (fileUrl) => {
    setIsLoadingDownload(true);
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });
      // ดึงนามสกุลไฟล์จากชื่อไฟล์
      let fileName = (
        res?.headers["content-disposition"] ?? "filename=File"
      ).split("filename=")[1];
      fileName = fileName.at(-1) === '"' ? fileName.slice(1, -1) : fileName;
      let fullFileName = "";
      const contentType = res.headers["content-type"];
      if (contentType.includes("text/csv")) {
        fullFileName = `${fileName}.csv`; // Set the desired file name for the download
      } else if (contentType.includes("application/zip")) {
        fullFileName = `${fileName}.zip`; // Set the desired file name for the download
      } else if (contentType.includes("text/plain")) {
        fullFileName = `${fileName}.txt`; // Set the desired file name for the download
      } else {
        fullFileName = `${fileName}.csv`;
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));

      // สร้าง element <a> สำหรับดาวน์โหลดไฟล์
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fullFileName); // กำหนดชื่อไฟล์ + นามสกุล

      document.body.appendChild(link);

      link.click();

      // ล้างหน่วยความจำ

      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    } finally {
      setIsLoadingDownload(false);
    }
  }, []);

  useEffect(() => {
    console.log("values=>>>", values);
  }, [values]);

  return (
    <>
      <Dialog
        open={openDialogError}
        onClose={() => {
          setOpenDialogError(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorDialog ?? "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialogError(false);
              setErrorDialog("");
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Hr03Stepper
          status={values?.status}
          note={values?.current_remark}
          type={values?.type}
        />
      </Paper>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={3}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }} textAlign="center">
              ข้อมูลดั้งเดิม
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldSelect
              name="request_type"
              label="ประเภทคำร้องขอเปิดใช้งาน"
              required
              controlProp={{ fullWidth: true }}
              options={requestTypeOptions}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="hr03_number"
              label="Ref code"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              inputProps={{
                placeholder: "กรุณากรอกเลข Ref code",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={"hr03.card_id"}
              label={"เลขบัตรประชาชน"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="fullName"
              label={"ชื่อ-สกุล"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกชื่อ-สกุล",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name={"hr03.bankcode"}
              label="ธนาคาร"
              required
              controlProp={{ fullWidth: true }}
              inputProps={{
                sx: { width: "100%" },
                loading: isBankOptionsLoading || bankOptionsError,
                loadingText: bankOptionsError ? "Error" : "Loading…",
              }}
              options={bankOptions}
              readOnly={true}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="hr03.accountno"
              label={"หมายเลขบัญชี"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน",
              }}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              ข้อมูล{" "}
              <Typography
                component={"span"}
                sx={{ fontSize: 20, fontWeight: 500 }}
                color="warning.main"
              >
                {values?.type === 1 ? "ขอถอนรายชื่อ" : "ขอเปิดรายบัญชี"}
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <FieldAsyncAutoComplete
              name="request_org"
              label="จากหน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly={true}
              basePath={`/api/policestations`}
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
              inputProps={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="card_id"
              label={"เลขบัตรประชาชน/เลขพาสปอร์ต"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน/เลขพาสปอร์ต",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={"full_name"}
              label={"ชื่อ-สกุล"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              inputProps={{
                placeholder: "กรุณากรอกชื่อ-สกุล",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name="nationality"
              label="สัญชาติ/ประเทศ"
              required
              options={countryOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldDatePicker
              name="birth_date"
              label="วันเกิด"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="book_no"
              label="เลขหนังสือ"
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">ตช.</InputAdornment>
                ),
                placeholder: "ตัวอย่างเช่น 0039.12/123",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldDatePicker
              name="request_date"
              label="ลงวันที่"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              readOnly={true}
              storeFormat="yyyy-MM-dd"
            />
          </Grid>
          {values?.type === 2 && (
            <>
              <Grid item xs={12}>
                <FieldAutoComplete
                  name={"bank.code"}
                  label="ธนาคาร"
                  required
                  controlProp={{ fullWidth: true }}
                  inputProps={{
                    sx: { width: "100%" },
                    loading: isBankOptionsLoading || bankOptionsError,
                    loadingText: bankOptionsError ? "Error" : "Loading…",
                  }}
                  options={bankOptions}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name="bank_number"
                  label="หมายเลขบัญชีที่ต้องการใช้งาน"
                  required
                  readOnly={true}
                  controlProps={{ fullWidth: true }}
                  inputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name="bank_name"
                  label="ชื่อบัญชีที่ต้องการขอเปิด"
                  required
                  controlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder: "ข้อมูลเท่าที่ทราบ",
                  }}
                  readOnly={true}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FieldAsyncSelect
              name="discription_bank"
              label="เหตุผลขอเปิดใช้บัญชีนี้"
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              basePath={`/api/openbankaccount/requests/description/bank`}
              itemFormat={(row) => {
                return {
                  value: row.id,
                  label: row.name,
                };
              }}
              readOnly={true}
            />
          </Grid>
          {values.is_declare_note === 1 && (
            <Grid item xs={12}>
              <FieldInput
                name="declare_note"
                label="เหตุผลเพิ่มเติม"
                controlProps={{ fullWidth: true }}
                inputProps={{ multiline: true, maxRows: 6, minRows: 3 }}
                readOnly
              />
            </Grid>
          )}
          <Grid item xs={12} mt={3}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }} textAlign="center">
              File Upload ประกอบขอถอนรายชื่อ
              {values.dowload_zip && (
                <Tooltip title="ดาวน์โหลดเอกสาร zip">
                  <Button
                    type="button"
                    onClick={() => downloadFile(values.dowload_zip)}
                    disabled={isLoadingDownload}
                    color="primary"
                    sx={{ fontSize: 20, fontWeight: 500, ml: 1 }}
                  >
                    <SystemUpdateAlt /> ZIP
                  </Button>
                </Tooltip>
              )}
            </Divider>
          </Grid>

          <Box
            sx={{
              marginX: 2,
              marginTop: 4,
            }}
          >
            <Grid container spacing={2}>
              {groupAttachments?.map((section, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Typography variant="h6" gutterBottom>
                    {section.title}
                  </Typography>
                  <List>
                    {section.attachments.length > 0 ? (
                      section.attachments.map((file, fileIndex) => (
                        <ListItem key={fileIndex}>
                          <ListItemIcon>
                            <Description color="error" />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography
                              onClick={async () =>
                                await previewFiles(
                                  file.url_download,
                                  file.file_name
                                )
                              }
                              sx={{
                                cursor: "pointer",
                                color: "primary.main",
                                textDecoration: "underline",
                                "&:hover": {
                                  opacity: 0.8,
                                },
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px", // หรือกำหนดขนาดตามที่ต้องการ
                              }}
                              title={file.file_name} // แสดงชื่อเต็มเมื่อ hover
                            >
                              {file.file_name}
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      ))
                    ) : (
                      <ListItem>
                        <ListItemIcon>
                          <InsertDriveFileIcon color="disabled" />
                        </ListItemIcon>
                        <ListItemText primary="ไม่มีไฟล์อัปโหลด" />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              เอกสารอื่นๆ
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TableOtherFiles
              typeCurrent={values?.template_attachment}
              values={values}
              isViewOnly={!values?.approve}
              receiveData={setReceiveDataOtherFile}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              ข้อมูลพนักงานสอบสวน
            </Divider>
          </Grid>
          <Grid item xs={12} md={2}>
            <FieldInput
              name="rank"
              label="ยศ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name="fname"
              label="ชื่อ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name="lname"
              label="สกุล"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="job_title"
              label="ตำแหน่ง"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAsyncAutoComplete
              name="org_code"
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly={true}
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              // itemFilter={(row) =>
              //   !row.ORG_CODE?.startsWith("0") && !row.ORG_CODE?.startsWith("1")
              // }
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="phone_number"
              label="โทรศัพท์"
              required
              controlProps={{ fullWidth: true }}
              readOnly
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="subtitle1">ลายเซ็น *</Typography>

            <SignaturePreview name="pol_signature" />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }}></Divider>
          </Grid>
          <Grid container spacing={1} sx={{ p: 1, marginTop: 2 }}>
            <Grid
              item
              xs={12}
              sm={8}
              sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}
            >
              {!isViewOnly &&
              values.approve &&
              values.status === "rtp_approve" ? (
                <>
                  {verify_request_account_HR03_process.includes("confirm") && (
                    <LoadingButton
                      variant="contained"
                      color="success"
                      startIcon={<Check />}
                      onClick={async () => {
                        setLoading(true);
                        const formData = new FormData();
                        formData.append("status", "aml_approve");

                        dispatch(
                          hr03ApproveRequest(values.id, formData, "/process")
                        )
                          .then(() => {
                            handleCloseModal();
                            toast.success("ดำเนินการสำเร็จ");
                            setReload && setReload(Math.random());
                          })
                          .catch(({ response }) => {
                            setErrorDialog(
                              response.data.message ??
                                "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง"
                            );
                            setOpenDialogError(true);
                          })
                          .finally(() => setLoading(true));
                      }}
                      loading={loading}
                      disabled={loading}
                    >
                      ผ่านการพิจารณา
                    </LoadingButton>
                  )}
                  {verify_request_account_HR03_process.includes("denied") && (
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      startIcon={<Cancel />}
                      onClick={async () => {
                        setOpen(true);
                      }}
                      loading={loading}
                      disabled={loading}
                    >
                      ไม่ผ่านพิจารณาข้อยุติจากที่ประชุม
                    </LoadingButton>
                  )}
                </>
              ) : null}

              {!isViewOnly &&
              verify_request_account_HR03_process.includes("confirm") &&
              values?.approve &&
              values.status !== "rtp_approve" ? (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  startIcon={<CheckCircle />}
                  onClick={async () => {
                    try {
                      const toBase64 = (file) =>
                        new Promise((resolve, reject) => {
                          const reader = new FileReader();
                          reader.readAsDataURL(file);
                          reader.onload = () => resolve(reader.result);
                          reader.onerror = reject;
                        });

                      const IdOlderFiles = values?.attachments
                        .filter((item) => item.is_more)
                        .map((item) => item.id);

                      const newFilesUpload = receiveDataOtherFile?.data;

                      // 1. หาไฟล์ที่หายไป
                      const missingFiles = IdOlderFiles.filter(
                        (id) => !newFilesUpload.some((file) => file.id === id)
                      );

                      const missingFilesData = missingFiles.map((id) => {
                        const oldFile = values.attachments.find(
                          (file) => file.id === id
                        );
                        return {
                          id: id,
                          status: "DELETE",
                          template_id: oldFile?.template_file_id || null,
                        };
                      });

                      // 2. หาไฟล์ใหม่
                      const newFiles = newFilesUpload?.filter(
                        (file) => !IdOlderFiles.includes(file.id)
                      );

                      const newFilesData = newFiles
                        ? await Promise.all(
                            newFiles.map(async (file) => {
                              const base64File = await toBase64(file.file[0]);
                              return {
                                status: "CREATE",
                                template_id: file.template_id || null,
                                file: base64File,
                                file_name: file.file_name,
                                original_file_name: file.original_file_name,
                              };
                            })
                          )
                        : [];

                      const allFilesData = [
                        ...missingFilesData,
                        ...newFilesData,
                      ];

                      setLoading(true);

                      const formData = new FormData();
                      formData.append("status", "aml_approve");

                      const formDataUpload = new FormData();
                      formDataUpload.append(
                        "attachment_more",
                        JSON.stringify(allFilesData)
                      );
                      formDataUpload.append("_method", "PUT");

                      //  **รอให้ `hr03ApproveUploadOgtherFilesRequest` ทำงานเสร็จก่อน**
                      await dispatch(
                        hr03ApproveUploadOgtherFilesRequest(
                          values.id,
                          formDataUpload
                        )
                      );

                      //  **จากนั้นค่อยยิง `hr03ApproveRequest`**
                      await dispatch(
                        hr03ApproveRequest(values.id, formData, "/process")
                      );

                      // ถ้าสำเร็จ, ปิด modal และแสดง toast
                      handleCloseModal();
                      toast.success("ดำเนินการสำเร็จ");

                      setReload && setReload(Math.random());
                    } catch (error) {
                      const response = error?.response;
                      setErrorDialog(
                        response?.data?.message ??
                          "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง"
                      );
                      setOpenDialogError(true);
                    } finally {
                      setLoading(false);
                    }
                  }}
                  loading={loading}
                  disabled={loading}
                >
                  ตรวจแล้วถูกต้อง
                </LoadingButton>
              ) : (
                ""
              )}
              <Button
                variant="outlined"
                color="primary"
                disabled={loading}
                onClick={handleOpenTableHistory}
                startIcon={<InsertChart />}
              >
                ประวัติของรายการ
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                flexWrap: "wrap", // ให้มีการไหลในกรณีที่ปุ่มเยอะเกิน
              }}
            >
              {!isViewOnly &&
                verify_request_account_HR03_process.includes("denied") &&
                values.approve &&
                values.status === "rtp_approve" && (
                  <>
                    <LoadingButton
                      type="button"
                      variant="contained"
                      color="error"
                      startIcon={<Cancel />}
                      loadingPosition="start"
                      onClick={handleClickReject}
                      aria-controls={openReject ? "Reject-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={openReject ? "true" : undefined}
                      disabled={loading}
                    >
                      ไม่ผ่านการพิจารณา นำส่งใหม่
                    </LoadingButton>
                    <Menu
                      id="Reject-menu"
                      anchorEl={anchorElReject}
                      open={openReject}
                      onClose={() => {
                        handleCloseReject();
                        setStatusReject("");
                      }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        onClick={async () => {
                          setOpen(true);
                          setStatusReject("aml_reject");
                        }}
                      >
                        ไม่ผ่านต้องแก้ไขมาใหม่ แบบรายชื่อ
                      </MenuItem>
                      <MenuItem
                        onClick={async () => {
                          setOpen(true);
                          setStatusReject("aml_reject_account");
                        }}
                      >
                        ไม่ผ่านต้องเพิ่มเอกสาร แบบรายบัญชี
                      </MenuItem>
                    </Menu>
                  </>
                )}

              {!isViewOnly &&
              verify_request_account_HR03_process.includes("denied") &&
              values.reject &&
              values.status !== "rtp_approve" ? (
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Cancel />}
                  disabled={loading}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  ปฏิเสธ
                </Button>
              ) : null}

              <Button
                variant="outlined"
                color="primary"
                disabled={loading}
                onClick={() => {
                  handleCloseModal("closeModal");
                }}
              >
                ปิด
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ModalReject
        open={open}
        setOpen={setOpen}
        id={values.id}
        status={values.status}
        statusReject={statusReject}
        setReload={setReload}
        handleCloseModal={handleCloseModal}
        loading={loading}
        setLoading={setLoading}
      />
    </>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema(values).validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateRules = (values) => ({
  type_request: Joi.alternatives()
    .try(Joi.number(), Joi.string(), Joi.array())
    .required()
    .messages({
      "any.required": "กรุณาระบุประเภทคำร้องขอเปิดใช้งาน",
    }),
  policestation_org_code: Joi.string().required().messages({
    "string.empty": "กรุณาระบุหน่วยงาน",
    "any.required": "กรุณาระบุหน่วยงาน",
  }),
  hr03_id: Joi.alternatives().try(Joi.number(), Joi.string()).required(),
  fullName: Joi.string()
    .messages({
      "string.empty": "กรุณากรอกชื่อ-สกุล",
      "any.required": "กรุณากรอกชื่อ-สกุล",
    })
    .required(),
  card_id: Joi.string().custom(thaiIdValidator).required().messages({
    "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
  }),
  date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .required()
    .messages({
      "any.required": "กรุณาเลือกวันที่",
    }),
  no: Joi.string().required().messages({
    "any.required": "กรุณาระบุหมายเลขหนังสือ",
  }),
  bank_id: Joi.when("type", {
    is: Joi.valid(2).required(),
    then: Joi.number().required().messages({
      "any.required": "กรุณาระบุธนาคาร",
    }),
    otherwise: Joi.number().optional(),
  }),
  bank_number: Joi.when("type", {
    is: Joi.valid(2).required(),
    then: Joi.string().required().messages({
      "any.required": "กรุณาระบุหมายเลขบัญชี",
    }),
    otherwise: Joi.string().optional(),
  }),
  bank_name: Joi.when("type", {
    is: Joi.valid(2).required(),
    then: Joi.string().required().messages({
      "any.required": "กรุณาระบุชื่อบัญชี",
    }),
    otherwise: Joi.string().optional(),
  }),
  discription_bank: Joi.number().required().messages({
    "any.required": "กรุณาระบุเหตุผลขอเปิดใช้บัญชีนี้",
  }),
  declare_note: Joi.when("is_declare_note", {
    is: 1,
    then: Joi.string().required().messages({
      "any.required": "กรุณาระบุเหตุผล",
      "string.empty": "กรุณาระบุเหตุผล",
      "string.base": "กรุณาระบุเหตุผล",
    }),
    otherwise: Joi.optional(),
  }),
  nationality: Joi.number().required(),
  birthday: Joi.string()
    .messages({
      "string.pattern.base": "กรุณากรอกวันที่ให้ถูกต้อง เช่น 21/07/1996",
      "any.required": "กรุณาระบุวันเกิด",
    })
    .required(),
  // ...( values.attachments ?
  //   [values.attachments.reduce((acc, el) => {
  //     acc["files.file" + el.id] = Joi.array()
  //       .items(Joi.object())
  //       max(el.is_multiple ? el.max_upload : 1)
  //       .required()
  //       .messages({
  //         "array.min": "กรุณาอัพโหลดไฟล์",
  //         "any.required": "กรุณาอัพโหลดไฟล์",
  //       });
  //     return acc;
  //   })]: []),
  // }),
});

// const schema = () => Joi.object(validateRules()).error(validationMsg());

const schema = (values) => Joi.object(validateRules(values));

export const mapToInit = (item) => {
  return item;
};
