import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import { hr03RequestAccountActiveUpdate } from "../../../../ducks/hr03List";
import Hr03RequestAccountActiveForm, {
  validate,
} from "./Form/Hr03RequestAccountActiveForm";
import TableHistoryUsedAccount from "./component/TableHistoryUsedAccount";
import Resizer from "react-image-file-resizer";
import Hr03RequestAccountActiveDetailPage from "./Hr03RequestAccountActiveDetailPage";

// Utility functions
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const signatureResize = (file) => {
  const fileType = file.type === "image/png" ? "PNG" : "JPEG";
  const compress = file.type === "image/png" ? 100 : 90;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      90,
      50,
      fileType,
      compress,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};
const processFormFiles = (files) => {
  return Object.entries(files)
    .map(([key, fileArray]) => {
      const templateId = parseInt(key.replace("file", ""), 10);
      if (isNaN(templateId)) return null;

      if (!Array.isArray(fileArray)) return null;

      const numbers = fileArray
        .map((item) => {
          const match = item?.url?.match(/(\d+)$/);
          return match ? parseInt(match[1], 10) : null;
        })
        .filter(Boolean);

      const newFile = fileArray.filter((item) => item.path);

      return {
        template_id: templateId,
        numbers,
        newFile,
      };
    })
    .filter(Boolean);
};

const processAttachments = async (attachmentsAfter) => {
  const results = await Promise.all(
    attachmentsAfter.map(async (entry) => {
      const files = await Promise.all(
        entry.newFile.map(async (item) => {
          const base64File = await toBase64(item);
          return {
            template_id: entry.template_id,
            file_name: item.name,
            file: base64File,
            status: "CREATE",
          };
        })
      );
      return files;
    })
  );

  return results.flat();
};

const handleMissingAttachments = (attachmentsAfter, attachmentsBefore) => {
  return attachmentsAfter
    .map((entry) => {
      if (!Array.isArray(entry.numbers)) return [];

      return attachmentsBefore
        .filter((item) => item.template_id === entry.template_id.toString())
        .filter((item) => !entry.numbers.includes(item.id))
        .map((item) => ({
          id: item.id,
          status: "DELETE",
          template_id: entry.template_id,
        }));
    })
    .flat();
};

const ContentSection = ({ children }) => (
  <Grid item xs={12}>
    <Paper
      sx={{
        p: 3,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {children}
    </Paper>
  </Grid>
);

const LoadingSkeleton = () => (
  <>
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
    <Skeleton animation="wave" />
  </>
);

// Main component
const Hr03RequestAccountActiveEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const itemId = parseInt(id);
  const dispatch = useDispatch();
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback((reason) => {
    if (reason && reason.type === "error") {
      SetToastInfo(reason);
    }
    setOpenModal(false);
  }, []);

  const [openModalHistory, setOpenModalHistory] = React.useState(false);
  const handleOpenModalHistory = (page) => setOpenModalHistory(page);
  const handleCloseModalHistory = () => setOpenModalHistory(false);

  const { data: bankOptions } = useQuery(
    ["bankSelect", "/api/banks/list/bank"],
    () => api.get("/api/banks/list/bank").then((res) => res?.data),
    { staleTime: 3 * 60 * 1000 }
  );

  // Fetch HR03 request data
  const {
    isLoading,
    data: item,
    refetch,
  } = useQuery(
    ["requests_open_bank_account", itemId],
    async () => {
      const response = await api.get(`/api/openbankaccount/requests/${itemId}`);
      const data = response.data;
      return {
        ...data,
        is_declare_note: data?.declare_reason?.is_declare_note,
        fullName: `${data?.hr03?.title} ${data?.hr03?.name} ${data?.hr03?.surname}`,
        page: "edit",
      };
    },
    { staleTime: 60 * 1000, cacheTime: 0 }
  );

  useEffect(() => {
    refetch();
  }, [refetch, itemId]);

  const handleSubmit = async (data) => {
    const objData = { ...data };

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

    const IdOlderFiles = item?.attachments
      .filter((item) => item.is_more)
      .map((item) => item.id);
    const newFilesUpload = objData?.receiveDataOtherFile?.data;

    // 1. หาไฟล์ที่หายไป (อยู่ใน IdOlderFiles แต่ไม่อยู่ใน newFilesUpload)
    const missingFiles = IdOlderFiles.filter(
      (id) => !newFilesUpload.some((file) => file.id === id)
    );

    // สร้างออบเจกต์สำหรับไฟล์ที่หายไป
    const missingFilesData = missingFiles.map((id) => {
      const oldFile = item.attachments.find((file) => file.id === id);
      return {
        id: id,
        status: "DELETE",
        template_id: oldFile?.template_file_id || null, // ใช้ template_id จากไฟล์เก่า (ถ้ามี)
      };
    });

    // 2. หาไฟล์ใหม่ (อยู่ใน newFilesUpload แต่ไม่อยู่ใน IdOlderFiles)
    const newFiles = newFilesUpload?.filter(
      (file) => !IdOlderFiles.includes(file.id)
    );

    // สร้างออบเจกต์สำหรับไฟล์ใหม่
    const newFilesData = newFiles
      ? await Promise.all(
          newFiles.map(async (file) => {
            const base64File = await toBase64(file.file[0]); // แปลงไฟล์เป็น Base64
            return {
              status: "CREATE",
              template_id: file.template_id || null, // ใช้ template_id จากไฟล์ใหม่ (ถ้ามี)
              file: base64File,
              file_name: file.file_name,
              original_file_name: file.original_file_name,
            };
          })
        )
      : [];

    const allFilesData = [...missingFilesData, ...newFilesData];

    const formData = new FormData();

    formData.append("type_request", objData.request_type);
    formData.append("type", item.type);
    formData.append("request_org", objData.request_org);
    formData.append("hr03_id", objData.hr03_number);
    formData.append("request_date", objData.request_date);
    formData.append("nationality", objData.nationality);
    formData.append("birth_date", objData.birth_date);
    formData.append("attachments_more", JSON.stringify(allFilesData));
    if (objData.type === 2) {
      formData.append("bank_id", objData.bank_id);
      formData.append("bank_number", objData.bank_number);
      formData.append("bank_name", objData.bank_name);
    }
    formData.append("discription_bank", objData.discription_bank);
    formData.append("is_declare_note", objData.is_declare_note);
    if (objData.is_declare_note === 1) {
      formData.append("declare_note", objData.declare_note);
    }
    formData.append("full_name", objData.full_name);
    formData.append("card_id", objData.card_id);
    formData.append("request_date", objData.request_date);
    formData.append("book_no", objData.book_no);
    formData.append("_method", "PUT");

    try {
      if (!item?.attachments) {
        throw new Error("No attachments found in item");
      }

      const attachmentsBefore = item.attachments.map(
        ({ id, template_file_id }) => ({
          id,
          template_id: template_file_id,
        })
      );

      if (!data?.files) {
        throw new Error("No files found in data");
      }

      const attachmentsAfter = processFormFiles(data.files);

      // Process both new and deleted attachments
      const newAttachments = await processAttachments(attachmentsAfter);
      const deletedAttachments = handleMissingAttachments(
        attachmentsAfter,
        attachmentsBefore
      );

      // Combine all attachments into a single array
      const allAttachments = [
        ...newAttachments.map((attachment) => ({
          ...attachment,
        })),
        ...deletedAttachments.map((attachment) => ({
          ...attachment,
        })),
      ];

      formData.append("attachments", JSON.stringify(allAttachments));

      // TODO: Add your API call here
      console.log("Final form data:", allAttachments);
    } catch (error) {
      console.error("Error processing form submission:", error);
      // Handle error (e.g., show toast notification)
    }

    return dispatch(hr03RequestAccountActiveUpdate(id, formData))
      .then(() => {
        navigate("/hr03/request-account-active");
        toast.success("อัพเดทสำเร็จ");
      })
      .catch(({ response }) => {
        if (response?.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  const handleOpenTableReferDetailPage = (id) => {
    handleOpenModal(
      <>
        <Hr03RequestAccountActiveDetailPage
          handleCancel={handleCloseModal}
          itemId={id}
          isViewOnly
        />
      </>
    );
  };

  useEffect(() => {
    if (!itemId) {
      navigate("/hr03/request-account-active");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return (
    <>
      <Modal
        open={openModalHistory ? true : false}
        onClose={() => handleCloseModalHistory()}
        aria-labelledby="modal-modal-title-history"
        aria-describedby="modal-modal-description-history"
      >
        <div>{openModalHistory}</div>
      </Modal>

      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>

      <Grid item xs={12}>
        <Typography variant="h5">
          แก้ไขข้อมูลคำร้องขอเปิดใช้งานบัญชี HR03{" "}
          <Chip
            onClick={() => {
              item?.total_open_worksheet > 0 &&
                handleOpenModal(
                  <TableHistoryUsedAccount
                    handleCancel={handleCloseModal}
                    apiWorksheet={item?.worksheet}
                    hrNumber={item?.hr03_number}
                    handleOpenModal={handleOpenModalHistory}
                    handleCloseModal={handleCloseModalHistory}
                  />
                );
            }}
            disabled={item?.total_open_worksheet < 1}
            label={`เปิดไปเเล้ว ${item?.total_open_worksheet ?? 0} รายการ`}
            color="default"
            sx={{
              height: "25px",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                fontFamily: "Kanit",
                fontSize: "14px",
              },
            }}
          />
          {item?.refer_id && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenTableReferDetailPage(item?.refer_id)}
              sx={{
                mx: 1,
                height: "30px",
                "& .MuiChip-label": {
                  display: "block",
                  whiteSpace: "normal",
                  fontFamily: "Kanit",
                  fontSize: "14px",
                },
                boxShadow: "none",
                borderRadius: "20px",
                "&:hover": {
                  boxShadow: "none",
                },
              }}
            >
              Refer. ID {item?.refer_id ?? "-"}
            </Button>
          )}
        </Typography>
      </Grid>
      <ContentSection>
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <Form
            validate={validate}
            onSubmit={handleSubmit}
            component={Hr03RequestAccountActiveForm}
            id={itemId}
            page="edit"
            initialValues={item}
          />
        )}
      </ContentSection>
    </>
  );
};

export default Hr03RequestAccountActiveEditPage;
