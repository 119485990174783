import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../../../apis";

const TableHistory = ({ id, setHistoryModal, Apihistory }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state?.setting?.metaUsers?.loading);
  const filter = useSelector((state) => state?.setting?.metaUsers?.filter);
  const reload = useSelector((state) => state?.setting?.metaUsers?.reload);
  const [isLoading, setIsLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);

  const historyRefetch = useCallback(
    async (page = 1, page_size = 10, filter = {}, id) => {
      try {
        setIsLoading(true);
        const res = await api.get(Apihistory, {
          params: {
            page: ++page,
            page_size,
          },
        });
        setHistoryData(res.data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [Apihistory]
  );

  useEffect(() => {
    historyRefetch(page, pageSize, filter, id);
  }, [page, pageSize, filter, id, historyRefetch, reload]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <LoadingButton
          size="small"
          variant="outlined"
          color="info"
          loading={isLoading}
          onClick={() => historyRefetch()}
          startIcon={<Refresh />}
        >
          Reload
        </LoadingButton>
      </GridToolbarContainer>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "His ID",
      sortable: true,
      width: 100,
    },
    {
      field: "old_status",
      headerName: "สถานะ",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        const statusLabel =
          {
            new: "รอยืนยัน",
            sent_to_bk: "บก.พิจารณา",
            bk_reject: "บก.ปฏิเสธ",
            sent_to_bh: "บช.พิจารณา",
            bh_reject: "บช.ปฏิเสธ",
            bh_approve: "ตร.พิจารณา",
            rtp_reject: "ตร.ปฏิเสธ",
            rtp_approve: "ปปง.พิจารณา",
            send_to_bank: "ผ่านการอนุมัติ",
            aml_approve: "ผ่านการพิจารณา",
            aml_reject_end: "ไม่ผ่านข้อยุติพิจารณา",
            aml_reject: "ไม่ผ่านพิจารณา",
            aml_reject_account: "ไม่ผ่าน ดำเนินใหม่รายบัญชี",
            create: "สร้างรายการ",
          }[params.row.old_status] || "ยังไม่มีคำขอ";

        const statusColor =
          {
            new: "warning",
            sent_to_bk: "warning",
            sent_to_bh: "warning",
            bh_approve: "violet",
            create: "warning",
            bk_reject: "error",
            bh_reject: "error",
            rtp_reject: "error",
            aml_reject: "error",
            aml_reject_account: "error",
            rtp_approve: "primary",
            aml_approve: "success",
            send_to_bank: "success",
            aml_reject_end: "pink",
          }[params.row.old_status] || "default";

        return (
          <Chip
            label={statusLabel}
            color={statusColor}
            sx={{
              height: "auto",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                fontFamily: "Kanit",
              },
            }}
          />
        );
      },
    },
    {
      field: "fname",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 200,
      renderCell: (params) => {
        return <>{params.row.fname + " " + params.row.lname ?? "-"}</>;
      },
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {params.row.status === "create" && "สร้างรายการ"}
            {params.row.status === "new" && "ยืนยันรายการ"}
            {["sent_to_bk"].includes(params.row.status) &&
              ["new"].includes(params.row.old_status) &&
              "ส่งขออนุมัติรายการ"}
            {[
              "sent_to_bk",
              "sent_to_bh",
              "bh_approve",
              "rtp_approve",
              "send_to_bank",
              "aml_approve",
            ].includes(params.row.status) &&
              [
                "sent_to_bk",
                "sent_to_bh",
                "bh_approve",
                "rtp_approve",
              ].includes(params.row.old_status) &&
              "อนุมัติรายการ"}
            {["sent_to_bk", "sent_to_bh", "bh_approve", "rtp_approve"].includes(
              params.row.status
            ) &&
              [
                "bk_reject",
                "bh_reject",
                "rtp_reject",
                "aml_reject",
                "aml_reject_account",
                "aml_reject_end",
              ].includes(params.row.old_status) &&
              "ส่งขออนุมัติใหม่"}
            {[
              "bk_reject",
              "bh_reject",
              "rtp_reject",
              "aml_reject",
              "aml_reject_account",
              "aml_reject_end",
            ].includes(params.row.status) && "ปฏิเสธรายการ"}
          </Stack>
        );
      },
    },
    {
      field: "remark",
      headerName: "เหตุผล",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.remark ?? "-"}</>;
      },
    },
    {
      field: "request_open_bank_account_id",
      headerName: "ID",
      sortable: true,
      width: 100,
    },

    {
      field: "rank",
      headerName: "ตำแหน่ง",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.job_title ?? "-"}</>;
      },
    },
    {
      field: "org_code",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.policestation.ORG_ABBR ?? "-"}</>;
      },
    },

    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);
        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const masterModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    px: 4,
    pt: 5,
    overflow: "auto", // ให้ Modal เลื่อนได้เมื่อเนื้อหาเยอะ
    maxHeight: "90vh", // จำกัดความสูงสูงสุดของ Modal
    width: "80%", // กำหนดความกว้างของ Modal
    height: "auto", // ให้ Modal ปรับความสูงตามเนื้อหา
  };

  const modalStyle = {
    ...masterModalStyle,
    height: "80%", // กำหนดความสูงของ Modal
    width: "80%", // กำหนดความกว้างของ Modal
  };

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ประวัติการดำเนินการต่างๆในแต่ละสถานะ (ID: {id})
      </Typography>
      <Box
        sx={{
          height: "60vh", // กำหนดความสูงของ Box ที่ครอบตาราง
          overflow: "auto", // ให้ Box เลื่อนได้เมื่อข้อมูลมีมาก
        }}
      >
        <DataGrid
          rowCount={historyData.total ?? 0}
          disableColumnMenu
          columns={columns}
          rows={_.get(historyData, "data", [])}
          loading={loading || isLoading}
          rowsPerPageOptions={[5, 10, 15, 20]}
          pagination
          paginationMode="server"
          page={page}
          pageSize={pageSize}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          components={{
            Toolbar: CustomToolbar,
          }}
          style={{
            minHeight: "500px", // Changed to set a minimum height

            overflow: "auto",
          }}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          right: 20,
          backgroundColor: "background.paper",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setHistoryModal(false);
          }}
        >
          ปิด
        </Button>
      </Box>
    </Box>
  );
};

export default TableHistory;
