import {
  CheckCircle,
  Delete,
  Edit,
  Refresh,
  RemoveRedEye,
  Restore,
  RestorePage,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Typography,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import {
  usersClearResult,
  usersForceReload,
  usersSetFilter,
} from "../../../../ducks/setting";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import Hr03RequestAccountActiveDetailPage from "./Hr03RequestAccountActiveDetailPage";
import Hr03RequestAccountActivePidSearch from "./Hr03RequestAccountActivePidSearch";
import Hr03RequestAccountActiveSearchForm from "./Hr03RequestAccountActiveSearchForm";

export default function Hr03RequestAccountActive() {
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(usersClearResult());
      dispatch(usersSetFilter({}));
    };
  }, [dispatch]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason && reason.type === "error") {
        SetToastInfo(reason);
      }
      if (reason === "closeModal") {
        setOpenModal(false);
        return;
      }

      usersRefetch(page, pageSize, filter);
      setOpenModal(false);
    },
    [page, pageSize, filter]
  );

  const usersRefetch = useCallback(
    async (page = 1, page_size = 10, filter = {}) => {
      try {
        setIsLoading(true);
        const res = await api.get(`/api/openbankaccount/requests`, {
          params: {
            page,
            page_size,
            ...filter,
          },
        });
        setUsersData(res.data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/openbankaccount/requests/${cfDel}`)
      .then(() => {
        usersRefetch();
        dispatch(usersForceReload());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  useEffect(() => {
    usersRefetch(page, pageSize, filter);
  }, [page, pageSize, filter, usersRefetch]);

  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {(request_account_HR03.includes("view") ||
              request_account_HR03.includes("edit")) && (
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {[
                  "sent_to_bk",
                  "sent_to_bh",
                  "bh_approve",
                  "aml_approve",
                  "rtp_approve",
                  "aml_reject_end",
                  "send_to_bank",
                ].includes(params.row.status) && (
                  <>
                    {request_account_HR03.includes("view") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleOpenModal(
                            <Hr03RequestAccountActiveDetailPage
                              handleCancel={handleCloseModal}
                              itemId={params?.row?.id}
                              isViewOnly={true}
                            />
                          );
                        }}
                      >
                        <RemoveRedEye />
                      </IconButton>
                    )}
                  </>
                )}

                {params.row.status === "new" && (
                  <>
                    {request_account_HR03.includes("view") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleOpenModal(
                            <Hr03RequestAccountActiveDetailPage
                              handleCancel={handleCloseModal}
                              itemId={params?.row?.id}
                              isViewOnly={false}
                            />
                          );
                        }}
                      >
                        <CheckCircle
                          sx={{
                            color: "#1976D3",
                          }}
                        />
                      </IconButton>
                    )}
                    {request_account_HR03.includes("edit") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          navigate(
                            `/hr03/request-account-active/edit/${params?.row?.id}`
                          );
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {request_account_HR03.includes("delete") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setCfDel(params?.row?.id);
                        }}
                      >
                        <Delete
                          sx={{
                            color: "#D32F2F",
                          }}
                        />
                      </IconButton>
                    )}
                  </>
                )}

                {[
                  "bk_reject",
                  "bh_reject",
                  "rtp_reject",
                  "aml_reject",
                ].includes(params.row.status) && (
                  <>
                    {request_account_HR03.includes("view") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleOpenModal(
                            <Hr03RequestAccountActiveDetailPage
                              handleCancel={handleCloseModal}
                              itemId={params?.row?.id}
                              isViewOnly={false}
                            />
                          );
                        }}
                      >
                        <Restore
                          sx={{
                            color: "#1976D3",
                          }}
                        />
                      </IconButton>
                    )}
                    {request_account_HR03.includes("edit") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          navigate(
                            `/hr03/request-account-active/edit/${params?.row?.id}`
                          );
                        }}
                      >
                        <Edit />
                      </IconButton>
                    )}
                    {request_account_HR03.includes("delete") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setCfDel(params?.row?.id);
                        }}
                      >
                        <Delete
                          sx={{
                            color: "#D32F2F",
                          }}
                        />
                      </IconButton>
                    )}
                  </>
                )}

                {["aml_reject_account", "RestorePage"].includes(
                  params.row.status
                ) && (
                  <>
                    {request_account_HR03.includes("create") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                      >
                        <RestorePage
                          sx={{
                            color: "#1976D3",
                          }}
                          onClick={() => onAddHandler(params.row.id)}
                        />
                      </IconButton>
                    )}
                    {request_account_HR03.includes("view") && (
                      <IconButton
                        type="button"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleOpenModal(
                            <Hr03RequestAccountActiveDetailPage
                              handleCancel={handleCloseModal}
                              itemId={params?.row?.id}
                              isViewOnly={true}
                            />
                          );
                        }}
                      >
                        <RemoveRedEye />
                      </IconButton>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 100,
      width: 190,
      renderCell: (params) => {

        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {params.row.status === "new" ? (
              <>
                <Chip
                  label="พงส."
                  color="warning"
                  sx={{
                    ml: 1,
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
                <Chip
                  label="รอยืนยัน"
                  color="warning"
                  sx={{
                    ml: 1,
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              </>
            ) : (
              <Tooltip
                placement="bottom"
                arrow
                title={
                  params?.row?.last_edit_note
                    ? params?.row?.last_edit_note
                    : ""
                }
              >
                <Chip
                  label={
                    params.row.status === "sent_to_bk"
                      ? "บก.พิจารณา"
                      : params.row.status === "bk_reject"
                      ? "บก.ปฏิเสธ"
                      : params.row.status === "sent_to_bh"
                      ? "บช.พิจารณา"
                      : params.row.status === "bh_reject"
                      ? "บช.ปฏิเสธ"
                      : params.row.status === "bh_approve"
                      ? "ตร.พิจารณา"
                      : params.row.status === "rtp_reject"
                      ? "ตร.ปฏิเสธ"
                      : params.row.status === "rtp_approve"
                      ? "ปปง.พิจารณา"
                      : params.row.status === "send_to_bank"
                      ? "ผ่านการอนุมัติ"
                      : params.row.status === "aml_approve"
                      ? "ผ่านการพิจารณา"
                      : params.row.status === "aml_reject_end"
                      ? "ไม่ผ่านข้อยุติพิจารณา"
                      : params.row.status === "aml_reject"
                      ? "ไม่ผ่านพิจารณา"
                      : params.row.status === "aml_reject_account"
                      ? "ไม่ผ่าน ดำเนินใหม่รายบัญชี"
                      : params.row.status === "create"
                      ? "สร้างรายการ"
                      : "ยังไม่มีคำขอ"
                  }
                  color={
                    params.row.status === "sent_to_bk" ||
                    params.row.status === "sent_to_bh" ||
                    params.row.status === "create"
                      ? "warning"
                      : params.row.status === "bk_reject" ||
                        params.row.status === "bh_reject" ||
                        params.row.status === "rtp_reject" ||
                        params.row.status === "aml_reject" ||
                        params.row.status === "aml_reject_account"
                      ? "error"
                      : params.row.status === "rtp_approve"
                      ? "primary"
                      : params.row.status === "aml_approve" ||
                        params.row.status === "send_to_bank"
                      ? "success"
                      : params.row.status === "aml_reject_end"
                      ? "pink"
                      : params.row.status === "bh_approve"
                      ? "violet"
                      : "default" //grey
                  }
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 100,
    },
    {
      field: "hr03_number",
      headerName: "Ref code",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.hr03_number}</>;
      },
    },
    {
      field: "short_name",
      headerName: "ประเภทคำร้อง",
      sortable: true,
      width: 150,
      renderCell: (params) => params.row?.request_form_type?.short_name ?? "-",
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.card_id ?? "-"}</>;
      },
    },
    {
      field: "full_name",
      headerName: "ชื่อ-สกุล ขอถอน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.full_name ?? "-"}</>;
      },
    },

    {
      field: "bank_id",
      headerName: "ธนาคาร",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.bank?.short_name ?? "-"}</>;
      },
    },
    {
      field: "bank_number",
      headerName: "เลขบัญชี",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.bank_number ?? "-"}</>;
      },
    },
    {
      field: "hr03.police_name.pol_name",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.fname + " " + params.row.lname ?? "-"}</>;
      },
    },
    {
      field: "ORG_ABBR",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.policestation?.ORG_ABBR ?? "-"}</>;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    setFilter(formValues);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <LoadingButton
          size="small"
          variant="outlined"
          color="info"
          loading={isLoading}
          onClick={() => usersRefetch()}
          startIcon={<Refresh />}
        >
          Reload
        </LoadingButton>
      </GridToolbarContainer>
    );
  };

  const onAddHandler = async (id) => {
    setIsLoading(true);
    try {
      const res = await api.get(`/api/openbankaccount/change/${id}`);
      navigate(`/hr03/request-account-active/duplicate`, {
        state: {
          id: res.data.id,
          hr_type: res.data.hr_type,
          tpo_case_id: res.data.hr03.tpo_case_id,
          case_no: res.data.hr03.case_no,
          case_org_code: res.data.hr03.case_org_code,
          case_date: res.data.hr03.case_date,
          case_type: res.data.hr03.case_type,
          accountno: res.data.hr03.accountno,
          accounttype: res.data.hr03.accounttype,
          bankcode: res.data.hr03.bankcode,
          bankname: res.data.hr03.bankname,
          ref_code: res.data.hr03_number,
          title: res.data.hr03.title,
          name: res.data.hr03.name,
          midname: res.data.hr03.midname,
          surname: res.data.hr03.surname,
          eng_name: res.data.hr03.eng_name,
          eng_midname: res.data.hr03.eng_midname,
          eng_surname: res.data.hr03.eng_surname,
          birth_date: res.data.hr03.birth_date,
          nationality: res.data.hr03.nationality,
          note: res.data.hr03.note,
          other: res.data.hr03.other,
          rank: res.data.hr03.rank,
          pol_name: res.data.hr03.pol_name,
          pol_surname: res.data.hr03.pol_surname,
          pol_job_title: res.data.hr03.pol_job_title,
          pol_tel: res.data.hr03.pol_tel,
          pol_org_code: res.data.hr03.pol_org_code,
          status: res.data.hr03.status,
          status_note: res.data.hr03.status_note,
          user_id: res.data.hr03.user_id,
          pol_signature: res.data.pol_signature,
          created_at: res.data.hr03.created_at,
          hr_sub_type: res.data.hr03.hr_sub_type,
          hr_aml_meeting: res.data.hr03.hr_aml_meeting,
          api_tpo_victim_fname: res.data.hr03.api_tpo_victim_fname,
          api_tpo_victim_lname: res.data.hr03.api_tpo_victim_lname,
          api_tpo_case_id: res.data.hr03.api_tpo_case_id,
          api_tpo_status: res.data.hr03.api_tpo_status,
          api_tpo_message: res.data.hr03.api_tpo_message,
          api_tpo_date_update: res.data.hr03.api_tpo_date_update,
          // fill form
          policestation_org_code: res.data.org_code,
          no: res.data.book_no,
          date: res.data.request_date,
          bank_id: res.data.bank_id ?? undefined,
          bank_number: res.data.bank_number ?? undefined,
          bank_name: res.data.bank_name ?? undefined,
          card_id: res.data.card_id,
          fullName: res.data.full_name,
          discription_bank: res.data.discription_bank ?? undefined,
          worksheet: res.data.worksheet,
          total_open_worksheet: res.data.total_open_worksheet,
          user: {
            id: res.data.created_by,
            // email: "sent128@koder3.com",
            fname: res.data.fname,
            // email_verified_at: null,
            // created_at: "2024-07-16T06:16:07.000000Z",
            // updated_at: "2025-01-22T14:17:02.000000Z",
            updated_by: res.data.created_by,
            lname: res.data.lname,
            rank: res.data.rank,

            job_title: res.data.job_title,
            pol_tel: res.data.phone_number,
            // pol_email: null,
            // pol_address: null,
            // notify_token: null,
            // hr03_org_code: null,
            // profile_signature: null,
            // profile_checked: null,
            // viewer_org_code: "00000",
            // profile_note: null,
            // active: 1,
            // status: 1,
            // card_id: "3446497162888",
            // birthday: "1991-07-24",
            // password_expired: "2025-04-20 21:17:02",
            // laser_code: null,
            // rolesPermission: [
            //   {
            //     role_id: 1,
            //     role_name: "Super Admin",
            //   },
            //   {
            //     role_id: 5,
            //     role_name: "Super Super Super Admin",
            //   },
            //   {
            //     role_id: 9,
            //     role_name: "Admin หน่วยงาน (Auto TPO)",
            //   },
            // ],
            // deleted_at: null,
            // twofa_secret: null,
            // verify_thai_id: null,
            policestation: res.data.policestation,
          },
          hr_list: {
            id: res.data.hr03_id,
            hr_type: res.data.hr_type,
            card_id: res.data.card_id,
            ref_code: res.data.hr03_number,
            user_id: res.data.created_by,
            // seq_in_meeting: 2,
            // hr_aml_meeting_id: 8,
            // created_at: "2024-08-13T16:18:33.000000Z",
            // updated_at: "2024-08-13T16:18:33.000000Z",
            bh_code: res.data.bh_code,
            // max_in_bh: 302,
          },
          gray_account: {
            id: res.data.hr03_id,
            hr_type: res.data.hr_type,
            card_id: res.data.card_id,
            ref_code: res.data.hr03_number,
            user_id: res.data.created_by,
            bh_code: res.data.bh_code,
          },

          create_form_type: res.data.opent_bank_account_type.create_form_type,
          req_type_request: res.data.opent_bank_account_type.request_type,
          hr03_id: res.data.hr03_id,
          type_account:
            res.data.opent_bank_account_type.type === 1 ? "black" : "dark",
          files: {},
          template_attachment: res.data.template_attachment,
          attachments: res.data.attachments,
          page: "add",
          isDuplicate: true,
          is_declare_note: res.data.declare_reason?.is_declare_note,
        },
      });
    } catch ({ response: errorRes }) {
      // return error msg if not found
      if (errorRes?.status > 404 && errorRes?.status < 500) {
        return toast.error(errorRes?.data.message);
      }

      return toast.error("เกิดข้อผิดพลาด");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal("closeModal")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      {request_account_HR03.includes("delete") && (
        <ConfirmDialog
          open={cfDel ? true : false}
          onClose={handleCfDelClose}
          cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
          onConfirm={handleCfDelOk}
          title="ยืนยันการลบข้อมูล"
        >
          ยืนยันการลบ(ID: {cfDel})
        </ConfirmDialog>
      )}
      <Grid item xs={12}>
        <Typography variant="h5">รายการคำขอเปิด HR03/ม้าเทา</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minHeight: "500px",
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={Hr03RequestAccountActiveSearchForm}
            onReset={() => {
              setFilter({});
              usersRefetch();
            }}
            usersData={usersData}
            setOpen={setOpen}
            filter={filter}
            total={usersData.total}
          />
          <DataGrid
            // density="compact"
            rowCount={usersData.total ?? 0}
            disableColumnMenu
            columns={columns}
            rows={_.get(usersData, "data", [])}
            loading={isLoading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page - 1}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(pageSize) => {
              console.log({ pageSize });
              setPageSize(pageSize);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              height: "100%",
              minHeight: "500px",
              width: "100%",
              mb: 2,
            }}
          />
        </Paper>
      </Grid>
      <Hr03RequestAccountActivePidSearch
        open={open}
        handleClose={handleClose}
      />
    </>
  );
}
