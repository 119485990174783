import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Field } from "react-final-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { isNumeric } from "../../helpers/stringFormater";

export default function FieldInput({
  name,
  inputType,
  label,
  required = false,
  readOnly = false,
  numericFormater,
  controlProps = {},
  inputProps = {},
  inputLabelProps = {},
  showHelperTextReadOnly = false,
  helperText,
  helperTextProps = {},
  disabled = false,
  onBlur,
  placeholder="",
}) {
  // State to handle password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Handle password visibility toggle
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle onBlur event
  const handleBlur = (input) => {
    onBlur && onBlur(input);
  };

  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl variant="outlined" {...controlProps}>
          <InputLabel
            htmlFor={name}
            error={(meta.error || meta.submitError) && meta.touched}
            required={required}
            {...inputLabelProps}
          >
            {label}
          </InputLabel>
          {readOnly ? (
            <>
              <OutlinedInput
                {...inputProps}
                error={(meta.error || meta.submitError) && meta.touched}
                type={inputType || "text"}
                id={name}
                label={`${label}${required ? "*" : ""}`}
                readOnly={readOnly}
                disabled={disabled}
                sx={{
                  "&.Mui-disabled": {
                    backgroundColor: "lightgrey", // สีพื้นหลังเมื่อ readOnly
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "lightgrey", // สีขอบเมื่อ readOnly
                    },
                  },
                  color: "grey.600", // สีข้อความเมื่อ readOnly
                }}
                autoComplete="off"
                value={
                  numericFormater && isNumeric(input.value)
                    ? numericFormater(input.value)
                    : input.value
                }
                placeholder={placeholder}
              />
              {helperText && showHelperTextReadOnly && (
                <FormHelperText
                  id={`${name}-helper`}
                  variant="outlined"
                  {...helperTextProps}
                >
                  {helperText}
                </FormHelperText>
              )}
            </>
          ) : (
            <>
              <OutlinedInput
                type={
                  inputType === "password"
                    ? showPassword
                      ? "text"
                      : "password"
                    : inputType || "text"
                }
                id={name}
                label={`${label}${required ? "*" : ""}`}
                endAdornment={
                  inputType === "password" && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          handleClickShowPassword();
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }
                error={(meta.error || meta.submitError) && meta.touched}
                {...inputProps}
                {...input}
                onChange={(e) =>
                  input.onChange(
                    inputType === "number"
                      ? +e?.target?.value
                      : e?.target?.value
                  )
                }
                onBlur={(e) => handleBlur(e?.target?.value)}
                placeholder={placeholder}
              />
              {(meta.error ||
                (!meta.modifiedSinceLastSubmit && meta.submitError)) &&
              meta.touched ? (
                <FormHelperText error id={`${name}-error`} variant="outlined">
                  {meta.error || meta.submitError}
                </FormHelperText>
              ) : (
                helperText && (
                  <FormHelperText
                    id={`${name}-helper`}
                    variant="outlined"
                    {...helperTextProps}
                  >
                    {helperText}
                  </FormHelperText>
                )
              )}
            </>
          )}
        </FormControl>
      )}
    </Field>
  );
}
