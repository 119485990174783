import { CheckCircle } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import FieldInput from "../form-fields/FieldInput";
import SubmitBtn from "../SubmitBtn";
import FieldSelect from "../form-fields/FieldSelect";
import FieldMultiFileDropZone from "../form-fields/FieldMultiFileDropZone";
import Joi from "joi";
import _ from "lodash";
import { FORM_ERROR } from "final-form";

const Hro3UploadFile = ({
  open,
  onClose,
  title,
  content,
  cfBtnProps = {},
  ccBtnProps = {},
  fieldProps = { name: "desc" },
  dialogProps = {},
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  loading,
  size = "xs",
  typeCurrent,
}) => {
  const { label: cancelLabel = "ยกเลิก", ...cancelBtnProps } = ccBtnProps;
  const { name: fieldName, ...inputProps } = fieldProps;
  const activeDialogProps = { fullWidth: true, maxWidth: size, ...dialogProps };

  const optionType = useMemo(() => {
    return [...typeCurrent]?.filter((item) => item.is_more)?.map((item) => ({
      value: item.id,
      label: item.title,
    }));
  }, [typeCurrent]);

  useEffect(() => {
    console.log("1.typeCurrent=>", typeCurrent);
  
  }, [typeCurrent])

  useEffect(() => {
    console.log("1.values=>", values);
  }, [values])
  
  

  const currentType = useMemo(() => {
    return typeCurrent.find((item) => item.id === values.template_id);
  }, [typeCurrent, values.template_id]);

 
  function getFileExtensions(mimeTypesString) {
    return mimeTypesString
      ?.split(",") // แยกด้วยจุลภาค
      ?.map((mimeType) => {
        // ลบช่องว่างและดึงส่วนสุดท้ายของ MIME type
        const type = mimeType.trim().split("/").pop();
        return type === "jpeg" ? "jpg" : type; // แปลง jpeg เป็น jpg
      })
      ?.join(", "); // รวมผลลัพธ์เป็นสตริงคั่นด้วยจุลภาค
  }

  return (
    <Dialog
      open={!!open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...activeDialogProps}
    >
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {typeof content === "string" || content instanceof String ? (
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          ) : (
            content
          )}

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <FieldSelect
                name="template_id"
                label="ประเภทไฟล์อัพโหลด"
                required
                controlProp={{ fullWidth: true }}
                options={optionType}
              />
            </Grid>
            <Grid item xs={12}>
              <FieldInput
                name="file_name"
                label="ชื่อไฟล์อัพโหลด"
                required
                controlProps={{ fullWidth: true }}
                inputProps={{
                  placeholder: "กรุณาระบุชื่อไฟล์อัพโหลด",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                อัพโหลดไฟล์ <span style={{ color: "red" }}>*</span>
                {/* {currentType?.is_required && (
                  <span style={{ color: "red" }}>*</span>
                )} */}
              </Typography>

              <FieldMultiFileDropZone
                name="file"
                accept={currentType?.file_type}
                type="file"
                required
                isMultiple={false}
                maxFiles={1}
                disabled={values.template_id === ""}
                placeholder={
                  <>
                   ลากและวางไฟล์บางไฟล์ที่นี่หรือคลิกเพื่อเลือกไฟล์{" "}
                    <br />
                    (*.{getFileExtensions(currentType?.file_type)}) only (
                    {currentType?.max_size_file} MB)
                  </>
                }
              />
              {/* {currentType?.discription && (
                <Typography
                  variant="p"
                  sx={{ fontSize: 14 }}
                  color="error.main"
                >
                  {currentType.discription}
                </Typography>
              )} */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: 2,
              mx: 2,
              my: 2,
            }}
          >
            <SubmitBtn
              variant="contained"
              color="primary"
              submitting={submitting}
              pristine={pristine}
              disabled={pristine || submitting || loading}
              startIcon={<CheckCircle />}
            >
              ยืนยันการอัพโหลดไฟล์
            </SubmitBtn>
            <Button
              type="button"
              {...cancelBtnProps}
              variant="outlined"
              color="primary"
              onClick={onClose}
              disabled={loading}
            >
              {cancelLabel}
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

Hro3UploadFile.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cfBtnProps: PropTypes.object,
  ccBtnProps: PropTypes.object,
  fieldProps: PropTypes.object,
  dialogProps: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  error: PropTypes.string,
  submitError: PropTypes.string,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  initialValues: PropTypes.object,
  submitFailed: PropTypes.bool,
  form: PropTypes.string,
  values: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  size: PropTypes.string,
  typeCurrent: PropTypes.array.isRequired,
};

export default React.memo(Hro3UploadFile);

export const validateRules = (values, typeCurrent) => {
  // คำนวณ currentType จาก values.template_id และ typeCurrent
  const currentType = typeCurrent.find(
    (item) => item.id === values.template_id
  );

  // ตรวจสอบว่า currentType มีค่าหรือไม่
  const isFileRequired = currentType && currentType.is_required === 1;

  return {
    template_id: Joi.alternatives()
      .try(Joi.number(), Joi.string(), Joi.array())
      .required()
      .messages({
        "any.required": "กรุณาระบุประเภทไฟล์อัพโหลด",
        "string.empty": "กรุณาระบุประเภทไฟล์อัพโหลด",
      }),
    file_name: Joi.string().required().messages({
      "any.required": "กรุณาระบุชื่อไฟล์อัพโหลด",
      "string.empty": "กรุณาระบุชื่อไฟล์อัพโหลด",
    }),
    file: isFileRequired
      ? Joi.array()
          .min(1) // ต้องมีไฟล์อย่างน้อย 1 ไฟล์
          .required()
          .messages({
            "array.base": "ไฟล์ต้องเป็นอาร์เรย์",
            "array.min": "ต้องอัพโหลดไฟล์อย่างน้อย 1 ไฟล์",
            "any.required": "กรุณาอัพโหลดไฟล์",
          })
      : Joi.array().messages({
          "array.base": "ไฟล์ต้องเป็นอาร์เรย์",
        }),
  };
};
const schema = (values, typeCurrent) =>
  Joi.object(validateRules(values, typeCurrent));

export const validate = (values, props) => {
  const { typeCurrent } = props; // รับ typeCurrent จาก props
  const errors = {};

  // ตรวจสอบว่า values.file เป็นอาร์เรย์หรือไม่
  if (!Array.isArray(values.file)) {
    values.file = []; // กำหนดให้เป็นอาร์เรย์ว่างหากไม่ใช่อาร์เรย์
  }

  const vResult = schema(values, typeCurrent).validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  return errors;
};
