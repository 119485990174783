import {
  Alert,
  Button,
  Divider,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../../apis";
import { getChip } from "../../../data/requestStatus";

import {
  ForwardToInbox,
  HistoryEduSharp
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReqDownloadListBtn from "../../customs/ReqDownloadListBtn";
import ResponseList from "../../customs/ResponseList";
import RequestStepper from "../../templates/RequestStepper";
import RequestFormReadOnly, {
  mapToInit,
} from "../requests/request-forms/RequestFormReadOnly";
import TableHistoryLog from "./TableHistoryLogEmail";

const isStepFail = (step) => step === "reject";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function RequestView({ caseId, reqId, handleCancel, status }) {
  const navigate = useNavigate();
  const requests = useSelector(
    (state) => state.auth.user.permissions.requests ?? []
  );

  const requestsSent = useSelector(
    (state) => state.auth.user.permissions.request_sent ?? []
  );

  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);
  const [historyLog, setHistoryLog] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [historyModal, setHistoryModal] = useState(false);
  const handleOpenModal = (page) => setHistoryModal(page);

  // for fetch info
  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const handleConfirm = () => {
    setLoading(true);
    api
      .put(
        `/api/admin/check/cases/${caseId}/pol_requests/${reqId}/sent_to_bank`
      )
      .then(() => {
        handleCancel({ type: "success", message: "สำเร็จ" });
      })
      .catch(({ response }) => {
        handleCancel({
          type: "error",
          message: response?.error ?? "เกิดข้อผิดพลาด",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSentBankHandler = () => {
    api
      .get(
        `api/sent_bank?request_id=${reqId}&page_size=${pageSize}&page=${page}`
      )
      .then(({ data }) => {
        setHistoryLog(data.data);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      });
  };
  const handleNavigateToCase = (path) => {
    window.open(path, "_blank");
  };

  useEffect(() => {
    let isSubscribed = true;

    if (item) {
      return undefined;
    }

    api
      .get(`/api/cases/${caseId}/pol_requests/${reqId}`)
      .then(({ data }) => {
        isSubscribed && setItem(data);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        isSubscribed && setLoading(false);
      });

    // if (status === "sent_to_bank") {
    //   onSentBankHandler();
    // }
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId, reqId]);

  useEffect(() => {
    onSentBankHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  const modalStyle = {
    ...masterModalStyle,
    height: !loading && item ? "100%" : undefined,
    // width: item ? "80%" : undefined,
    width: !loading && item ? "70%" : undefined,
  };

  const handleOpenTableHistory = () => {
    handleOpenModal(
      <TableHistoryLog
        setHistoryModal={setHistoryModal}
        historyLog={historyLog}
      />
    );
  };
  return (
    <>
      <Box sx={modalStyle}>
        <Snackbar
          open={fetchInfo ? true : false}
          autoHideDuration={6000}
          onClose={handleToastClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleToastClose}
            severity={fetchInfo.type || "warning"}
            sx={{ width: "100%" }}
          >
            {fetchInfo.payload}
          </Alert>
        </Snackbar>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
              <Typography id="modal-modal-title" variant="h6" component="div">
                ส่งคำขอ (ID: {reqId})
              </Typography>
              {!loading && item?.status && getChip(item.status)}
            </Stack>
          </Grid>
          {requests.includes("download") && (
            <Grid item xs={12} md={6} textAlign="right">
              <ReqDownloadListBtn links={item?.links} />
            </Grid>
          )}
        </Grid>
        <Paper sx={{ p: 2, mb: 3 }}>
          {item?.status && (
            <RequestStepper status={item.status} note={item.status_note} />
          )}
          {item?.status_note && (
            <>
              <Divider
                style={{
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              />
              <Typography
                variant="subtitle1"
                color={isStepFail(item?.status) && "error"}
                paragraph
                sx={{ overflowWrap: "break-word" }}
              >
                หมายเหตุ: {item?.status_note}
              </Typography>
            </>
          )}
          {item?.response_list?.length > 0 && (
            <>
              <Divider sx={{ mb: 2 }}>ข้อมูลตอบกลับ</Divider>
              <ResponseList items={item.response_list} />
            </>
          )}
        </Paper>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mb: 3,
          }}
        >
          {loading || !item ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <Form
                // validate={validate}
                readOnly
                onSubmit={() => undefined}
                component={RequestFormReadOnly}
                initialValues={mapToInit(item)}
              />
              {/* {historyLog.length > 0 && (
              <>
                <Divider sx={{ mt: 2, mb: 2 }}>ประวัติการส่งอีเมล</Divider>
                <DataGrid
                  name="bank_accountsQ"
                  disableColumnMenu
                  columns={columns}
                  rows={historyLog}
                  // loading={loading}
                  rowsPerPageOptions={[5, 10, 20]}
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => setPage(newPage)}
                  // pagination
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  autoHeight
                  readOnly
                />
              </>
            )} */}
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={6}>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-start"
                  >
                    {item?.status === "sent_to_bank" &&
                    requestsSent.includes("mailling") ? (
                      <LoadingButton
                        type="button"
                        variant="contained"
                        startIcon={<ForwardToInbox />}
                        onClick={() => {
                          handleConfirm();
                        }}
                        loadingPosition="start"
                      >
                        ส่ง email
                      </LoadingButton>
                    ) : (
                      ""
                    )}

                    <Button
                      type="button"
                      color="secondary"
                      variant="outlined"
                      startIcon={<HistoryEduSharp />}
                      onClick={() => {
                        handleOpenTableHistory();
                      }}
                      sx={{ mr: 2 }}
                    >
                      ประวัติการส่งอีเมล
                    </Button>
                  </Stack>
                  <Stack
                    direction="row"
                    mt={2}
                    spacing={1}
                    justifyContent="flex-start"
                  >
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={() =>
                        handleNavigateToCase(`/cases/${caseId}/mgt`)
                      }
                      sx={{ mr: 2 }}
                    >
                      กลับไปจัดการ Case
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Button
                      type="button"
                      variant="outlined"
                      justifyContent="flex-end"
                      onClick={() => {
                        handleCancel();
                      }}
                    >
                      ปิด
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Box>

      <Modal
        open={historyModal ? true : false}
        onClose={() => {
          setHistoryModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{historyModal}</div>
      </Modal>
    </>
  );
}
