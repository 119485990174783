import { Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import fileDownload from "js-file-download";
import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import api from "../../../../apis";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../../form-fields/FieldDatePicker";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";
import { format } from "date-fns";
import FieldAsyncSelect from "../../../form-fields/FieldAsyncSelect";
import letterTypes from "../../../../data/selects/letterTypes";

const Joi = require("joi").extend(require("@joi/date"));

export default function DownloadTemplateLogSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  rows,
  filter,
}) {
  const event = [
    { value: "created", label: "Created" },
    { value: "updated", label: "Updated" },
    { value: "deleted", label: "Deleted" },
  ];
  const includeReqTypes = [2, 3, 9, 10];

  const filterdLetterTypes = useMemo(
    () => letterTypes.filter((lt) => includeReqTypes.includes(lt.value)),
    [includeReqTypes]
  );

  const qState = useSelector((state) => state.auth.user.permissions);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const handleDownload = (uri, setActionState) => {
    setLoadingPDF(true);
    setActionState(true);
    api
      .post(
        uri,
        {
          recived_at: values.recived_at ?? format(new Date(), "yyyy-MM-dd"),
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoadingPDF(false);
        setActionState(false);
      });
  };

  return (
    <form onSubmit={handleSubmit} initialValues={initialValues} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {qState?.log_download_template?.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldAsyncSelect
              name="bank_id"
              label="หน่วยงานข้อมูล"
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              basePath={`/api/banks`}
              itemFormat={(row) => {
                return {
                  value: row.id,
                  label: `${row.name} (${row.short_name})`,
                };
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldAutoComplete
              name="request_type"
              label="ประเภทคำขอ"
              options={filterdLetterTypes}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              //   inputProps={{ sx: { fullWidth: true } }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldInput
              name="created_by"
              label="สร้างโดย"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldDatePicker
              name="start_date"
              label="จากวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              value={new Date()}
              loading={loadingPDF}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              value={new Date()}
              loading={loadingPDF}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} mb={2}>
        {qState?.log_download_template?.includes("search") && (
          <Grid item md={12} lg={4}>
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          </Grid>
        )}

        {/* <Grid
          item
          md={12}
          lg={qState?.transaction_log?.includes("search") ? 8 : 12}
        >
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <LoadingButton
              type="button"
              variant="outlined"
              startIcon={<Download />}
              loadingPosition="start"
              // loading={pdfDownloading}
             
              onClick={() => handleDownload("/api/", setPdfDownloading)}
              disabled={!values.recived_at} 
              
            >
             
              ดาวน์โหลด
            </LoadingButton>
          </Stack>
        </Grid> */}
      </Grid>
    </form>
  );
}
// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }

//   const now = new Date();
//   if (!errors.start_date && values.start_date) {
//     const startDate = parseISO(values.start_date);
//     if (isAfter(startDate, now)) {
//       errors.start_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (!errors.end_date && values.end_date) {
//     const endDate = parseISO(values.end_date);
//     if (isAfter(endDate, now)) {
//       errors.end_date = "มากกว่าเวลาปัจจุบัน";
//     }
//   }

//   if (
//     !errors.start_date &&
//     !errors.end_date &&
//     values.start_date &&
//     values.end_date &&
//     isAfter(parseISO(values.start_date), parseISO(values.end_date))
//   ) {
//     errors.start_date = "ไม่ถูกต้อง";
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = {
//   type_id: Joi.number().min(1).max(9).allow(null),
//   bank_id: Joi.number().allow(null),
//   start_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   end_date: Joi.date()
//     .min(defaultJoiDates.minDate)
//     .max(defaultJoiDates.forceUTC(startOfToday()))
//     .allow(null),
//   status: Joi.string().allow(null),
//   search: Joi.string().max(50).allow(null),
// };

// const schema = Joi.object(validateRules).error(validationMsg());
