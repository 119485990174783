import {
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import validationMsg from "../../../../../helpers/validationMsg";
import DialogDescForm from "../../../../dialogs/DescFormDialog";
import PredefineRejectDesc from "../component/PredefineRejectDesc";
import api from "../../../../../apis";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ModalReject = ({
  open,
  setOpen,
  id,
  status,
  statusReject,
  setReload,
  handleCloseModal,
  loading,
  setLoading,
}) => {
  const handleCfRejectClose = () => setOpen(false);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [titleError, setTitleError] = useState("พบปัญหากรุณาลองใหม่อีกครั้ง");

  const handleReject = (formValues) => {
    setLoading(true);
    if (status === "rtp_approve") {
      if (statusReject) {
        if (statusReject === "aml_reject") {
          return api
            .post(`/api/openbankaccount/process/${id}/reject`, {
              remark: formValues.status_note,
              status: "aml_reject",
            })
            .then(() => {
              handleCfRejectClose();
              toast.success("ปฏิเสธเรียบร้อย");
              handleCfRejectClose();
              setReload && setReload(Math.random());
              handleCloseModal();
            })
            .catch((e) => {
              console.log(e);
              setIsError(true);
              handleCfRejectClose();
              setTitleError("พบปัญหาในการปฏิเสธ กรุณาลองใหม่อีกครั้ง");
            })
            .finally(() => setLoading(false));
        }

        if (statusReject === "aml_reject_account") {
          return api
            .post(`/api/openbankaccount/process/${id}/reject`, {
              remark: formValues.status_note,
              status: "aml_reject_account",
            })
            .then(() => {
              handleCfRejectClose();
              toast.success("ปฏิเสธเรียบร้อย");
              handleCfRejectClose();
              setReload && setReload(Math.random());
              handleCloseModal();
            })
            .catch((e) => {
              console.log(e);
              setIsError(true);
              handleCfRejectClose();
              setTitleError("พบปัญหาในการปฏิเสธ กรุณาลองใหม่อีกครั้ง");
            })
            .finally(() => setLoading(false));
        }
      } else {
        return api
          .post(`/api/openbankaccount/${id}/approve`, {
            remark: formValues.status_note,
            status: "aml_reject_end",
          })
          .then(() => {
            handleCfRejectClose();
            toast.success("ปฏิเสธเรียบร้อย");
            navigate("/hr03/verify-account-active");
            handleCfRejectClose();
            setReload && setReload(Math.random());
            handleCloseModal();
          })
          .catch((e) => {
            console.log(e);
            setIsError(true);
            handleCfRejectClose();
            setTitleError("พบปัญหาในการปฏิเสธ กรุณาลองใหม่อีกครั้ง");
          })
          .finally(() => setLoading(false));
      }
    } else {
      return (
        api
          // .put(`/api/hr_reports/${itemId}/${getType}_reject`, formValues)
          .post(`/api/openbankaccount/process/${id}/reject`, {
            remark: formValues.status_note,
          })
          .then(() => {
            handleCfRejectClose();
            toast.success("ปฏิเสธเรียบร้อย");
            navigate("/hr03/verify-account-active");
            setReload && setReload(Math.random());
            handleCloseModal();
          })
          .catch((e) => {
            console.log(e);
            setIsError(true);
            handleCfRejectClose();
            setTitleError("พบปัญหาในการปฏิเสธ กรุณาลองใหม่อีกครั้ง");
          })
          .finally(() => setLoading(false))
      );
    }
  };

  return (
    <>
      <Dialog
        open={isError}
        onClose={() => setIsError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {titleError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsError(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <Form
        initialValues={{
          status_note: "",
        }}
        validate={validate}
        onSubmit={handleReject}
        component={DialogDescForm}
        open={open}
        onClose={handleCfRejectClose}
        cfBtnProps={{ label: "ยืนยัน", color: "error" }}
        title={
          ["aml_reject", "aml_reject_end", "aml_reject_account"].includes(
            statusReject
          ) || status === "rtp_approve"
            ? "ยืนยันการไม่ผ่าน"
            : "ยืนยันการปฏิเสธ"
        }
        content={
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">
              โปรดอธิบายเหตุผลการ
              {["aml_reject", "aml_reject_account"].includes(statusReject) ||
              status === "rtp_approve"
                ? "ไม่ผ่าน"
                : "ปฏิเสธ"}
              เพื่อส่งกลับให้ พงส. เจ้าของข้อมูลแก้ไข
            </Typography>
            <PredefineRejectDesc />
          </Stack>
        }
        size="sm"
        fieldProps={{
          name: "status_note",
          label: "เหตุผล",
          inputProps: {
            multiline: true,
            maxRows: 10,
            minRows: 10,
            autoFocus: true,
          },
        }}
        loading={loading}
      />
    </>
  );
};

export default ModalReject;

export const validateRules = (values) => ({
  status_note: Joi.string().invalid(null).required().messages({
    "string.empty": "กรุณาระบุเหตุผล",
    "any.required": "กรุณาระบุเหตุผล",
  }),
});

const schema = (values) => Joi.object(validateRules(values));

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema(values).validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });
  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

// const validateReject = (values, props) => {
//   const schema = Joi.object({
//     status_note: Joi.string().invalid(null).required(),
//   }).error(validationMsg());

//   const errors = {};
//   const vResult = schema.validate(values, {
//     abortEarly: false,
//     allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
//     let details = vResult.error.details;
//     details.forEach(({ context, message }) => {
//       _.set(errors, context.label, message);
//       // return (errors[context.label] = message);
//       errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
//     });
//   }
//   return errors;
// };
