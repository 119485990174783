const universalIdValidator = (value, helpers) => {
  // ตรวจสอบบัตรประชาชนไทย (13 หลัก)
  if (/^\d{13}$/.test(value)) {
    let sum = 0;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(value[i]) * (13 - i);
    }

    const checkDigit = (11 - (sum % 11)) % 10;
    if (checkDigit !== parseInt(value[12])) {
      return helpers.message("หมายเลขบัตรประชาชนไม่ถูกต้อง");
    }
    return value;
  }

  // ตรวจสอบเลขพาสปอร์ตมาตรฐาน ICAO
  // 1️⃣ (ตัวอักษร 1-3 ตัว + ตัวเลข 6-9 หลัก) เช่น A12345678, AB1234567, C01X00T47
  // 2️⃣ (ตัวเลขล้วน 9 หลัก) เช่น 123456789 (US, UK)
  if (/^(?:[A-Z]{1,3}\d{6,9}|\d{9}|[A-Z0-9]{9})$/.test(value)) {
    return value;
  }

  return helpers.message("หมายเลขบัตรประชาชนหรือเลขพาสปอร์ตไม่ถูกต้อง");
};

export default universalIdValidator;
