import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

export default function ConfirmDialog({
  open = false,
  onClose,
  cfBtnProps = {},
  ccBtnProps = {},
  onConfirm,
  title,
  children,
  maxWidth,
  width, 
  height
}) {
  const [cfLoading, SetCfLoading] = useState(false);
  const handleCf = () => {
    SetCfLoading(true);

    const cfResult = onConfirm();

    if (cfResult instanceof Promise) {
      cfResult.finally(() => {
        onClose();
        SetCfLoading(false);
      });
    } else {
      onClose();
      SetCfLoading(false);
    }
  };

  const { label: confirmLabel, ...confirmBtnProps } = cfBtnProps;
  const { label: cancelLabel = "ยกเลิก", ...cancelBtnProps } = ccBtnProps;
  return (
    <Dialog
      open={open ? true : false}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      PaperProps={{
        style: { 
          width: width || "auto",
          height:height || "auto"
         }

      }}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button {...cancelBtnProps} onClick={onClose} disabled={cfLoading}>
          {cancelLabel}
        </Button>
        <LoadingButton
          onClick={handleCf}
          loading={cfLoading}
          loadingPosition="start"
          variant="contained"
          autoFocus
          {...confirmBtnProps}
        >
          {confirmLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
