import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import fileDownload from "js-file-download";
import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import UploadForm, { validate } from "./UploadForm";
// import jschardet from "jschardet";

export default function UploadPage({ isExternal = false }) {
  const bankID = useSelector((state) => state.auth.user?.bank?.id ?? 0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [barColor, setBarColor] = useState("primary");

  //   const handleClose = () => {
  //     setLoading(false);
  //   };

  const onSubmit = async (formData, form, callback) => {
    setProgress(0);
    setLoading(true);
    const fileTotal = formData.template_files.length;
    const bank_id = formData.bank_id;

    let fileCount = 0;
    const outputFile = { name: null, data: [] };
    try {
      for (const tf of formData.template_files) {
        await readAndMergeFiles(tf, outputFile, bank_id);
        setProgress(Math.floor((++fileCount * 100) / fileTotal));
        //       console.log(tf.name);
      }
    } catch (e) {
      console.log(e);
      setBarColor("error");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
      setBarColor("primary");
      return {
        [FORM_ERROR]: "เกิดข้อผิดพลาด",
      };
    }
    await new Promise((resolve) => setTimeout(resolve, 600));
    const blob = new Blob([_.uniq(outputFile.data).join("\n")], {
      type: "text/csv;charset=utf-8;",
    });

    // download file
    let downloadFilename = "download.csv";
    let extractMatch = null;
    if (outputFile.name) {
      downloadFilename = outputFile.name;
    } else if (
      (extractMatch = /^(.+)\./.exec(formData.template_files[0].name))
    ) {
      downloadFilename = extractMatch[1] + ".csv";
    }

    fileDownload(blob, downloadFilename);
    // reader.readAsText(file, "windows-874");
    // reader.readAsText(file, "windows-874");
    form.restart();
    if (bankID) {
      form.change("bank_id", bankID);
    }
    setLoading(false);
    //     callback();
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={loading}
        // onClose={handleClose}
      >
        <DialogTitle>Loading....</DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", p: 2 }}>
            <LinearProgress
              variant="determinate"
              color={barColor}
              value={progress}
            />
          </Box>
        </DialogContent>
      </Dialog>
      <Grid item xs={12}>
        <Typography variant="h5"> File Normalization</Typography>
        <Typography variant="subtitle1">
          แปลงไฟล์ จากแต่ละ email ให้สามารถใช้งานได้
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={UploadForm}
            isExternal={isExternal}
          />
        </Paper>
      </Grid>
    </>
  );
}

const readAndMergeFiles = async (file, outputFile, bank_id) => {
  if (file.name.endsWith(".csv")) {
    await readCSVFile(file, outputFile);
  } else if (
    file.name.endsWith(".xlsx") ||
    file.name.endsWith(".xlsb") ||
    file.name.endsWith(".xls")
  ) {
    // Process Excel file
    await readExcelFile(file, outputFile, bank_id);
  }
};

const readCSVFile = (file, outputFile) => {
  return new Promise((resolve, reject) => {
    let retryRead = false;
    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target.result;
      if (!retryRead && csvData.match(/[^\u0E00-\u0E7F\u0020-\u007E\n\r]/)) {
        // not match unicode , thai charactor and newline
        retryRead = true;
        console.log("try read as winsdows-874");
        reader.readAsText(file, "windows-874");
        return;
      }
      //       const encoding = detectEncoding(e.target.result);
      //       const utf8Text = convertToUTF8(e.target.result, encoding);
      const lines = csvData.split("\n");

      // determine which template and how to slice
      let slice = [null];
      if (lines.length) {
        const headers = lines[0].split("|");
        // find first start col
        const startIndex = headers.findIndex(
          (h) => h.toUpperCase() === "AUTHORITYID"
        );
        if (startIndex !== -1) {
          slice = [startIndex, startIndex + headers.length];
          // if (headers.some((h) => h.toUpperCase() === "SEARCHKEY")) {
          //   // tp02
          //   slice = [startIndex, startIndex + 40];
          // } else {
          //   // tp04
          //   slice = [startIndex, startIndex + 33];
          // }
        }
      }

      lines.forEach((l) => {
        const line = l.trim();
        if (line === "") return;
        outputFile.data.push(
          line
            .split("|")
            .slice(...slice)
            .join("|")
        );
      });
      resolve(outputFile);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(file);
    //     reader.readAsArrayBuffer(file);
  });
};

const readExcelFile = (file, outputFile, bank_id) => {
  const sheetToCSV = (sheet) => {
    const csv = [];
    let range = XLSX.utils.decode_range(sheet["!ref"]);
    if (range.e.r === 0) {
      range = { s: { r: Infinity, c: Infinity }, e: { r: 0, c: 0 } };
      Object.keys(sheet)
        .filter(function (x) {
          return x.charAt(0) !== "!";
        })
        .map(XLSX.utils.decode_cell)
        .forEach(function (x) {
          range.s.c = Math.min(range.s.c, x.c);
          range.s.r = Math.min(range.s.r, x.r);
          range.e.c = Math.max(range.e.c, x.c);
          range.e.r = Math.max(range.e.r, x.r);
        });
    }
    let dateColumn = [];
    let timeColumn = [];
    let dateTimeColumn = [];
    let stringColumn = [];

    const columnCount = range.e.c;
    const rowCount =
      "A2" in sheet && range.e.r === 0 ? range.e.r + 1 : range.e.r;
    const currentDate = new Date();
    const formattedCurrentDate = currentDate
      .toISOString()
      .replace(/[:.]/g, "-");
    if (columnCount < 39) {
      //TP04
      outputFile.name = "TP04_" + formattedCurrentDate + ".csv";
      dateColumn = [22, 25];
      if ([2, 10].includes(bank_id)) {
        dateColumn = dateColumn.filter((el) => el !== 22);
      }
      if ([12].includes(bank_id)) {
        dateTimeColumn.push(...[1]);
        timeColumn.push(...[25]);
        dateColumn = dateColumn.filter((el) => el !== 25);
      }
      stringColumn = [5, 7, 12, 14, 16, 17, 19];
      // if(bank_id === 1){
      //   stringColumn.unshift(1)
      // }
    } else {
      //TP02
      outputFile.name = "TP02_" + formattedCurrentDate + ".csv";
      dateColumn = [3, 8, 13, 34, 36];
      stringColumn = [6, 14, 17];
    }

    const colExclude = [];
    for (let rowNum = range.s.r; rowNum <= rowCount; rowNum++) {
      const row = [];
      for (let colNum = range.s.c; colNum <= columnCount; colNum++) {
        const cellAddress = { c: colNum, r: rowNum };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        const cell = sheet[cellRef];

        // const dateValue = new Date(Math.round((cell.v - 25569) * 86400 * 1000));
        // const formattedDate = `${dateValue.getUTCDate().toString().padStart(2, '0')}/${(dateValue.getUTCMonth() + 1).toString().padStart(2, '0')}/${dateValue.getUTCFullYear()}`;
        if (
          cell &&
          typeof cell.v === "string" &&
          cell.v.toLowerCase().includes("seqno")
        ) {
          colExclude.push(colNum);
        }

        if (
          !colExclude.includes(colNum) &&
          cell &&
          cell.t === "n" &&
          dateTimeColumn.includes(colNum)
        ) {
          const dateValue = new Date(
            Math.round((cell.v - 25569) * 86400 * 1000)
          ).toLocaleString("en-GB", { timeZone: "UTC" });
          row.push(dateValue.replaceAll(",", ""));
        } else if (
          !colExclude.includes(colNum) &&
          cell &&
          cell.t === "n" &&
          dateColumn.includes(colNum)
        ) {
          const dateValue = new Date(
            Math.round((cell.v - 25569) * 86400 * 1000)
          );
          // Format as dd/mm/yyyy
          const formattedDate = `${dateValue
            .getUTCDate()
            .toString()
            .padStart(2, "0")}/${(dateValue.getUTCMonth() + 1)
            .toString()
            .padStart(2, "0")}/${dateValue.getUTCFullYear()}`;
          //console.log(colNum,cell.t,cell.v,cell.w,formattedDate);
          row.push(formattedDate);
        } else if (
          !colExclude.includes(colNum) &&
          cell &&
          cell.t === "n" &&
          timeColumn.includes(colNum)
        ) {
          row.push(cell.w);
        } else if (
          !colExclude.includes(colNum) &&
          cell &&
          cell.t === "n" &&
          stringColumn.includes(colNum)
        ) {
          //console.log(colNum,cell.t,cell.v,cell.w);
          row.push(cell ? cell.w : "");
        } else if (!colExclude.includes(colNum)) {
          //   console.log(colNum, cell.t, cell.v, cell.w);
          row.push(cell ? cell.v : "");
        }

        //row.push(cell ? cell.w : '');
      }
      // Only add rows that contain data
      if (row.some((cell) => cell !== "")) {
        csv.push(row.join("|"));
      }
    }

    return csv.join("\n");
  };
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      var wb = XLSX.read(data, { type: "binary" });
      const worksheet = wb.Sheets[wb.SheetNames[0]];
      const sheetData = sheetToCSV(worksheet);
      outputFile.data.push(
        ...sheetData.split("\n").filter((line) => line.trim() !== "")
      );
      resolve(outputFile);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsBinaryString(file);
  });
};

// Helper function to detect encoding
// const detectEncoding = (text) => {
//   const result = jschardet.detect(text);
//   return result.encoding;
// };

// Helper function to convert to UTF-8
// const convertToUTF8 = (text, fromEncoding) => {
//   console.log(text);
//   if (fromEncoding.toUpperCase() === "UTF-8") {
//     return text; // Already in UTF-8
//   }

//   const encoder = new TextEncoder(fromEncoding);
//   const utf8Bytes = encoder.encode(text);
//   const utf8Text = new TextDecoder("utf-8").decode(utf8Bytes);

//   return utf8Text;
// };
