import {
  Add,
  CheckCircle,
  Delete,
  Edit,
  PublishedWithChangesOutlined,
  Visibility,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiLink from "@mui/material/Link";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO, subMonths } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../apis";
import { getChip } from "../../../data/hr03Status";
import {
  changePage,
  changePageSize,
  clearResult,
  fetch,
  forceReload as forceReloadGrid,
  setFilter,
} from "../../../ducks/hr03List";

import ConfirmDialog from "../../dialogs/ConfirmDialog";
import Hr03AccountSearchForm from "./Hr03AccountSearchForm";
import Hr03AccountView from "./Hr03AccountView";
import Hr03VerifyAccountView from "./Hr03VerifyAccountView";

import { Download } from "@mui/icons-material";
import ReportCSV from "./exportCSV";
import ReportExcel from "./exportExcel";
import fileDownload from "js-file-download";
import { options } from "./Hr03AccountForm";
import { fil } from "date-fns/locale";

export default function Hr03ListPage() {
  const saving_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.saving_account_HR03 ?? []
  );
  const export_condition = useSelector(
    (state) => state.auth.user.export_condi ?? []
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // add account modal state
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = (payload) => {
    if (payload?.type === "error") {
      SetToastInfo(payload);
    } else {
      dispatch(forceReloadGrid());
    }
    setOpenModal(false);
  };

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      dispatch(setFilter({}));
    };
  }, [dispatch]);

  const fetchIdRef = React.useRef(0);
  const rows = useSelector((state) => state.hr03.data);
  const total = useSelector((state) => state.hr03.meta.total);
  const loading = useSelector((state) => state.hr03.meta.loading);
  const forceReload = useSelector((state) => state.hr03.meta.forceReload);
  const page = useSelector((state) => state.hr03.meta.page);
  const pageSize = useSelector((state) => state.hr03.meta.pageSize);
  const filter = useSelector((state) => state.hr03.meta.filter);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  const [anchorElDowload, setAnchorElDowload] = React.useState(null);
  const openDowload = Boolean(anchorElDowload);

  const handleClickDowload = (event) => {
    setAnchorElDowload(event.currentTarget);
  };
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
  };

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    // Only update the data if this is the latest fetch
    dispatch(fetch(pageSize, page, filter, fetchId, fetchIdRef)).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  const renderMainAction = (params) => {
    if (
      params.row.status === "new" &&
      saving_account_HR03.includes("confirm")
    ) {
      return (
        <Tooltip title="ยืนยันข้อมูล">
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <Hr03VerifyAccountView
                  handleCancel={handleCloseModal}
                  itemId={params.id}
                />
              );
            }}
          >
            <CheckCircle color="primary" />
          </IconButton>
        </Tooltip>
      );
    }

    if (
      ["bh_reject", "rtp_reject"].includes(params.row.status) &&
      saving_account_HR03.includes("confirm")
    ) {
      return (
        <Tooltip title="ยืนยันข้อมูล">
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <Hr03VerifyAccountView
                  handleCancel={handleCloseModal}
                  itemId={params.id}
                />
              );
            }}
          >
            <PublishedWithChangesOutlined color="primary" />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      saving_account_HR03.includes("view") && (
        <Tooltip title="ดูข้อมูล">
          <IconButton
            type="button"
            variant="contained"
            size="small"
            onClick={() => {
              handleOpenModal(
                <Hr03AccountView
                  handleCancel={handleCloseModal}
                  itemId={params.id}
                />
              );
            }}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
      )
    );
  };

  const columns = [
    ...(saving_account_HR03.includes("edit") ||
    saving_account_HR03.includes("delete") ||
    saving_account_HR03.includes("confirm") ||
    saving_account_HR03.includes("view")
      ? [
          {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 120,
            renderCell: (params) => {
              return (
                <Stack
                  spacing={0.5}
                  direction="row"
                  justifyContent="left"
                  sx={{ width: "100%" }}
                >
                  {renderMainAction(params)}
                  {["new", "bh_reject", "rtp_reject"].includes(
                    params.row.status
                  ) && (
                    <>
                      {saving_account_HR03.includes("edit") && (
                        <Tooltip title="แก้ไข">
                          <IconButton
                            type="button"
                            variant="contained"
                            size="small"
                            onClick={() => {
                              navigate(`/hr03/edit/${params.id}`);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      )}
                      {saving_account_HR03.includes("delete") && (
                        <IconButton
                          type="button"
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={() => {
                            setCfDel(params.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </>
                  )}
                </Stack>
              );
            },
          },
        ]
      : []),
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {getChip(params.row.status, { size: "small" })}{" "}
            {params.row.status === "new" && (
              <Chip
                label="กรุณายืนยัน"
                color="warning"
                size="small"
                กรุณายืนยัน
                sx={{
                  height: "auto",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    fontFamily: "Kanit",
                  },
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "hr_type",
      headerName: "ประเภท",
      sortable: true,
      width: 80,
      renderCell: (params) =>
        options.filter((el) => el.value === params.row.hr_type)[0].label,
    },
    {
      field: "tpo_case_id",
      headerName: "caseId",
      sortable: true,
      width: 100,
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: true,
      minWidth: 180,
      maxWidth: 200,
    },
    {
      field: "fullname",
      headerName: "ชื่อ-สกุล",
      sortable: true,
      minWidth: 120,
      maxWidth: 250,
      valueGetter: ({ row }) => {
        return `${row.name} ${row.surname}`;
        // return params.row.bank?.short_name;
      },
    },
    {
      field: "bankname",
      headerName: "ธนาคาร",
      sortable: true,
      width: 80,
    },
    {
      field: "accountno",
      headerName: "เลขบัญชี",
      sortable: true,
      minWidth: 150,
      maxWidth: 180,
    },
    {
      field: "pol_name",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 150,
    },
    {
      field: "pol_policestation",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        return value.ORG_ABBR;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm:ss") : undefined;
      },
    },
  ];

  const onSearchFormSubmit = (data) => {
    dispatch(setFilter(data));
  };

  // delete record confirmation
  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);
  const handleCfDelOk = () => {
    return api
      .delete(`/api/hr_reports/${cfDel}`)
      .then(() => {
        dispatch(forceReloadGrid());
      })
      .catch(() => {
        SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
      });
  };

  const DownloadBtn = (
    <LoadingButton
      id="export-button-excel"
      type="button"
      variant="outlined"
      startIcon={<Download />}
      loadingPosition="start"
      // onClick={() => handleDownload("/api/", setPdfDownloading)}
      aria-controls={openDowload ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={openDowload ? "true" : undefined}
      onClick={handleClickDowload}
      loading={isLoadingExcel}
      disabled={total === 0 || total < export_condition.min_download || total > export_condition.max_download}

    >
      ดาวน์โหลด
    </LoadingButton>
  );
  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <ConfirmDialog
        open={cfDel ? true : false}
        onClose={handleCfDelClose}
        cfBtnProps={{ label: "ลบ", color: "error", startIcon: <Delete /> }}
        onConfirm={handleCfDelOk}
        title="ยืนยันการลบข้อมูล"
      >
        ยืนยันการลบ(ID: {cfDel})
      </ConfirmDialog>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Typography variant="h5">บัญชี HR03</Typography>
        <Typography variant="subtitle1">
          การแจ้งรายชื่อบุคคลผู้ที่ความเสี่ยงสูง (HR03)
          เพื่อระงับการทำธุรกรรมทางอิเล็กทรอนิกส์ ทุกบัญชีของบุคคลนั้น (
          <MuiLink
            rel="noopener"
            target="_blank"
            href="https://sites.google.com/view/pctpolice/home/%E0%B8%84%E0%B8%A1%E0%B8%AD%E0%B9%81%E0%B8%88%E0%B8%87%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%8A%E0%B8%AD%E0%B8%9A%E0%B8%84%E0%B8%84%E0%B8%A5%E0%B8%9C%E0%B8%A1%E0%B8%84%E0%B8%A7%E0%B8%B2%E0%B8%A1%E0%B9%80%E0%B8%AA%E0%B8%A2%E0%B8%87%E0%B8%AA%E0%B8%87-hr03?authuser=0"
          >
            อ่านเพิ่มเติม
          </MuiLink>
          )
        </Typography>
      </Grid>
      {saving_account_HR03.includes("search") && (
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Hr03AccountSearchForm
              onSubmit={onSearchFormSubmit}
              // loading={loading}
              onReset={() => dispatch(setFilter({}))}
              initialValues={{}}
            />
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mb: 2 }}
          >
            {saving_account_HR03.includes("download") && (
              <>
                {!filter.start_date || !filter.end_date ? (
                  <Tooltip title="กรุณาเลือกวันที่ ที่ค้นหาก่อนใช้งานปุ่มดาวน์โหลด">
                    <span>{DownloadBtn}</span>
                  </Tooltip>
                ) : (
                  DownloadBtn
                )}
              </>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorElDowload}
              open={openDowload}
              onClose={handleCloseDownload}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={async () => {
                  // ReportExcel(rows);
                  setIsLoadingExcel(true);
                  handleCloseDownload();
                  try {
                    const res = await api.get("/api/hr_reports", {
                      responseType: "blob",
                      params: {
                        page: page,
                        page_size: pageSize,
                        export: "excel",
                        ...(filter.start_date && {
                          start_date: filter.start_date,
                        }),
                        ...(filter.end_date && { end_date: filter.end_date }),
                        ...filter,

                      },
                    });

                    const contentDisposition =
                      res.headers["content-disposition"];
                    const filename = /filename=.+/.exec(contentDisposition)
                      ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                      : "download.xlsx";

                    fileDownload(res.data, filename);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsLoadingExcel(false);
                  }
                }}
              >
                Excel
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  // ReportExcel(rows);
                  setIsLoadingExcel(true);
                  handleCloseDownload();
                  try {
                    const res = await api.get("/api/hr_reports", {
                      responseType: "blob",
                      params: {
                        page: page,
                        page_size: pageSize,
                        export: "csv",
                        ...(filter.start_date && {
                          start_date: filter.start_date,
                        }),
                        ...(filter.end_date && { end_date: filter.end_date }),
                        ...filter,
                      },
                    });

                    const contentDisposition =
                      res.headers["content-disposition"];
                    const filename = /filename=.+/.exec(contentDisposition)
                      ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
                      : "download.csv";

                    fileDownload(res.data, filename);
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setIsLoadingExcel(false);
                  }
                }}
              >
                CSV
              </MenuItem>
            </Menu>
            {saving_account_HR03.includes("create") && (
              <Button
                component={Link}
                to="/hr03/add"
                variant="contained"
                color="success"
                startIcon={<Add />}
              >
                เพิ่มบัญชี
              </Button>
            )}
          </Stack>
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows ?? []}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page) => dispatch(changePage(page))}
            onPageSizeChange={(pageSize) => dispatch(changePageSize(pageSize))}
          />
        </Paper>
      </Grid>
    </>
  );
}
