import {
  Box,
  Button,
  Chip,
  createTheme,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import { Form } from "react-final-form";
import { useQuery } from "react-query";
import api from "../../../../apis";
import Hr03RequestAccountActiveFormDetail from "./Form/Hr03RequestAccountActiveFormDetail";
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import { InsertChart } from "@mui/icons-material";
import TableHistory from "../Hr03VerifyAccountActive/component/TableHistory";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  height: "100%",
};

export default function Hr03RequestAccountActiveDetailPage({
  itemId,
  handleCancel,
  isViewOnly,
}) {
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );
  const [historyModal, setHistoryModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = (page) => setHistoryModal(page);
  const handleCloseModal = () => setHistoryModal(false);
  const {
    isLoading,
    isError,
    data: item,
    refetch,
  } = useQuery(
    ["requests_open_bank_account", itemId],
    () =>
      api.get(`/api/openbankaccount/requests/${itemId}`).then((res) => {
        let data = res.data;

        return {
          ...data,
          is_declare_note: data?.declare_reason?.is_declare_note,
          fullName: `${data?.hr03?.title} ${data?.hr03?.name} ${data?.hr03?.surname}`,
        };
      }),
    { staleTime: 60 * 1000 }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    console.log("isViewOnly=>", isViewOnly);
  }, [isViewOnly]);

  const previewFiles = useCallback(async (fileUrl) => {
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });

      // ดึง content-type จาก header
      const contentType = res.headers["content-type"];
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: contentType })
      );

      // ตรวจสอบประเภทไฟล์จาก content-type
      if (contentType === "application/pdf") {
        // กรณีเป็น PDF
        const pdfWindow = window.open(url);
        pdfWindow.onload = () => {
          pdfWindow.print();
        };
      } else {
        // กรณีเป็นไฟล์ประเภทอื่น
        const link = document.createElement("a");
        link.href = url;

        // กำหนดนามสกุลไฟล์ตาม content-type
        let extension = "";
        switch (contentType) {
          case "image/jpeg":
            extension = ".jpg";
            break;
          case "image/png":
            extension = ".png";
            break;
          case "application/msword":
            extension = ".doc";
            break;
          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            extension = ".docx";
            break;
          case "application/vnd.ms-excel":
            extension = ".xls";
            break;
          case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            extension = ".xlsx";
            break;
          default:
            extension = "";
        }

        const fileName = `file${extension}`; // สามารถเปลี่ยนชื่อไฟล์ตามต้องการ
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    }
  }, []);

  const modalStyle = {
    ...masterModalStyle,
    height: item ? "100%" : undefined,
    width: item ? "80%" : undefined,
  };

  const handleOpenTableHistory = () => {
    handleOpenModal(
      <TableHistory
        setHistoryModal={setHistoryModal}
        Apihistory={item?.history}
        id={item?.id}
      />
    );
  };

  const handleOpenTableReferDetailPage = (id) => {
    handleOpenModal(
      <>
        <Hr03RequestAccountActiveDetailPage
          handleCancel={handleCloseModal}
          itemId={id}
          isViewOnly={isViewOnly}
        />
      </>
    );
  };

  return (
    <Box sx={modalStyle}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h5">
            {" "}
            <Typography variant="h5">
              ตรวจสอบรายการขอ
              {item?.type === 1 ? "ถอนแบบรายชื่อ" : "เปิดแบบรายบัญชี"} (ID:{" "}
              {itemId})
              {item?.status === "new" ? (
                <>
                  <Chip
                    label="พงส."
                    color="warning"
                    sx={{
                      ml: 1,
                      height: "30px",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                  <Chip
                    label="รอยืนยัน"
                    color="warning"
                    sx={{
                      ml: 1,
                      height: "30px",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                </>
              ) : (
                <Chip
                  label={
                    item?.status === "sent_to_bk"
                      ? "บก.พิจารณา"
                      : item?.status === "bk_reject"
                      ? "บก.ปฏิเสธ"
                      : item?.status === "sent_to_bh"
                      ? "บช.พิจารณา"
                      : item?.status === "bh_reject"
                      ? "บช.ปฏิเสธ"
                      : item?.status === "bh_approve"
                      ? "ตร.พิจารณา"
                      : item?.status === "rtp_reject"
                      ? "ตร.ปฏิเสธ"
                      : item?.status === "rtp_approve"
                      ? "ปปง.พิจารณา"
                      : item?.status === "send_to_bank"
                      ? "ผ่านการอนุมัติ"
                      : item?.status === "aml_approve"
                      ? "ผ่านการพิจารณา"
                      : item?.status === "aml_reject_end"
                      ? "ไม่ผ่านข้อยุติพิจารณา"
                      : item?.status === "aml_reject"
                      ? "ไม่ผ่านพิจารณา"
                      : item?.status === "aml_reject_account"
                      ? "ไม่ผ่าน ดำเนินใหม่รายบัญชี"
                      : "ยังไม่มีคำขอ"
                  }
                  color={
                    item?.status === "sent_to_bk" ||
                    item?.status === "sent_to_bh"
                      ? "warning"
                      : item?.status === "bk_reject" ||
                        item?.status === "bh_reject" ||
                        item?.status === "rtp_reject" ||
                        item?.status === "aml_reject" ||
                        item?.status === "aml_reject_account"
                      ? "error"
                      : item?.status === "rtp_approve"
                      ? "primary"
                      : item?.status === "aml_approve" ||
                        item?.status === "send_to_bank"
                      ? "success"
                      : item?.status === "aml_reject_end"
                      ? "pink"
                      : item?.status === "bh_approve"
                      ? "violet"
                      : "default" //grey
                  }
                  sx={{
                    ml: 3,
                    height: "30px",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              )}
              {item?.refer_id && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenTableReferDetailPage(item?.refer_id)}
                  sx={{
                    mx: 1,
                    height: "30px",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                      fontSize: "14px",
                    },
                    boxShadow: "none",
                    borderRadius: "20px",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  Refer. ID {item?.refer_id ?? "-"}
                </Button>
              )}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenTableHistory}
                startIcon={<InsertChart />}
                disabled={loading}
                sx={{
                  mx: 1,
                  height: "30px",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                    fontFamily: "Kanit",
                    fontSize: "14px",
                  },
                  borderRadius: "20px",
                }}
              >
                ประวัติของรายการ
              </Button>
            </Typography>
          </Typography>
        </Grid>

        {item?.download_request_pdf &&
          request_account_HR03.includes("download") && (
            <Grid
              item
              xs={10}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <LoadingButton
                variant="contained"
                color="primary"
                startIcon={<DownloadIcon />}
                onClick={async () => {
                  await previewFiles(item?.download_request_pdf);
                }}
                loading={isLoading}
                sx={{ width: "auto", padding: "6px 16px" }} // กำหนดขนาดของปุ่ม
              >
                ดาวน์โหลดเอกสาร
              </LoadingButton>
            </Grid>
          )}
      </Box>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {isLoading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <Form
                //validate={validate}
                onSubmit={() => {}}
                component={Hr03RequestAccountActiveFormDetail}
                id={itemId}
                page="detail"
                initialValues={item}
                isViewOnly={isViewOnly}
                handleCancel={handleCancel}
                handleOpenTableHistory={handleOpenTableHistory}
                loading={loading}
                setLoading={setLoading}
              />
              <Modal
                open={historyModal ? true : false}
                onClose={() => {
                  setHistoryModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <div>{historyModal}</div>
              </Modal>
            </>
          )}
        </Paper>
      </Grid>
    </Box>
  );
}
