import { Box, Grid, Typography, Avatar } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import CountUp from "react-countup";

const Express = ({
  data
}) => {
  const title = "จำนวนคงค้าง ใบงานเร่งด่วน";

  const card = useMemo(() => {
    return [
      {
        title: data[0]?.title ?? "เร่งด่วน ม้าดำ",
        color: "#EF5350",
        count: data[0]?.total ?? 0,
      },
      {
        title: data[1]?.title ?? "เร่งด่วน ม้าเทาเข้ม",
        color: "#506A85",
        count: data[1]?.total ?? 0,
      },
    ];
  }, [data]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h6" align="center" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        </Grid>

        {card?.map((item, index) => (
          <Grid item md={12} sm={12} xs={12} key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: item.color,
                padding: 2,
                height: "120px",
                borderRadius: "12px",
                width: "90%",
                minWidth: "200px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                position: "relative",
                overflow: "hidden",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                  fontFamily: "Kanit, sans-serif",
                  lineHeight: "1.2",
                }}
              >
                {item.title}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  mt: 1,
                  position: "relative",
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "45px",
                    textAlign: "center",
                  }}
                >
                  <CountUp
                    separator=","
                    start={0}
                    end={_.isNil(item.count) ? 0 : item.count}
                  />
                </Typography>

                <Typography
                  sx={{
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "400",
                    position: "absolute",
                    right: "16px",
                    bottom: "0",
                  }}
                >
                  รายการ
                </Typography>
              </Box>

              
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Express;
