import { Save } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useEffect, useMemo, useState } from "react";
import { Field } from "react-final-form";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import FieldCheckBox from "../../../form-fields/FieldCheckBox";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";

export default function AgencyAddForm({
  onCancel, //mycustom
  onError, // mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  isEdit = false,
}) {
  const navigate = useNavigate();
  const qState = useSelector((state) => state.auth.user.permissions);
  const [bh_code, setBH_code] = useState(null);
  // const [bk_code, setBK_code] = useState(null);
  const { isLoading: bhIsLoading, data: bhData } = useQuery("BH", () =>
    api
      .get(`/api/orgs/bh${!isEdit ? "?status=true" : ""}`)
      .then((res) => res.data)
  );
  const {
    isLoading: bkIsLoading,
    data: bkData,
    refetch: bkrefetch,
  } = useQuery("BK", () =>
    api
      .get(`/api/orgs/bk?bh_code=${bh_code}${!isEdit ? "&status=true" : ""}`)
      .then((res) => res.data)
  );

  useEffect(() => {
    // if (bh_code === null) setBK_code("");

    setTimeout(() => {
      bkrefetch();
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bh_code]);

  const selectBH = useMemo(() => {
    if (values.BH_CODE !== undefined) setBH_code(values.BH_CODE);
    return bhData != null
      ? bhData.find((option) => option.BH_CODE === values.BH_CODE) || null
      : null;
  }, [bhData, values.BH_CODE]);
  const selectBK = useMemo(() => {
    return bkData != null
      ? bkData.find((option) => option.BK_CODE === values.BK_CODE) || null
      : null;
  }, [bkData, values.BK_CODE]);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid xs={12}>
        <Field name="ORG_TYPE">
          {({ input, meta }) => (
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                ประเภทหน่วยงาน
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e, v) => {
                  form.change("ORG_TYPE", v);

                  switch (v) {
                    case "BH":
                      form.change("BH_CODE", null);
                      form.change("BK_CODE", null);
                      break;
                    case "BK":
                      form.change("BK_CODE", null);
                      break;
                    default:
                      break;
                  }
                }}
              >
                <FormControlLabel
                  value="BH"
                  control={<Radio checked={values.ORG_TYPE === "BH"} />}
                  label="หน่วยงาน บช"
                />
                <FormControlLabel
                  value="BK"
                  control={<Radio checked={values.ORG_TYPE === "BK"} />}
                  label="หน่วยงาน บก"
                />
                <FormControlLabel
                  value="KK"
                  control={<Radio checked={values.ORG_TYPE === "KK"} />}
                  label="หน่วยงาน กก"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Field>
      </Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {(values.ORG_TYPE === "BK" || values.ORG_TYPE === "KK") && (
            <Grid item xs={12} md={6}>
              <Field name="BH_CODE">
                {({ input }) => (
                  <Autocomplete
                    loading={bhIsLoading}
                    onChange={(e, v) => {
                      form.change("BH_CODE", v.BH_CODE);
                      setBH_code(v.BH_CODE);
                    }}
                    disablePortal
                    disableClearable
                    options={bhData}
                    renderInput={(params) => (
                      <TextField {...params} label="หน่วยงาน บช" />
                    )}
                    value={selectBH}
                  />
                )}
              </Field>
            </Grid>
          )}
          {values.ORG_TYPE === "KK" && values.BH_CODE && (
            <Grid item xs={12} md={6}>
              <Field name="BK_CODE">
                {({ input }) => (
                  <Autocomplete
                    loading={bkIsLoading}
                    disablePortal
                    disableClearable
                    onChange={(e, v) => {
                      form.change("BK_CODE", v.BK_CODE);
                    }}
                    options={bkData}
                    sx={{ width: "full" }}
                    renderInput={(params) => (
                      <TextField {...params} label="หน่วยงาน บก" />
                    )}
                    value={selectBK}
                  />
                )}
              </Field>
            </Grid>
          )}
        </Grid>
      </Box>
      <Divider textAlign="center" sx={{ mt: 3 }}>
        เพิ่มหน่วยงาน
      </Divider>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="ORG_CODE"
              label="รหัสหน่วยงาน*"
              controlProps={{ fullWidth: true }}
              readOnly={isEdit}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="ORG"
              label="ชื่อหน่วยงาน*"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="ORG_ABBR"
              label="ชื่อย่อหน่วยงาน*"
              controlProps={{ fullWidth: true }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        {qState?.setting_police_station_report?.includes("edit") && (
          <>
            <FormLabel id="demo-row-radio-buttons-group-label">
              เงื่อนไขรายงาน
            </FormLabel>
            <Grid container my={2}>
              <Grid item>
                <FieldCheckBox
                  name="is_report"
                  label={"ไม่ต้องการใช้ข้อมูลหน่วยงานนี้ในการออกรายงาน"}
                  labelPlacement="start"
                  inputLabelProps={{ sx: { m: 0 } }}
                  inputProps={{ sx: { mx: 1 } }}
                />
              </Grid>
            </Grid>
          </>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Field name="status">
              {({ input }) => (
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    สถานะ
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e, v) => {
                      form.change("status", v === "true");
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio checked={values.status} />}
                      label="เปิดใช้งาน"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio checked={!values.status} />}
                      label="ปิดใช้งาน"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Field>
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: 2 }} />
      {!qState?.setting_police_station?.includes("edit") && (
        <p className="text-red-400 my-2 font-normal">คุณไม่มีสิทธิ์แก้ไข</p>
      )}
      <Grid item xs={12}>
        <SubmitBtn
          variant="contained"
          startIcon={<Save />}
          submitting={submitting}
          pristine={pristine}
          readOnly={!qState?.setting_police_station?.includes("edit")}
        >
          บันทึก
        </SubmitBtn>
        <Button
          type="button"
          variant="outlined"
          disabled={submitting}
          sx={{ ml: 2 }}
          onClick={() => {
            navigate("/settingagency");
          }}
        >
          ยกเลิก
        </Button>
      </Grid>
    </form>
  );
}

// export const validate = (values, props) => {
//   const errors = {};
//   const vResult = schema().validate(values, {
// abortEarly: false,
// allowUnknown: false,
//   });

//   // console.log(vResult);
//   // console.log(schema);

//   if (vResult.error) {
// let details = vResult.error.details;
// details.forEach(({ context, message }) => {
//   _.set(errors, context.label, message);
//   // return (errors[context.label] = message);
//   errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
// });
//   }

//   // console.log(errors);

//   return errors;
// };

// export const validateRules = () => ({
//   rank: Joi.string().max(255).required(),
//   cardId: Joi.string()
// .pattern(/^\d{13}$/)
// .required(),
//   laserCode: Joi.string().required(),
//   fname: Joi.string().max(255).required(),
//   lname: Joi.string().max(255).required(),
//   pol_tel: Joi.string()
//     .pattern(/^\d{10}$/)
//     .required(),
//   email: Joi.string()
//     .email({ tlds: { allow: false } })
//     .required(),
//   job_title: Joi.string().max(255).required(),
//   // agency_under: Joi.string().pattern(/^\d{5}$/),
//   // agency: Joi.string().pattern(/^\d{5}$/),
//   // agency_supervise: Joi.string().pattern(/^\d{5}$/),
//   agency_under: Joi.string().max(255),
//   agency: Joi.string().max(255),
//   agency_supervise: Joi.string().max(255),
//   password: Joi.string().min(8).required(),
//   confirm_password: Joi.string().min(8).required(),
//   status: Joi.bool(),
// });

// const schema = () => Joi.object(validateRules()).error(validationMsg());

export const mapToInit = (item) => {
  // let initValue = _.pick(item, Object.keys(validateRules()));
  // if (initValue.nationality === "TH") {
  //   initValue = _.omit(initValue, ["nationality"]);
  // } else {
  //   initValue.is_foreigner = true;
  //   initValue = _.omit(initValue, ["eng_name", "eng_midname", "eng_surname"]);
  // }
  return item;
};
