import { Alert, Button, Divider, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import FieldMultipleFileDropZone from "../../form-fields/FieldMultipleFileDropZone";
import SubmitBtn from "../../SubmitBtn";
import { TextSnippet } from "@mui/icons-material";
import _ from "lodash";
import Joi from "joi";
import validationMsg from "../../../helpers/validationMsg";
import { FORM_ERROR } from "final-form";
import { useSelector } from "react-redux";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import letterTypes from "../../../data/selects/letterTypes";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";

export default function UploadForm({
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  isExternal,
}) {
  const qState = useSelector((state) => state.auth.user.permissions);
  const bank_id = useSelector((state) => state.auth.user?.bank?.id ?? 0);
  const isInternal = useSelector(
    (state) => state.auth.user?.isInternal ?? false
  );
  useEffect(() => {
    if (bank_id) {
      form.change("bank_id", bank_id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        {submitFailed && (error || submitError) && (
          <Grid item xs={12}>
            <Alert severity="warning" sx={{ width: "auto" }}>
              {error || submitError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAutoComplete
            name="type_id"
            label="ประเภทคำขอ"
            options={letterTypes}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAsyncSelect
            name="bank_id"
            label="หน่วยงานภายนอก"
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            basePath={`/api/banks`}
            disabled={isExternal && bank_id}
            readOnly={isExternal && bank_id}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <Typography variant="subtitle1">Template file *</Typography> */}
          <FieldMultipleFileDropZone
            name="template_files"
            accept={[
              "text/csv",
              "application/vnd.ms-excel",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
              ".xlsb",
            ]}
            disabled={
              (!qState?.extorg_file_normalization?.includes("create") &&
                !isInternal) ||
              (!qState?.file_normalization?.includes("create") && isInternal) ||
              submitting
            }
            readonly={
              (!qState?.extorg_file_normalization?.includes("create") &&
                !isInternal) ||
              (!qState?.file_normalization?.includes("create") && isInternal)
            }
            placeholder={
              <>
                Drag 'n' drop some files here, or click to select files <br />
                (*.csv, *.xls, *.xlsx,*.xlsb)
              </>
            }
            //     dropZoneProps={{ maxSize: 1048576 * 10 }}
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          {((!qState?.extorg_file_normalization?.includes("create") &&
            !isInternal) ||
            (!qState?.file_normalization?.includes("create") &&
              isInternal)) && (
            <h3 className="my-3 text-red-500 font-normal text-sm">
              คุณไม่มีสิทธิ์เพิ่มข้อมูล
            </h3>
          )}

          <Divider></Divider>
        </Grid>
        <Grid item md={12}>
          <Stack direction="row" spacing={2}>
            <SubmitBtn
              variant="contained"
              startIcon={<TextSnippet />}
              submitting={submitting}
              pristine={pristine}
              disabled={
                (!qState?.extorg_file_normalization?.includes("create") &&
                  !isInternal) ||
                (!qState?.file_normalization?.includes("create") && isInternal)
              }
            >
              Process
            </SubmitBtn>
            <Button
              variant="outlined"
              disabled={submitting || _.isEmpty(values)}
              onClick={(event) => {
                form.restart();
              }}
            >
              clear
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const schema = Joi.object(validateRules()).error(validationMsg());
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  // if (
  //   !errors.template_file &&
  //   values.template_file?.size &&
  //   (values.template_file.size / 1024000).toFixed(2) > 10
  // ) {
  //   errors.template_file = "ขนาดไม่เกิน 10 MB";
  //   errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
  // }

  // if (
  //   !errors.response_letter_file &&
  //   values.response_letter_file?.size &&
  //   (values.response_letter_file.size / 1024000).toFixed(2) > 5
  // ) {
  //   errors.response_letter_file = "ขนาดไม่เกิน 5 MB";
  //   errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
  // }

  return errors;
};

export const validateRules = () => ({
  type_id: Joi.number().required(),
  bank_id: Joi.number().required(),
  template_files: Joi.array().min(1).max(30).items(Joi.any()).required(),
});
