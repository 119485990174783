import React, { useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from "@mui/material";

const Hr03Type = ({ data }) => {
  const title = "จำนวนข้อมูลขอเปิดใช้บัญชี HRO3 ตามแต่ละประเภท";

  return (
    <>
      <Typography
        variant="h6"
        align="center"
        sx={{ fontWeight: "bold", mb: 2 }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          p: 2,
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", // เพิ่ม Shadow ให้ Box
          borderRadius: "5px", // ความโค้งมนให้ Box
          backgroundColor: "white", // พื้นหลังของ Box
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            borderRadius: "12px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#F5F5F5",
                  "& th": {
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Kanit, sans-serif",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                <TableCell sx={{ width: "30%" }}>
                  ประเภทคำร้องขอ (รายการ)
                </TableCell>

                <TableCell sx={{ width: "20%" }}>
                  <Tooltip title="คำร้องขอแบบร่าง">
                    <Typography
                      variant="content"
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                    >
                      {" "}
                      ร่างคำขอ{" "}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "15%" }}>
                  <Tooltip title="คำร้องขอทั้งหมด ที่ในขั้นตอนดำเนินการตรวจสอบ">
                    <Typography
                      variant="content"
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                    >
                      {" "}
                      ร้องขอ{" "}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "15%" }}>
                  <Tooltip title="คำร้องขอ ที่ผ่านพิจารณา">
                    <Typography
                      variant="content"
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                    >
                      {" "}
                      ผ่าน{" "}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  <Tooltip title="คำร้องขอ ที่ไม่ผ่านพิจารณา">
                    <Typography
                      variant="content"
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                    >
                      {" "}
                      ไม่ผ่าน{" "}
                    </Typography>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: "40%" }}>{row.name}</TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    {row.new}
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    {row.process}
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    {row.success}
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    {row.fail}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default Hr03Type;
