import { Chip, Tooltip } from "@mui/material";

const requestStatus = {
  new: { label: "พงส.", color: "warning" },
  // sent_to_bk: {
  //   label: "บก. พิจารณา",
  //   color: "warning",
  //   desc: "อยู่ในระหว่างคณะทำงานระดับ บก. พิจารณา",
  // },
  sent_to_bh: {
    label: "บช. พิจารณา",
    color: "warning",
    desc: "อยู่ในระหว่างคณะทำงานระดับ บช. พิจารณา",
  },
  bh_reject: {
    label: "บช. ปฏิเสธ",
    color: "error",
    desc: "คณะทำงาน บช. ปฏิเสธ",
    stepProps: { error: true },
  },
  bh_approve: {
    label: "ตร. พิจารณา",
    color: "info",
    desc: "อยู่ในระหว่างคณะทำงานระดับ ตร. พิจารณา",
  },
  rtp_reject: {
    label: "ตร. ปฏิเสธ",
    color: "error",
    desc: "คณะทำงาน ตร. ปฏิเสธ",
    stepProps: { error: true },
  },
  rtp_approve: {
    label: "ตร. อนุมัติ",
    color: "info",
    desc: "คณะทำงาน ตร. ตรวจสอบแล้ว",
  },
  sent_to_aml: {
    label: "ปปง. พิจารณา",
    color: "info",
    desc: "อยู่ในระหว่าง ปปง. พิจารณา",
  },
  hr03: {
    label: "HR03",
    color: "success",
    desc: "ขึ้นบัญชี hr03 แล้ว",
  },
  rtp_process1: {
    label: "เตรียมเข้าประชุม",
    color: "warning",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  rtp_process2: {
    label: "เตรียมเข้าประชุม",
    color: "warning",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  rtp_process3: {
    label: "เตรียมเข้าประชุม",
    color: "warning",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  rtp_process4: {
    label: "เตรียมเข้าประชุม",
    color: "warning",
  },
  "rtp_process3-1": {
    label: "เตรียมเข้าประชุม",
    color: "warning",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  "rtp_process3-2": {
    label: "เตรียมเข้าประชุม",
    color: "warning",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  "rtp_process3-3": {
    label: "เตรียมเข้าประชุม",
    color: "warning",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
  "rtp_process3-4": {
    label: "เตรียมเข้าประชุม",
    color: "warning",
    desc: "ตร. เตรียมนำเข้าที่ประชุม ปปง. ครั้งถัดไป",
  },
};

export default requestStatus;

export const getChip = (status, props = {}) => {
  const reqStatus = requestStatus[status];
  if (!reqStatus) {
    return (
      <Tooltip placement="top" arrow title="ไม่พบสถานะ กรุณาติดต่อ admin">
        <Chip
          label="unknow"
          color="error"
          {...props}
          sx={{
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
              fontFamily: "Kanit",
            },
          }}
        />
      </Tooltip>
    );
  }

  let chip = (
    <Chip
      label={reqStatus.label}
      color={reqStatus.color}
      {...props}
      sx={{
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
          fontFamily: "Kanit",
        },
      }}
    />
  );

  if (reqStatus.desc) {
    chip = (
      <Tooltip placement="top" arrow title={reqStatus.desc}>
        {chip}
      </Tooltip>
    );
  }

  return chip;
};

export const getDescChip = (status, props = {}) => {
  const reqStatus = requestStatus[status];
  if (!reqStatus) {
    return (
      <Chip
        label="ไม่พบสถานะ กรุณาติดต่อ admin"
        color="error"
        {...props}
        sx={{
          height: "auto",
          "& .MuiChip-label": {
            display: "block",
            whiteSpace: "normal",
            fontFamily: "Kanit",
          },
        }}
      />
    );
  }

  return (
    <Chip
      label={reqStatus.desc || reqStatus.label}
      color={reqStatus.color}
      {...props}
      sx={{
        height: "auto",
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
          fontFamily: "Kanit",
        },
      }}
    />
  );
};

export const masterSteps = [
  "new",
  // "sent_to_bk",
  "sent_to_bh",
  ["bh_approve", "bh_reject"],
  ["rtp_process1", "rtp_process2", "rtp_process3"],
  // ["rtp_approve", "rtp_reject"],
  "sent_to_aml",
  "hr03",
];

export const Hr03Status = {
  new: { label: "พงส.", color: "warning", desc: "รายการรอการยืนยันข้อมูล" },
  sent_to_bk: {
    label: "บก.พิจารณา",
    color: "warning",
    desc: "อยู่ในระหว่างคณะทำงานระดับ บก. พิจารณา",
  },
  bk_reject: {
    label: "บก.ปฏิเสธ",
    color: "error",
    desc: "คณะทำงาน บก. ปฏิเสธ",
    stepProps: { error: true },
  },
  sent_to_bh: {
    label: "บช.พิจารณา",
    color: "warning",
    desc: "อยู่ในระหว่างคณะทำงานระดับ บช. พิจารณา",
  },
  bh_reject: {
    label: "บช.ปฏิเสธ",
    color: "error",
    desc: "คณะทำงาน บช. ปฏิเสธ",
    stepProps: { error: true },
  },
  bh_approve: {
    label: "ตร.พิจารณา",
    color: "info",
    desc: "อยู่ในระหว่างคณะทำงานระดับ ตร. พิจารณา",
  },
  rtp_reject: {
    label: "ตร.ปฏิเสธ",
    color: "error",
    desc: "คณะทำงาน ตร. ปฏิเสธ",
    stepProps: { error: true },
  },
  rtp_approve: {
    label: "ปปง.พิจารณา",
    color: "info",
    desc: "อยู่ในระหว่าง ปปง. พิจารณา",
  },
  aml_reject_end: {
    label: "ไม่ผ่านข้อยุติพิจารณา",
    color: "secondary",
    desc: "ไม่ผ่านข้อยุติพิจารณา",
    stepProps: { error: true },
  },
  aml_reject: {
    label: "ไม่ผ่านพิจารณา",
    color: "error",
    desc: "ไม่ผ่านพิจารณา รอแก้ไขมาใหม่",
    stepProps: { error: true },
  },
  aml_reject_account: {
    label: "ไม่ผ่าน ดำเนินใหม่รายบัญชี",
    color: "error",
    desc: "ไม่ผ่านพิจารณา ต้องส่งใหม่แบบรายบัญชี",
    stepProps: { error: true },
  },
  send_to_bank: {
    label: "ผ่านการอนุมัติ",
    color: "success",
    desc: "ตร.อนุมัติ รอส่งหาธนาคาร",
  },
  aml_approve: {
    label: "ผ่านการพิจารณา",
    color: "success",
    desc: "ระบบได้รับผลให้ ผ่านการพิจารณาแล้ว",
  },
};

export const Hr03Steps = [
  "new",
  ["sent_to_bk", "bk_reject"],
  ["sent_to_bh", "bh_reject"],
  ["bh_approve", "rtp_reject"],
  "sent_to_aml",
  "hr03",
];

export const Hr03ListSteps = [
  "new",
  "sent_to_bk",
  ["sent_to_bh", "bk_reject"],
  ["bh_approve", "bh_reject"],
  ["rtp_approve", "rtp_reject"],
  ["aml_approve", "aml_reject_end", "aml_reject", "aml_reject_account"],
];

export const Hr03AccountSteps = [
  "new",
  "sent_to_bk",
  ["sent_to_bh", "bk_reject"],
  ["bh_approve", "bh_reject"],
  ["send_to_bank", "rtp_reject"],
];
