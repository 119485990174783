import { Download, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Grid, Snackbar, Stack } from "@mui/material";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../../apis";
import FieldAutoComplete from "../../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../../form-fields/FieldDatePicker";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";
import { format } from "date-fns";

const Joi = require("joi").extend(require("@joi/date"));

export default function ThaiIDLogSearchForm({
  loading, //mycustom
  onReset, //mycustom
  showStatusField, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  rows,
  filter,
}) {
  const event = [
    { value: "created", label: "Created" },
    { value: "updated", label: "Updated" },
    { value: "deleted", label: "Deleted" },
  ];

  const qState = useSelector((state) => state.auth.user.permissions);

  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);

  const [fetchInfo, SetFetchInfo] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchInfo(false);
  };

  const handleDownload = (uri, setActionState) => {
    setLoadingPDF(true);
    setActionState(true);
    api
      .post(
        uri,
        {
          recived_at: values.recived_at ?? format(new Date(), "yyyy-MM-dd"),
        },
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        // handleCancel({ type: "success", message: "สำเร็จ" });
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? /filename="?([^"]+)"?/.exec(contentDisposition)[1]
          : "download";
        fileDownload(response.data, filename);
      })
      .catch((e) => {
        console.log(e);
        SetFetchInfo({ type: "error", payload: "เกิดข้อผิดพลาด" });
      })
      .finally(() => {
        setLoadingPDF(false);
        setActionState(false);
      });
  };

  return (
    <form onSubmit={handleSubmit} initialValues={initialValues} noValidate>
      <Snackbar
        open={fetchInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity={fetchInfo.type || "warning"}
          sx={{ width: "100%" }}
        >
          {fetchInfo.payload}
        </Alert>
      </Snackbar>
      {qState?.thaiid_log?.includes("search") && (
        <Grid container sx={{ mb: 3 }} spacing={2}>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldInput
              name="user"
              label="ค้นหาด้วยชื่อผู้ใช้"
              controlProps={{ fullWidth: true }}
              placeholder="ระบุชื่อผู้ใช้"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldInput
              name="email"
              label="email"
              controlProps={{ fullWidth: true }}
              placeholder="ระบุ อีเมล์"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldDatePicker
              name="start_date"
              label="จากวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              value={new Date()}
              loading={loadingPDF}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={4}>
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              value={new Date()}
              loading={loadingPDF}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2} mb={2}>
        {qState?.thaiid_log?.includes("search") && (
          <Grid item md={12} lg={4}>
            <Stack direction="row" spacing={2} alignItems="center">
              <SubmitBtn
                variant="contained"
                startIcon={<Search />}
                submitting={loading}
                pristine={pristine && !modifiedSinceLastSubmit}
              >
                ค้นหา
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={loading || (pristine && !modifiedSinceLastSubmit)}
                onClick={(event) => {
                  form.reset();
                  if (onReset) onReset(event);
                }}
              >
                Reset
              </Button>
            </Stack>
          </Grid>
        )}

        {/* <Grid
          item
          md={12}
          lg={qState?.transaction_log?.includes("search") ? 8 : 12}
        >
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <LoadingButton
              type="button"
              variant="outlined"
              startIcon={<Download />}
              loadingPosition="start"
              // loading={pdfDownloading}
             
              onClick={() => handleDownload("/api/", setPdfDownloading)}
              disabled={!values.recived_at} 
              
            >
             
              ดาวน์โหลด
            </LoadingButton>
          </Stack>
        </Grid> */}
      </Grid>
    </form>
  );
}
