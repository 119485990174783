import { Alert, Grid, Paper, Snackbar, Typography } from "@mui/material";
import { format, startOfToday } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../apis";
import { defaultJoiDates } from "../../../helpers/dateHelper";
import RequestNewFormMany from "./request-forms/RequestNewFormMany";

export default function RequestAddPageAMany({ type }) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();
  const [item, setItem] = useState({});

  const [fetchError, SetFetchError] = useState(false);
  const handleToastClose = (event, reason) => {
    SetFetchError(false);
  };

  const validateRules = () => ({
    bank_id: Joi.any()
      .messages({
        "string.empty": "กรุณาระบุธนาคาร",
        "any.required": "กรุณาระบุธนาคาร",
      })
      .required(),
    bank_accounts: Joi.array()
      .items(Joi.any())
      .messages({
        "array.empty": "กรุณาเลือกบัญชีธนาคาร",
        "array.min": "กรุณาเลือกบัญชีธนาคาร",
        "array.required": "กรุณาเลือกบัญชีธนาคาร",
      })
      .min(1)
      .required(),

    noA: Joi.string()
      .messages({
        "string.empty": "กรุณาระบุเลขหนังสือ",
        "any.required": "กรุณาระบุเลขหนังสือ",
      })
      .required(),
    dateA: Joi.date()
      .min(defaultJoiDates.minDate)
      .max(defaultJoiDates.forceUTC(startOfToday()))
      .messages({
        "string.empty": "กรุณาระบุวันที่",
        "any.required": "กรุณาระบุวันที่",
        "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
          defaultJoiDates.minDate,
          "dd/MM/yyyy"
        )}`,
        "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
          defaultJoiDates.forceUTC(startOfToday()),
          "dd/MM/yyyy"
        )}`,
      })
      .required(),
    rank: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุยศ",
        "any.required": "กรุณาระบุยศ",
      })
      .required(),
    fname: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุชื่อ",
        "any.required": "กรุณาระบุชื่อ",
      })
      .required(),
    lname: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุนามสกุล",
        "any.required": "กรุณาระบุนามสกุล",
      })
      .required(),
    job_title: Joi.string()
      .max(255)
      .messages({
        "string.empty": "กรุณาระบุตำแหน่ง",
        "any.required": "กรุณาระบุตำแหน่ง",
      })
      .required(),
    email: Joi.string()
      .messages({
        "string.empty": "กรุณาระบุอีเมล",
        "any.required": "กรุณาระบุอีเมล",
      })
      .required(),
    policestation_org_code: Joi.string()
      .pattern(/^\d{5}$/)
      .messages({
        "string.empty": "กรุณาระบุหน่วยงาน",
        "any.required": "กรุณาระบุหน่วยงาน",
      })
      .required(),
    pol_tel: Joi.string()
      .pattern(/^\d{10}$/)
      .messages({
        "string.empty": "กรุณาระบุเบอร์โทร",
        "any.required": "กรุณาระบุเบอร์โทร",
      })
      .required(),
    address: Joi.string(),
    // .messages({
    //   "string.empty": "กรุณาระบุที่อยู่",
    //   "any.required": "กรุณาระบุที่อยู่",
    // })
    // .required(),
    profile_signature: Joi.any()
      .messages({
        "string.empty": "กรุณาอัพโหลดลายเซ็น",
        "any.required": "กรุณาอัพโหลดลายเซ็น",
      })
      .required(),
  });
  const schema = () => Joi.object(validateRules());

  const validate = (values, props) => {
    const errors = {};
    const vResult = schema().validate(values, {
      abortEarly: false,
      allowUnknown: true,
    });

    if (vResult.error) {
      let details = vResult.error.details;
      // console.log({details})
      details.forEach(({ context, message }) => {
        _.set(errors, context.label, message);
        errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
      });
    }
    return errors;
  };

  useEffect(() => {
    let isSubscribed = true;
    api
      .get(`/api/me`)
      .then(({ data }) => {
        // console.log(data.permisstions.map((v) => v.option.map((v) => v.info)));
        isSubscribed && setItem({ ...data, request_type_id: type });
      })
      .catch(({ response }) => {
        if (response && response.status === 404) {
          SetFetchError("เกิดข้อผิดพลาด");
        }
        SetFetchError("เกิดข้อผิดพลาด");
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  const onSubmit = async (formData) => {
    if (!formData.profile_signature) {
      return {
        [FORM_ERROR]: "กรุณาอัพโหลดลายเซ็น",
        profile_signature: "กรุณาอัพโหลดลายเซ็น",
      };
    }

    const dataAll = [];
    const dataA = {
      request_type_id: type,
      letter: type === 2 ? "account" : "accountR",
      data: {
        address: formData.address,
        bank_account: parseInt(formData.bank_accounts[0].bank_account_id),
        bank_accounts: formData.bank_accounts,
        bank_id: formData.bank_id,
        date: formData.dateA,
        email: formData.email,
        fname: formData.fname,
        job_title: formData.job_title,
        lname: formData.lname,
        no: formData.noA,
        policestation_org_code: formData.policestation_org_code,
        rank: formData.rank,
        signature:
          formData.profile_signature &&
          formData.profile_signature instanceof File
            ? await signatureResize(formData.profile_signature)
            : formData.profile_signature
            ? formData.profile_signature
            : undefined,
        tel: formData.pol_tel,
      },
    };
    dataAll.push(dataA);

    const dataSubmit = [];

    const user = {
      rank: formData.rank,
      fname: formData.fname,
      lname: formData.lname,
      job_title: formData.job_title,
      policestation_org_code: formData.policestation_org_code,
      email: formData.email,
      pol_tel: formData.pol_tel,
      address: formData.address,
      profile_signature:
        formData.profile_signature && formData.profile_signature instanceof File
          ? await signatureResize(formData.profile_signature)
          : formData.profile_signature
          ? formData.profile_signature
          : undefined,
    };

    for (let i = 0; i < dataAll.length; i++) {
      let submitData = {};

      if (
        dataAll[i].data.signature &&
        dataAll[i].data.signature instanceof File
      ) {
        // resize before submit
        try {
          const signatureBase64 = await signatureResize(
            dataAll[i].data.signature
          );
          submitData = {
            request_type_id: dataAll[i].request_type_id,
            letter: dataAll[i].letter,
            data: { ...dataAll[i].data, signature: signatureBase64 },
          };
        } catch (e) {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
            signature: "เกิดข้อผิดพลาด",
          };
        }
      } else {
        submitData = {
          request_type_id: dataAll[i].request_type_id,
          letter: dataAll[i].letter,
          data: dataAll[i].data,
        };
      }
      dataSubmit.push(submitData);
    }

    try {
      const res = await api.post(`/api/cases/${caseId}/pol_requests_new`, {
        user,
        requests: dataSubmit,
      });

      if (res.data.id) {
        navigate(`/cases/${caseId}/requests/${res.data.id}`);
      } else {
        navigate(`/cases/${caseId}/mgt`);
      }
    } catch ({ response }) {
      if (response.status === 422) {
        const errors = _.mapValues(response.data.errors, (e) => e[0]);

        // convert laravel dot notation key to object
        const normalizeKeyError = {};
        for (const [key, value] of Object.entries(errors)) {
          _.set(normalizeKeyError, key, value);
        }

        normalizeKeyError[FORM_ERROR] = response.data.message;

        // console.log(normalizeKeyError);
        return normalizeKeyError;
      } else {
        return {
          [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
        };
      }
    }
  };

  return (
    <>
      <Snackbar
        open={fetchError ? true : false}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {fetchError}
        </Alert>
      </Snackbar>
      <Grid item xs={12}>
        <Typography variant="h5">สร้างคำขอ (ID: {caseId} )</Typography>
      </Grid>
      {/* <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={RequestForm}
          />
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Form
            validate={validate}
            onSubmit={onSubmit}
            component={RequestNewFormMany}
            initialValues={mapToInit(item)}
          />
        </Paper>
      </Grid>
    </>
  );
}

const signatureResize = (file) => {
  const fileType = file.type === "image/png" ? "PNG" : "JPEG";
  const compress = file.type === "image/png" ? 100 : 90;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      90,
      50,
      fileType,
      compress,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
};

export const mapToInit = (item) => {
  return item;
};

// const mapToInit = (item) => {
//   return _.pick(item, Object.keys(validateRules));
// };
