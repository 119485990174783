import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import api from "../../../apis";
import _ from "lodash";

const FieldComponent = ({
  label,
  required = false,
  readOnly = false,
  controlProp = {},
  inputProp = {},
  basePath,
  itemFormat,
  itemFilter,
  input,
  meta: { initial, pristine, error, submitError, touched },
  pageSize = 30,
  placeholder = "พิมเพื่อค้นหา",
  requestParams,
  disabled = false,
  reFetch = false,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const [inputValue, setInputValue] = useState("");
  const [fetching, SetFetching] = useState(false);

  // store object value from server
  const itemObj = useRef(null);
  const [isInitial, SetIsInitial] = useState(false);

  if (!input.value) {
    itemObj.current = null;
  }

  //   const isLoadOption =
  //     open && (options.length === 0 || inputValue?.length >= 3);

  const loadOptions = useMemo(() => {
    return _.debounce((searchValue, callback) => {
      //       if (searchValue?.length < 3) {
      //         return callback([]);
      //       }

      api
        .get(basePath, {
          params: {
            page_size: pageSize,
            page: 0,
            search: searchValue,
            ...requestParams,
          },
        })
        .then(({ data }) => {
          let items = null;
          if (itemFilter) {
            if (data?.data) {
              items = data?.data;
            } else {
              items = data.rows.filter(itemFilter) || data;
            }
          } else {
            if (data?.data) {
              items = data?.data;
            } else {
              items = data?.rows || data;
            }
          }
          callback(items.map(itemFormat));
        })
        .catch(() => {
          callback([]);
        })
        .finally(() => {
          SetFetching(false);
        });
    }, 800);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, pageSize]);

  useEffect(() => {
    let active = true;
    if (inputValue?.length < 3 || inputValue === itemObj.current?.label) {
      return undefined;
    }

    SetFetching(true);

    //debounce callback
    const dbCallback = (options) => {
      if (active) {
        setOptions([...options]);
      }
    };

    loadOptions(inputValue, dbCallback);

    return () => {
      active = false;
    };
  }, [basePath, inputValue, loadOptions]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  //for inital value
  useEffect(() => {
    let active = true;

    // if (itemObj.current || fetching) {
    //   return undefined;
    // }

    if (initial && (pristine || reFetch)) {
      if (itemObj.current && itemObj.current.value === initial) {
        return undefined;
      }
      SetFetching(true);
      api
        .get(`${basePath}/${!isInitial ? initial : input.value}`)
        .then(({ data }) => {
          itemObj.current = itemFormat(data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          if (active) {
            SetFetching(false);
            SetIsInitial(true);
          }
        });
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath, initial, pristine, reFetch]);

  // console.log(input.value);
  // console.log(inputValue);

  return (
    <FormControl
    variant="outlined"
    error={(error || submitError) && touched}
    {...controlProp}
  >
    <InputLabel
      id={`${input.name}-select-label`}
      error={(error || submitError) && touched}
    ></InputLabel>
    <Autocomplete
      readOnly={readOnly}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        input.onBlur();
      }}
      options={options}
      loading={fetching}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      value={itemObj.current}
      onChange={(event, value) => {
        itemObj.current = value;
        input.onChange(value);
      }}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label={`${label}${required ? " *" : ""}`}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {fetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              sx: {
                backgroundColor: disabled ? "grey.300" : "inherit", // เปลี่ยนสีพื้นหลัง
                color: disabled ? "grey.600" : "inherit", // เปลี่ยนสีข้อความ
                pointerEvents: disabled ? "none" : "auto", // ปิดการคลิกเมื่อ readOnly
              },
            }}
            error={(error || submitError) && touched}
            placeholder={placeholder}
            disabled={disabled} // ปิดการใช้งานเมื่อ readOnly
          />
          {(error || submitError) && touched && (
            <FormHelperText
              id={`${input.name}-error`}
              variant="outlined"
              color="error"
            >
              {error || submitError}
            </FormHelperText>
          )}
        </>
      )}
      {...inputProp}
    />
  </FormControl>
  
  );
};

FieldComponent.propTypes = {
  basePath: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  itemFormat: PropTypes.func.isRequired,
};

export default FieldComponent;
