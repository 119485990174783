import { Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Grid,
  Modal,
  Paper,
  Snackbar,
  Typography,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import {
  transactionAuditChangePage,
  transactionAuditChangePageSize,
  transactionAuditClearResult,
  importAdminLogFetch,
  transactionAuditForceReload,
  transactionAuditSetFilter,
} from "../../../../ducks/auditList";
import TransactionLogSearchForm from "./importAdminLogSearchForm";
import { fi } from "date-fns/locale";

export default function ImportAdminLog() {
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(transactionAuditClearResult());
      dispatch(transactionAuditSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const fetchIdRef = React.useRef(0);
  const rows = useSelector(
    (state) => state?.auditList?.transaction_audit?.data
  );
  const total = useSelector(
    (state) => state?.auditList?.metaTransactionAudit?.total
  );
  const loading = useSelector(
    (state) => state?.auditList?.metaTransactionAudit?.loading
  );
  const forceReload = useSelector(
    (state) => state?.auditList?.metaTransactionAudit?.forceReload
  );

  const filter = useSelector(
    (state) => state?.auditList?.metaTransactionAudit?.filter
  );

  // const [rowsState, setRowsState] = React.useState({
  //   page: 0,
  //   pageSize: 5,
  // });

  useEffect(() => {
    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;
    //filter overide by url status
    const activeFilter = reqStatus ? { ...filter, status: reqStatus } : filter;
    // Only update the data if this is the latest fetch
    dispatch(
      importAdminLogFetch(pageSize, page, activeFilter, fetchId, fetchIdRef)
    ).catch((e) => {
      SetToastInfo({ type: "error", message: "เกิดข้อผิดพลาด" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, forceReload, filter]);

  // add modal state
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpenModal = (page) => setOpenModal(page);
  const handleCloseModal = useCallback(
    (reason) => {
      if (reason) {
        if (reason.type === "error") {
          SetToastInfo(reason);
        } else {
          dispatch(transactionAuditForceReload());
        }
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 70,
    },
    {
      field: "creator",
      headerName: "นำเข้าโดย",
      sortable: true,
      width: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.creator?.fname} {params.row.creator?.lname}
          </div>
        );
      },
    },
    {
      field: "file_path",
      headerName: "file_path",
      sortable: true,
      width: 300,
      renderCell: (params) => {
        return (
          <Tooltip
            placement="top"
            arrow
            title={JSON.stringify(params?.row?.file_path)}
          >
            <div>{JSON.stringify(params?.row?.file_path)}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "response",
      headerName: "response",
      sortable: true,
      width: 250,
      renderCell: (params) => {
        return (
          <Tooltip
            placement="top"
            arrow
            title={JSON.stringify(params?.row?.response)}
          >
            <div>{JSON.stringify(params?.row?.response)}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "created_at",
      headerName: "วันที่สร้าง",
      sortable: true,
      width: 200,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    dispatch(transactionAuditSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>
      <Grid item xs={12}>
        <Typography variant="h5">Importadminlog</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 800,
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={TransactionLogSearchForm}
            loading={loading}
            onReset={() => dispatch(transactionAuditSetFilter({}))}
            showStatusField={reqStatus ? false : true}
            rows={rows}
            filter={filter}
            initialValues={{}}
          />
          <DataGrid
            // density="compact"
            disableColumnMenu
            columns={columns}
            rows={rows ?? []}
            rowCount={total}
            loading={loading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page - 1}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(pageSize) => {
              console.log({ pageSize });
              setPageSize(pageSize);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}

function CustomToolbar() {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.AuditList?.metaTransactionAudit?.loading
  );

  return (
    <GridToolbarContainer>
      <LoadingButton
        size="small"
        variant="outlined"
        color="info"
        loading={loading}
        onClick={() => dispatch(transactionAuditForceReload())}
        startIcon={<Refresh />}
      >
        Reload
      </LoadingButton>
    </GridToolbarContainer>
  );
}
