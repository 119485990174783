import { Check, CreditCard, FileCopy } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import { useState } from "react";
import { Form } from "react-final-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import api from "../../../../apis";
import { getTextMask } from "../../../../helpers/imask";
import validationMsg from "../../../../helpers/validationMsg";
import FieldInput from "../../../form-fields/FieldInput";
import SubmitBtn from "../../../SubmitBtn";

const pidMask = getTextMask({
  mask: "0000000000000",
  unmask: true,
  overwrite: true,
});

export default function Hr03RequestAccountActivePidSearch({
  open,
  handleClose,
}) {
  const navigate = useNavigate();
  const [dataBlack, setDataBlack] = useState({ data: [], total: 0 });
  const [dataDark, setDataDark] = useState({ data: [], total: 0 });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageDark, setPageDark] = useState(1);
  const [pageSizeDark, setPageSizeDark] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isDidRequest, setsDidRequest] = useState(false);

  const [anchorElDowload, setAnchorElDowload] = useState(null);
  const [menu, setMenu] = useState([]);
  const openDowload = Boolean(anchorElDowload);
  const handleCloseDownload = () => {
    setAnchorElDowload(null);
    setMenu([]);
  };
  const handleClickDowload = (event, params) => {
    setMenu(params);
    setAnchorElDowload(event.currentTarget);
  };
  const menuDowload = (
    <Menu
      id="basic-menu"
      anchorEl={anchorElDowload}
      open={openDowload}
      onClose={handleCloseDownload}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {menu?.row?.open_bank_account_type.map((el) => (
        <MenuItem
          onClick={async () => {
            onAddHandler(
              el.create_form_type,
              el.request_type,
              menu?.row?.id,
              menu?.row?.type_account
            );
          }}
        >
          {el.name}
        </MenuItem>
      ))}
    </Menu>
  );
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isDidRequest ? "80%" : 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "100vh",
    overflow: "auto",
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/openbankaccount/hr03_list", {
        params: {
          card_id: formData.pid,
          page: page,
          page_size: pageSize,
        },
      });

      setDataBlack({
        ...res.data,
        ...{
          data: res.data.data.map((el) => {
            return { ...el, type_account: "black" };
          }),
        },
      });
      const resGrayAccount = await api.get(
        "/api/openbankaccount/gray_account",
        {
          params: {
            card_id: formData.pid,
            page: pageDark,
            page_size: pageSizeDark,
          },
        }
      );
      setDataDark({
        ...resGrayAccount.data,
        ...{
          data: resGrayAccount.data.data.map((el) => {
            return { ...el, type_account: "dark" };
          }),
        },
      });
    } catch ({ response: errorRes }) {
      // return error msg if not found
      if (errorRes?.status === 404) {
        return {
          [FORM_ERROR]: "ไม่พบข้อมูล",
          case_id: "โปรดตรวจสอบข้อมูล",
          pid: "โปรดตรวจสอบข้อมูล",
        };
      }

      return { [FORM_ERROR]: "เกิดข้อผิดพลาด" };
    } finally {
      setIsLoading(false);
      setsDidRequest(true);
      setPage(1);
      setPageSize(10);
      setPageDark(1);
      setPageSizeDark(10);
    }
  };

  const onAddHandler = async (
    create_form_type,
    type_request,
    id,
    type_account
  ) => {
    setIsLoading(true);
    try {
      const res = await api.get("/api/openbankaccount/create", {
        params: {
          request_type: create_form_type,
          type: type_request,
          hr03_id: id,
        },
      });
      navigate(`/hr03/request-account-active/add`, {
        state: {
          ...res.data,
          create_form_type: create_form_type,
          req_type_request: type_request,
          hr03_id: id,
          type_account: type_account,
          files: {},
          page: "add",
          policestation_org_code: res.data.user.policestation.ORG_CODE,
        },
      });
    } catch ({ response: errorRes }) {
      // return error msg if not found
      if (errorRes?.status > 404 && errorRes?.status < 500) {
        return toast.error(errorRes?.data.message);
      }

      return toast.error("เกิดข้อผิดพลาด");
    } finally {
      setIsLoading(false);
      setsDidRequest(true);
    }
  };

  const columns = [
    {
      field: "actions",
      headerName: "ส่งคำขอเปิด",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Tooltip title="ดูข้อมูล">
            <IconButton
              type="button"
              variant="contained"
              size="small"
              onClick={(e) => handleClickDowload(e, params)}
              color="primary"
            >
              <FileCopy />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 100,
      width: 190,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {params.row.open_bank_account?.status === "new" ? (
                <>
                  <Chip
                    label="พงส."
                    color="warning"
                    size="small"
                    sx={{
                      ml: 1,
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                  <Chip
                    label="กรุณายืนยัน"
                    color="warning"
                    size="small"
                    sx={{
                      ml: 1,
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                </>
              ) : (
                <>
                  <Chip
                    label={
                      params.row.open_bank_account?.status === "sent_to_bk"
                        ? "บก.พิจารณา"
                        : params.row.open_bank_account?.status === "bk_reject"
                        ? "บก.ปฏิเสธ"
                        : params.row.open_bank_account?.status === "sent_to_bh"
                        ? "บช.พิจารณา"
                        : params.row.open_bank_account?.status === "bh_reject"
                        ? "บช.ปฏิเสธ"
                        : params.row.open_bank_account?.status === "bh_approve"
                        ? "ตร.พิจารณา"
                        : params.row.open_bank_account?.status === "rtp_reject"
                        ? "ตร.ปฏิเสธ"
                        : params.row.open_bank_account?.status === "rtp_approve"
                        ? "ปปง.พิจารณา"
                        : params.row.open_bank_account?.status ===
                          "aml_reject_end"
                        ? "ไม่ผ่านข้อยุติพิจารณา"
                        : params.row.open_bank_account?.status === "aml_reject"
                        ? "ไม่ผ่านพิจารณา"
                        : params.row.open_bank_account?.status ===
                          "aml_reject_account"
                        ? "ไม่ผ่าน ดำเนินใหม่รายบัญชี"
                        : params.row.open_bank_account?.status ===
                          "send_to_bank"
                        ? "ผ่านการอนุมัติ"
                        : params.row.open_bank_account?.status ===
                            "aml_approve" ||
                          params.row.open_bank_account?.status === "rtp_approve"
                        ? "ผ่านการพิจารณา"
                        : "ยังไม่มีคำขอ"
                    }
                    color={
                      params.row.open_bank_account?.status === "sent_to_bk" ||
                      params.row.open_bank_account?.status === "sent_to_bh"
                        ? "warning"
                        : params.row.open_bank_account?.status ===
                            "bk_reject" ||
                          params.row.open_bank_account?.status ===
                            "bh_reject" ||
                          params.row.open_bank_account?.status ===
                            "rtp_reject" ||
                          params.row.open_bank_account?.status ===
                            "aml_reject" ||
                          params.row.open_bank_account?.status ===
                            "aml_reject_account"
                        ? "error"
                        : params.row.open_bank_account?.status === "rtp_approve"
                        ? "primary"
                        : params.row.open_bank_account?.status ===
                            "aml_approve" ||
                          params.row.open_bank_account?.status ===
                            "send_to_bank"
                        ? "success"
                        : params.row.open_bank_account?.status ===
                          "aml_reject_end"
                        ? "pink"
                        : params.row.open_bank_account?.status === "bh_approve"
                        ? "violet"
                        : "default" //grey
                    }
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        size: "small",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                </>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "ref_code",
      headerName: "Ref code",
      sortable: true,
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {params.row.hr_list?.ref_code
              ? params.row.hr_list?.ref_code
              : params.row.gray_account?.ref_code
              ? params.row.gray_account?.ref_code
              : "-"}
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 80,
    },

    {
      field: "case_no",
      headerName: "caseId",
      sortable: true,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {`${params.row.tpo_case_id} ${
              params.row.case_no ? `,${params.row.case_no}` : ""
            }`}
          </>
        );
      },
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.card_id ?? "-"}</>;
      },
    },
    {
      field: "full_name",
      headerName: "ชื่อ-สกุล",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.name + " " + params?.row?.surname ?? "-"}</>;
      },
    },

    {
      field: "bankname",
      headerName: "ธนาคาร",
      sortable: true,
      width: 100,
      renderCell: (params) => {
        return <>{params?.row?.bankname ?? "-"}</>;
      },
    },
    {
      field: "accountno",
      headerName: "เลขบัญชี",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.accountno ?? "-"}</>;
      },
    },
    {
      field: "hr03.police_name.pol_name",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.pol_name + " " + params.row.pol_surname ?? "-"}</>;
      },
    },
    {
      field: "ORG_ABBR",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.pol_policestation?.ORG_ABBR ?? "-"}</>;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const onCloseAndReset = () => {
    handleClose();
    setDataBlack({ data: [], total: 0 });
    setDataDark({ data: [], total: 0 });
    setPage(1);
    setPageSize(10);
    setPageDark(1);
    setPageSizeDark(10);
    setsDidRequest(false);
  };
  return (
    <Modal
      id="modalPid"
      open={open}
      onClose={() => {
        onCloseAndReset();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          ตรวจสอบรายการ HR03/ม้าเทา
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="subtitle1"
          component="h2"
          color="primary"
        >
          โปรดระบุเลขบัตรประชาชนเจ้าของบัญชี เพื่อตรวจสอบรายการ
        </Typography>
        <Form validate={validate} onSubmit={onSubmit}>
          {({
            handleSubmit,
            errors,
            error,
            submitError,
            submitting,
            pristine,
            initialValues,
            submitFailed,
            form,
            values,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                noValidate
                style={{ maxWidth: 550, margin: "25px auto" }}
              >
                <Grid container spacing={2} alignItems="center">
                  {submitFailed && (error || submitError) && (
                    <Grid item xs={12}>
                      <Alert severity="warning" sx={{ width: "auto" }}>
                        {error || submitError}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={8}>
                    <FieldInput
                      name="pid"
                      label="ค้นหา เลขบัตรประชาชน"
                      required
                      controlProps={{ fullWidth: true }}
                      inputProps={{
                        inputComponent: pidMask,
                        placeholder: "เลขบัตรประชาชน",
                        startAdornment: (
                          <InputAdornment position="start">
                            <CreditCard />
                          </InputAdornment>
                        ),
                      }}
                      readOnly={submitting}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Stack direction="row" spacing={2}>
                      <SubmitBtn
                        variant="contained"
                        startIcon={<Check />}
                        submitting={submitting}
                        pristine={pristine}
                      >
                        ตกลง
                      </SubmitBtn>
                      <Button
                        type="button"
                        variant="outlined"
                        disabled={submitting}
                        onClick={() => {
                          onCloseAndReset();
                          form.reset();
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
        {isDidRequest && (
          <>
            {menuDowload}
            <Paper
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: 800,
              }}
            >
              <Grid item container sx={{ textAlign: "center", mt: 3, mb: 2 }}>
                <Grid item xs={12}>
                  รายการ HR03{" "}
                  <Typography component={"span"} color="warning.main">
                    (ม้าดำ)
                  </Typography>
                </Grid>
              </Grid>
              <DataGrid
                rowCount={dataBlack.total ?? 0}
                disableColumnMenu
                columns={columns}
                rows={_.get(dataBlack, "data", [])}
                loading={isLoading}
                rowsPerPageOptions={[5, 10, 15, 20]}
                pagination
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={(page) => setPage(page)}
                onPageSizeChange={(pageSize) => {
                  setPageSize(pageSize);
                }}
              />
              <Grid item container sx={{ textAlign: "center", mt: 3, mb: 2 }}>
                <Grid item xs={12}>
                  รายการม้าเทาเข้ม
                </Grid>
              </Grid>
              <DataGrid
                rowCount={dataDark.total ?? 0}
                disableColumnMenu
                columns={columns}
                rows={_.get(dataDark, "data", [])}
                loading={isLoading}
                rowsPerPageOptions={[5, 10, 15, 20]}
                pagination
                paginationMode="server"
                page={pageDark}
                pageSize={pageSizeDark}
                onPageChange={(page) => setPageDark(page)}
                onPageSizeChange={(pageSize) => {
                  setPageSizeDark(pageSize);
                }}
              />
            </Paper>
            <Stack
              direction="row"
              justifyContent={"flex-end"}
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Button variant="outlined" onClick={() => onCloseAndReset()}>
                ปิด
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
}

const validate = (values, props) => {
  const errors = {};
  const schema = Joi.object(validateRules()).error(validationMsg());
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

const validateRules = () => ({
  pid: Joi.string()
    .pattern(/^\d{13}$/)
    .required(),
});
