import { Button, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { getChip } from "../../../data/requestStatus";

import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";

const TableHistoryLog = ({ setHistoryModal, historyLog }) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const masterModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    px: 4,
    pt: 5,
    overflow: "auto", // ให้ Modal เลื่อนได้เมื่อเนื้อหาเยอะ
    maxHeight: "90vh", // จำกัดความสูงสูงสุดของ Modal
    width: "70%", // กำหนดความกว้างของ Modal
    height: "auto", // ให้ Modal ปรับความสูงตามเนื้อหา
    zIndex: 99999,
  };

  const modalStyle = {
    ...masterModalStyle,
    height: "80%", // กำหนดความสูงของ Modal
    width: "70%", // กำหนดความกว้างของ Modal
  };

  const columns = useMemo(() => {
    return [
      { field: "id", headerName: "ID", sortable: false, maxWidth: 70 },
      {
        field: "user.fname",
        headerName: "ชื่อ-นามสกุล",
        sortable: true,
        minWidth: 200,
        maxWidth: 400,
        renderCell: (params) => {
          return (
            <Typography>
              {`${params.row.user.fname} ${params.row.user.lname}`}
            </Typography>
          );
        },
      },
      {
        field: "status",
        headerName: "สถานะ",
        sortable: true,
        renderCell: (params) => {
          return (
            <Typography>
              {getChip(params.row.status, { fontSize: "0.75rem" })}
            </Typography>
          );
        },
      },
      {
        field: "created_at",
        headerName: "วันที่ส่ง",
        sortable: true,
        minWidth: 200,
        maxWidth: 400,
        valueGetter: (params) => {
          if (!params.row?.created_at) {
            return undefined;
          }

          const date = parseISO(params.row?.created_at);

          return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
        },
      },
      {
        field: "user.policestation",
        headerName: "หน่วยงาน",
        sortable: true,
        minWidth: 200,
        maxWidth: 400,
        renderCell: (params) => {
          return (
            <Typography>
              {params.row.user?.policestation?.ORG_ABBR ?? "-"}
            </Typography>
          );
        },
      },
    ];
  }, []);

  return (
    <Box sx={modalStyle}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        ประวัติการส่งอีเมล
      </Typography>
      <Box
        sx={{
          height: "60vh", // กำหนดความสูงของ Box ที่ครอบตาราง
          overflow: "auto", // ให้ Box เลื่อนได้เมื่อข้อมูลมีมาก
        }}
      >
        <DataGrid
          name="bank_accountsQ"
          disableColumnMenu
          columns={columns}
          rows={historyLog}
          // loading={loading}
          rowsPerPageOptions={[5, 10, 20]}
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => setPage(newPage)}
          // pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          autoHeight
          readOnly
          style={{
            minHeight: "500px", // Changed to set a minimum height
            overflow: "auto",
          }}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          right: 20,
          backgroundColor: "background.paper",
          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setHistoryModal(false);
          }}
        >
          ปิด
        </Button>
      </Box>
    </Box>
  );
};

export default TableHistoryLog;
