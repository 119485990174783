import { Delete, UploadFile } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const FieldComponent = ({
  name,
  required = false,
  readOnly = false,
  resizeImage,
  shouldResizeImage = () => true,
  dropZoneProps = {},
  showFileSize = true,
  placeholder,
  accept = "image/jpeg, image/png,application/pdf,application/zip",
  input,
  meta,
  disabled,
  isMultiple = true,
  maxFiles = undefined,
  defaultFiles = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(
    Array.isArray(input.value) ? input.value : defaultFiles || []
  );
  const [error, setError] = useState("");

  useEffect(() => {
    const newFiles = Array.isArray(input.value)
      ? input.value
      : defaultFiles || [];
    if (JSON.stringify(files) !== JSON.stringify(newFiles)) {
      setFiles(newFiles);
    }
  }, [input.value, defaultFiles, files]);

  const onDrop = async (dropFiles) => {
    input.onBlur();

    // Check if adding new files would exceed maxFiles
    if (maxFiles && files.length + dropFiles.length > maxFiles) {
      setError(
        `ไม่สามารถอัพโหลดได้ เนื่องจากจำนวนไฟล์เกินกำหนด (สูงสุด ${maxFiles} ไฟล์)`
      );
      return;
    }

    if (dropFiles.length > 0) {
      setLoading(true);
      setError(""); // Clear any previous errors

      const processedFiles = await Promise.all(
        dropFiles.map(async (file) => {
          if (!file) return null;
          if (
            resizeImage &&
            file.type?.startsWith("image/") &&
            shouldResizeImage(file)
          ) {
            try {
              return await resizeImage(file);
            } catch (e) {
              console.error(e);
              return null;
            }
          }
          return file;
        })
      );

      const validFiles = processedFiles.filter(Boolean);
      const newFiles = isMultiple ? [...files, ...validFiles] : validFiles;

      setFiles(newFiles);
      input.onChange(newFiles);
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: maxFiles ? maxFiles - files.length : undefined,
    accept,
    multiple: isMultiple,
    ...dropZoneProps,
    disabled: loading || disabled || (maxFiles && files.length >= maxFiles),
  });

  const removeFile = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    input.onChange(updatedFiles.length > 0 ? updatedFiles : []);
    setFiles(updatedFiles);
    input.onBlur(); // ตั้งค่า touched เป็น true
    setError("");
  };

  const calcFileSizeMB = (size) =>
    size > 0 ? (size / 1048576).toFixed(2) : null;

  const preview = useMemo(() => {
    if (loading) {
      return (
        <Card sx={{ maxWidth: 345 }}>
          <CardActionArea>
            <Box sx={{ textAlign: "center", p: 2 }}>
              <CircularProgress />
            </Box>
            <CardContent>
              <Typography
                variant="subtitle2"
                component="div"
                textAlign="center"
                noWrap
              >
                Loading...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }

    if (!Array.isArray(files)) {
      return null; // or return a placeholder/message
    }

    return files.map((file, index) => (
      <Grid container spacing={1} alignItems="center" key={index}>
        <Grid item xs={1}>
          {file.type?.startsWith("image/") ? (
            <CardMedia
              component="img"
              image={URL.createObjectURL(file)}
              alt="upload file"
            />
          ) : (
            <Box sx={{ textAlign: "center" }}>
              <UploadFile sx={{ fontSize: 25 }} color="disabled" />
            </Box>
          )}
        </Grid>
        <Grid item xs={10}>
          <Typography variant="subtitle2" component="div" noWrap>
            {file.name}
          </Typography>
          {showFileSize && (
            <Typography variant="caption" component="div" noWrap color="grey">
              {calcFileSizeMB(file.size ?? 0)} MB
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            size="medium"
            color="error"
            onClick={() => removeFile(index)}
          >
            <Delete fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    ));
  }, [files, loading, showFileSize]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div
            {...getRootProps({
              className: `!block h-full content-center self-center dropzone ${
                maxFiles && files.length >= maxFiles ? "opacity-50" : ""
              } ${
                meta.invalid && meta.touched
                  ? "!bg-red-50 !text-[#d32f2f] !border-[#d32f2f]"
                  : ""
              }`,
            })}
          >
            <input {...getInputProps()} />
            <div
              className="text-wrap"
              style={{
                width: "100%",
                textAlign: "center",
                wordBreak: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
              }}
            >
              {maxFiles && files.length >= maxFiles
                ? `ไม่สามารถอัพโหลดได้ เนื่องจากมีไฟล์ครบ ${maxFiles} ไฟล์แล้ว`
                : placeholder ||
                  "Drag 'n' drop some files here, or click to select files"}
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography
                variant="subtitle2"
                component="div"
                color="primary.main"
                noWrap
              >
                Files ({files.length}
                {maxFiles ? `/${maxFiles}` : ""})
              </Typography>
            </Grid>
            {/* <Grid item xs={6} justifyItems="flex-end" textAlign="right">
              <Typography
                variant="subtitle2"
                component="button"
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  setFiles([]);
                  input.onChange([]);
                  setError("");
                }}
              >
                ล้างไฟล์
              </Typography>
            </Grid> */}
          </Grid>
          {error && (
            <Typography
              variant="caption"
              color="error"
              sx={{ display: "block", mb: 1 }}
            >
              {error}
            </Typography>
          )}
          {preview}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {meta.invalid && meta.touched && (
            <Typography variant="caption" color="error.main">
              {meta.error || meta.submitError}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default FieldComponent;
