import { Save, SystemUpdateAlt } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { format, startOfToday } from "date-fns";
import { fi } from "date-fns/locale";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import api from "../../../../../apis";
import countryOptions from "../../../../../data/selects/countries";
import { defaultJoiDates } from "../../../../../helpers/dateHelper";
import { getTextMask } from "../../../../../helpers/imask";
import thaiIdValidator from "../../../../../helpers/ThaiIdValidator";
import FieldAsyncAutoComplete from "../../../../form-fields/FieldAsyncAutoComplete";
import FieldAsyncSelect from "../../../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../../../form-fields/FieldAutoComplete";
import FieldDatePicker from "../../../../form-fields/FieldDatePicker";
import FieldInput from "../../../../form-fields/FieldInput";
import FieldMultiFileDropZone from "../../../../form-fields/FieldMultiFileDropZone";
import FieldSelect from "../../../../form-fields/FieldSelect";
import FieldSingleFileDropZone from "../../../../form-fields/FieldSingleFileDropZone";
import SubmitBtn from "../../../../SubmitBtn";
import SignaturePreview from "../../../requests/request-forms/SignaturePreview";
import TableOtherFiles from "../component/TableOtherFiles";

// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";

const TextMaskCustom = getTextMask({
  mask: "00000000000000",
  unmask: true,
  overwrite: true,
});

export default function Hr03RequestAccountActiveForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
  page,
  id,
  idFromHr03,
  isDuplicate = false,
}) {
  // const request_account_HR03 = useSelector(
  //   (state) => state.auth.user.permissions.request_account_HR03 ?? []
  // );
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const navigate = useNavigate();
  const [isErrorDowload, setIsErrorDowLoad] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [titleError, setTitleError] = useState(
    "ไม่สามารถดาวน์โหลดไฟล์ได้ เนื่องจากไฟล์ไม่มีอยู่ในระบบ"
  );

  const [type1, setType1] = useState([]);
  const [type2, setType2] = useState([]);
  const [typeCurrent, setTypeCurrent] = useState([]);
  const [receiveDataOtherFile, setReceiveDataOtherFile] = useState();

  useEffect(() => {
    if (submitFailed && !modifiedSinceLastSubmit && (error || submitError)) {
      setOpen(true);
    }
  }, [submitFailed, error, submitError, modifiedSinceLastSubmit]);

  useEffect(() => {
    if (values.req_type_request && page === "add") {
      values.type_account === "dark" &&
        form.change("hr_list.card_id", values.card_id);
      form.change("type_request", values.create_form_type);
      form.change("hr03_fullName", `${values.name} ${values.surname}`);
      form.change("fullName", `${values.name} ${values.surname}`);
      form.change("type", values.req_type_request);
      form.change(
        "user.profile_signature",
        values.user.profile_signature ? values.user.profile_signature : ""
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.req_type_request]);

  useEffect(() => {
    form.change("receiveDataOtherFile", receiveDataOtherFile);
  }, [receiveDataOtherFile]);

  useEffect(() => {
    const isHaveAllType =
      Object.keys(type1).length > 0 && Object.keys(type2).length > 0;
    if (!isHaveAllType && values.type) {
      refetchDataRequestsFiletemplate(values.type);
    } else {
      setTypeCurrent(values.type === 1 ? type1 : type2);
    }
  }, [values.type]);

  useEffect(() => {
    if (page === "add" && !isDuplicate && Object.keys(typeCurrent).length > 0) {
      form.change("attachments", typeCurrent);
    }
  }, [typeCurrent]);
  const { data: requestTypeOptions } = useQuery(
    [page === "add" && values.type_account, page === "edit" && values.hr_type],

    () =>
      (values.type_account || values.hr_type) &&
      api
        .get("/api/openbankaccount/type_request", {
          params: {
            type_request:
              page === "add" && values.type_account === "black"
                ? 1
                : page === "add" && values.type_account === "dark"
                ? 2
                : values.hr_type,
            hr03_id: values.hr03_id,
          },
        })
        .then((res) =>
          res.data.map((row) => ({
            value: row.create_form_type,
            type: row.request_type,
            label: row.name,
          }))
        ),

    { staleTime: 60 * 1000 }
  );

  const { data: declareReasonOptions } = useQuery(
    ["declareReasonOptions"],
    () =>
      api.get("/api/openbankaccount/requests/description/bank").then((res) =>
        res.data.map((row) => ({
          value: row.id,
          is_declare_note: row.is_declare_note,
          label: row.name,
        }))
      ),

    { staleTime: 60 * 1000 }
  );

  const groupAttachments = useMemo(() => {
    return values?.template_attachment?.map((template) => {
      // Find all matching attachments for this template
      const matchingAttachments = values?.attachments?.filter(
        (attachment) =>
          attachment.template_file_id ===
          (!isDuplicate
            ? template.id
            : template.ref_template_id
            ? template.ref_template_id
            : ""
          ).toString()
      );
      // Return combined object
      return {
        ...template,
        attachments: matchingAttachments,
      };
    });
  }, [values]);

  const downloadFile = useCallback(async (fileUrl) => {
    setIsLoadingDownload(true);
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });
      // ดึงนามสกุลไฟล์จากชื่อไฟล์
      let fileName = (
        res?.headers["content-disposition"] ?? "filename=File"
      ).split("filename=")[1];
      fileName = fileName.at(-1) === '"' ? fileName.slice(1, -1) : fileName;
      let fullFileName = "";
      const contentType = res.headers["content-type"];
      if (contentType.includes("text/csv")) {
        fullFileName = `${fileName}.csv`; // Set the desired file name for the download
      } else if (contentType.includes("application/zip")) {
        fullFileName = `${fileName}.zip`; // Set the desired file name for the download
      } else if (contentType.includes("text/plain")) {
        fullFileName = `${fileName}.txt`; // Set the desired file name for the download
      } else {
        fullFileName = `${fileName}.csv`;
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));

      // สร้าง element <a> สำหรับดาวน์โหลดไฟล์
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fullFileName); // กำหนดชื่อไฟล์ + นามสกุล

      document.body.appendChild(link);

      link.click();

      // ล้างหน่วยความจำ

      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    } finally {
      setIsLoadingDownload(false);
    }
  }, []);

  const refetchDataRequestsFiletemplate = (type) =>
    api
      .get("/api/openbankaccount/requests/filetemplate", {
        params: { type: type },
      })
      .then((res) => {
        type === 1 ? setType1(res?.data) : setType2(res?.data);
        setTypeCurrent(res?.data);
      });
  const {
    isLoading: isBankOptionsLoading,
    data: bankOptions,
    error: bankOptionsError,
  } = useQuery(
    [
      "bankSelect",
      "/api/banks/list/bank",
      { value: "code", label: "fullname" },
    ],
    () =>
      api.get("/api/banks/list/bank").then((res) =>
        res?.data?.map((row) => ({
          value: row.code,
          label: `${row.name} (${row.short_name})`,
        }))
      ),
    { staleTime: 3 * 60 * 1000 }
  );

  useEffect(() => {
    const type =
      page === "add" && requestTypeOptions && requestTypeOptions.length > 0
        ? requestTypeOptions.filter(
            (el) => el?.value === values.type_request
          )[0].type
        : undefined;
    type && form.change("type", type);
  }, [values.type_request]);

  useEffect(() => {
    const is_declare_note =
      declareReasonOptions && declareReasonOptions.length > 0
        ? declareReasonOptions.filter(
            (el) => el?.value === values.discription_bank
          )[0]?.is_declare_note
        : undefined;
    is_declare_note !== undefined &&
      form.change("is_declare_note", is_declare_note);
  }, [values.discription_bank]);

  useEffect(() => {
    values && !values.files && form.change("files", {});
    values && !values.user && form.change("user", {});
  }, [values]);

  function getFileExtensions(mimeTypesString) {
    return mimeTypesString
      ?.split(",") // แยกด้วยจุลภาค
      ?.map((mimeType) => {
        // ลบช่องว่างและดึงส่วนสุดท้ายของ MIME type
        const type = mimeType.trim().split("/").pop();
        return type === "jpeg" ? "jpg" : type; // แปลง jpeg เป็น jpg
      })
      ?.join(", "); // รวมผลลัพธ์เป็นสตริงคั่นด้วยจุลภาค
  }

  const getFileAttachmentsList = (item) =>
    item &&
    item.map((el) => (
      <Grid item xs={12} md={6}>
        <Typography variant="subtitle1">
          {el.title}{" "}
          {el.is_required ? (
            <span
              style={{
                color: "red",
              }}
            >
              *
            </span>
          ) : (
            ""
          )}
          {el.url && (
            <Link target="_blank" href={el.url} color="inherit" mx={2}>
              ตัวอย่างไฟล์
            </Link>
          )}
        </Typography>
        <FieldMultiFileDropZone
          name={"files.file" + el.id}
          accept={el?.file_type}
          type="file"
          required={el.required ? true : false}
          isMultiple={el.is_multiple}
          maxFiles={el.is_multiple ? el.max_upload : 1}
          placeholder={
            <>
              ลากและวางไฟล์บางไฟล์ที่นี่หรือคลิกเพื่อเลือกไฟล์ <br />
              (*.{getFileExtensions(el?.file_type)}) only ({el.max_size_file}{" "}
              MB)
            </>
          }
          defaultFiles={
            page === "edit" || isDuplicate
              ? el.attachments?.map((att) => ({
                  name: att.file_name,
                  url: att.url_download, // ใช้ URL ดาวน์โหลด
                }))
              : undefined
          }
        />
        {el.discription && (
          <Typography variant="p" sx={{ fontSize: 14 }} color="error.main">
            {el.discription}
          </Typography>
        )}
      </Grid>
    ));

  return (
    <>
      {toastInfo?.type && (
        <Alert
          onClose={handleToastInfoClose}
          severity={toastInfo?.type || "warning"}
          sx={{ width: "100%" }}
        >
          {toastInfo?.message || ""}
        </Alert>
      )}

      <Dialog
        open={isErrorDowload}
        onClose={() => setIsErrorDowLoad(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="warning.main">
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {titleError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsErrorDowLoad(false);
            }}
          >
            ปิด
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit} noValidate>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" color="warning.main">
            Warning
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error || submitError}
            </DialogContentText>
          </DialogContent>
          <DialogActions key="dialog_actions_hr03_request_account_active_form">
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              ปิด
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={3}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }} textAlign="center">
              ข้อมูลดั้งเดิม
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldSelect
              name={page === "add" ? "type_request" : "request_type"}
              label="ประเภทคำร้องขอเปิดใช้งาน"
              required
              controlProp={{ fullWidth: true }}
              options={requestTypeOptions}
              readOnly={page === "edit" || isDuplicate}
              disabled={page === "edit" || isDuplicate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={
                values.type_account === "black" && page === "add"
                  ? "hr_list.ref_code"
                  : values.type_account === "dark" && page === "add"
                  ? "gray_account.ref_code"
                  : "hr03_number"
              }
              label="Ref code"
              required
              controlProps={{ fullWidth: true }}
              readOnly
              disabled
              inputProps={{
                placeholder: "กรุณากรอก Ref code",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "hr_list.card_id" : "hr03.card_id"}
              label={"เลขบัตรประชาชน"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "hr03_fullName" : "fullName"}
              label={"ชื่อ-สกุล"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกชื่อ-สกุล",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name={page === "add" ? "bankcode" : "hr03.bankcode"}
              label="ธนาคาร"
              required
              controlProp={{ fullWidth: true }}
              inputProps={{
                sx: { width: "100%" },
                loading: isBankOptionsLoading || bankOptionsError,
                loadingText: bankOptionsError ? "Error" : "Loading…",
              }}
              options={bankOptions}
              readOnly={true}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "accountno" : "hr03.accountno"}
              label={"หมายเลขบัญชี"}
              required
              controlProps={{ fullWidth: true }}
              readOnly={true}
              disabled
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน",
              }}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              ข้อมูล{" "}
              <Typography
                sx={{ fontSize: 20, fontWeight: 500 }}
                component={"span"}
                color="warning.main"
              >
                {values.type === 1 ? "ขอถอนรายชื่อ" : "ขอเปิดรายบัญชี"}
              </Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <FieldAsyncAutoComplete
              name={page === "add" ? "policestation_org_code" : "request_org"}
              label="จากหน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly={readOnly}
              disabled={readOnly}
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
              inputProps={{ sx: { width: "100%" } }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name="card_id"
              label={"เลขบัตรประชาชน/เลขพาสปอร์ต"}
              required
              controlProps={{ fullWidth: true }}
              inputProps={{
                placeholder: "กรุณากรอกเลขบัตรประชาชน/เลขพาสปอร์ต",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "fullName" : "full_name"}
              label={"ชื่อ-สกุล"}
              required
              controlProps={{ fullWidth: true }}
              inputProps={{
                placeholder: "กรุณากรอกชื่อ-สกุล",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAutoComplete
              name="nationality"
              label="สัญชาติ/ประเทศ"
              required
              options={countryOptions}
              controlProp={{
                sx: {
                  width: "100%",
                },
              }}
              disabled={readOnly}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldDatePicker
              name="birth_date"
              label="วันเกิด"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              readOnly={readOnly}
              disabled={readOnly}
              storeFormat="yyyy-MM-dd"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "no" : "book_no"}
              label="เลขหนังสือ"
              required
              controlProps={{ fullWidth: true }}
              readOnly={readOnly}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">ตช.</InputAdornment>
                ),
                placeholder: "ตัวอย่างเช่น 0039.12/123",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldDatePicker
              name={page === "add" ? "date" : "request_date"}
              label="ลงวันที่"
              required
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
              readOnly={readOnly}
              storeFormat="yyyy-MM-dd"
            />
          </Grid>

          {values.type === 2 && (
            <>
              <Grid item xs={12}>
                <FieldAsyncSelect
                  name={"bank_id"}
                  label="ธนาคาร"
                  controlProp={{
                    sx: {
                      width: "100%",
                    },
                  }}
                  basePath={`/api/banks`}
                  itemFormat={(row) => {
                    return {
                      value: row.id,
                      label: `${row.name} (${row.short_name})`,
                    };
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name={"bank_number"}
                  label="หมายเลขบัญชีที่ต้องการใช้งาน"
                  required
                  controlProps={{ fullWidth: true }}
                  readOnly={readOnly}
                  inputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldInput
                  name="bank_name"
                  label="ชื่อบัญชีที่ต้องการขอเปิด"
                  required
                  controlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder: "ข้อมูลเท่าที่ทราบ",
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FieldSelect
              name="discription_bank"
              label="เหตุผลขอเปิดใช้บัญชีนี้"
              required
              controlProp={{ fullWidth: true }}
              options={declareReasonOptions}
            />
          </Grid>
          {values.is_declare_note === 1 && (
            <Grid item xs={12}>
              <FieldInput
                name="declare_note"
                label="เหตุผลเพิ่มเติม"
                controlProps={{ fullWidth: true }}
                inputProps={{ multiline: true, maxRows: 6, minRows: 3 }}
                readOnly={readOnly}
                disabled={readOnly}
              />
            </Grid>
          )}

          <Grid item xs={12} mt={3}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }} textAlign="center">
              File Upload ประกอบขอ
              {values.type === 1 ? "ถอนรายชื่อ" : "เปิดรายบัญชี"}
              {values.dowload_zip && (
                <Tooltip title="ดาวน์โหลดเอกสาร zip">
                  <Button
                    type="button"
                    onClick={() => downloadFile(values.dowload_zip)}
                    disabled={isLoadingDownload}
                    color="primary"
                    sx={{ fontSize: 20, fontWeight: 500, ml: 1 }}
                  >
                    <SystemUpdateAlt /> ZIP
                  </Button>
                </Tooltip>
              )}
            </Divider>
          </Grid>

          {getFileAttachmentsList(
            page === "add" && !isDuplicate ? typeCurrent : groupAttachments
          )}

          <Grid item xs={12}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              เอกสารอื่นๆ
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TableOtherFiles
              typeCurrent={typeCurrent}
              receiveData={setReceiveDataOtherFile}
              values={page === "add" ? [] : values}
              type_request={values?.type_request}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider
              sx={{ fontSize: 20, fontWeight: 500, mt: 2 }}
              textAlign="center"
            >
              ข้อมูลพนักงานสอบสวน
            </Divider>
          </Grid>
          <Grid item xs={12} md={2}>
            <FieldInput
              name={page === "add" ? "user.rank" : "rank"}
              label="ยศ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name={page === "add" ? "user.fname" : "fname"}
              label="ชื่อ"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <FieldInput
              name={page === "add" ? "user.lname" : "lname"}
              label="สกุล"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "user.job_title" : "job_title"}
              label="ตำแหน่ง"
              controlProps={{ fullWidth: true }}
              required
              readOnly
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldAsyncAutoComplete
              name={page === "add" ? "user.policestation.ORG_CODE" : "org_code"}
              label="หน่วยงาน"
              required
              controlProp={{ fullWidth: true }}
              readOnly={readOnly}
              basePath="/api/policestations"
              requestParams={{ page_size: undefined, page: undefined }}
              // itemFilter={(row) =>
              //   !row.ORG_CODE?.startsWith("0") && !row.ORG_CODE?.startsWith("1")
              // }
              itemFormat={(row) => {
                return { value: row.ORG_CODE, label: row.ORG_ABBR };
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FieldInput
              name={page === "add" ? "user.pol_tel" : "phone_number"}
              label="โทรศัพท์"
              required
              controlProps={{ fullWidth: true }}
              readOnly
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="subtitle1">ลายเซ็น *</Typography>
            {readOnly || page === "edit" ? (
              <SignaturePreview
                name={
                  page === "add" && !isDuplicate
                    ? "user.profile_signature"
                    : "pol_signature"
                }
              />
            ) : (
              <FieldSingleFileDropZone
                name={
                  page === "add" && !isDuplicate
                    ? "user.profile_signature"
                    : "pol_signature"
                }
                accept="image/jpeg, image/png"
                placeholder={
                  <>
                    Drag 'n' drop some files here, or click to select files{" "}
                    <br />
                    (*.jpg,*.gif,*.png) only
                  </>
                }
                isStack
              />
            )}
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Divider sx={{ fontSize: 20, fontWeight: 500 }}></Divider>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <SubmitBtn
                variant="contained"
                startIcon={<Save />}
                submitting={submitting}
                // pristine={pristine}
              >
                บันทึก
              </SubmitBtn>
              <Button
                variant="outlined"
                disabled={submitting}
                onClick={() => navigate("/hr03/request-account-active")}
              >
                ยกเลิก
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export const validate = (values, props) => {
  const errors = {};
  const vResult = schema(values).validate(values, {
    abortEarly: false,
    allowUnknown: true,
  });
  if (vResult.error) {
    let details = vResult.error.details;
    // console.log({details})
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }
  return errors;
};

export const validateRules = (values) => ({
  ...(values.page === "add"
    ? {
        type_request: Joi.alternatives()
          .try(Joi.number(), Joi.string(), Joi.array())
          .required()
          .messages({
            "any.required": "กรุณาระบุประเภทคำร้องขอเปิดใช้งาน",
          }),
        policestation_org_code: Joi.string().required().messages({
          "string.empty": "กรุณาระบุหน่วยงาน",
          "any.required": "กรุณาระบุหน่วยงาน",
        }),
        hr03_id: Joi.alternatives().try(Joi.number(), Joi.string()).required(),
        fullName: Joi.string()
          .messages({
            "string.empty": "กรุณากรอกชื่อ-สกุล",
            "any.required": "กรุณากรอกชื่อ-สกุล",
          })
          .required(),
        card_id: Joi.string().custom(thaiIdValidator).required().messages({
          "any.required": "กรุณาระบุหมายเลขบัตรประชาชน",
        }),
        date: Joi.date()
          .min(defaultJoiDates.minDate)
          .max(defaultJoiDates.forceUTC(startOfToday()))
          .messages({
            "string.empty": "กรุณาระบุวันที่",
            "any.required": "กรุณาระบุวันที่",
            "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.minDate,
              "dd/MM/yyyy"
            )}`,
            "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.forceUTC(startOfToday()),
              "dd/MM/yyyy"
            )}`,
          })
          .required()
          .messages({
            "any.required": "กรุณาเลือกวันที่",
          }),
        no: Joi.string().required().messages({
          "any.required": "กรุณาระบุหมายเลขหนังสือ",
        }),
        bank_id: Joi.when("type", {
          is: Joi.valid(2).required(),
          then: Joi.number().required().messages({
            "any.required": "กรุณาระบุธนาคาร",
          }),
          otherwise: Joi.number().optional(),
        }),
        bank_number: Joi.when("type", {
          is: Joi.valid(2).required(),
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุหมายเลขบัญชี",
            "string.empty": "กรุณาระบุหมายเลขบัญชี",
          }),
          otherwise: Joi.string().optional(),
        }),
        bank_name: Joi.when("type", {
          is: Joi.valid(2).required(),
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุชื่อบัญชี",
            "string.empty": "กรุณาระบุชื่อบัญชี",
          }),
          otherwise: Joi.string().optional(),
        }),
      }
    : {
        org_code: Joi.string().required().messages({
          "string.empty": "กรุณาระบุหน่วยงาน",
          "any.required": "กรุณาระบุหน่วยงาน",
        }),
        book_no: Joi.string().required().messages({
          "string.empty": "กรุณาระบุเลขหนังสือ",
          "any.required": "กรุณาระบุเลขหนังสือ",
        }),
        request_date: Joi.date()
          .min(defaultJoiDates.minDate)
          .max(defaultJoiDates.forceUTC(startOfToday()))
          .messages({
            "string.empty": "กรุณาระบุวันที่",
            "any.required": "กรุณาระบุวันที่",
            "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.minDate,
              "dd/MM/yyyy"
            )}`,
            "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.forceUTC(startOfToday()),
              "dd/MM/yyyy"
            )}`,
          })
          .required()
          .messages({
            "any.required": "กรุณาเลือกวันที่",
          }),
        bank_number: Joi.when("type", {
          is: 2,
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุหมายเลขบัญชี",
            "string.empty": "กรุณาระบุหมายเลขบัญชี",
          }),
          otherwise: Joi.optional(),
        }),
        bank_name: Joi.when("type", {
          is: 2,
          then: Joi.string().required().messages({
            "any.required": "กรุณาระบุชื่อบัญชี",
            "string.empty": "กรุณาระบุชื่อบัญชี",
          }),
          otherwise: Joi.optional(),
        }),
        full_name: Joi.string().required().messages({
          "string.empty": "กรุณากรอกชื่อ-สกุล",
          "any.required": "กรุณากรอกชื่อ-สกุล",
        }),
      }),
  discription_bank: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .required()
    .messages({
      "any.required": "กรุณาระบุเหตุผลขอเปิดใช้บัญชีนี้",
      "string.empty": "กรุณาระบุเหตุผลขอเปิดใช้บัญชีนี้",
    }),

  declare_note: Joi.when("is_declare_note", {
    is: 1,
    then: Joi.string().required().messages({
      "any.required": "กรุณาระบุเหตุผล",
      "string.empty": "กรุณาระบุเหตุผล",
      "string.base": "กรุณาระบุเหตุผล",
    }),
    otherwise: Joi.optional(),
  }),
  nationality: Joi.alternatives()
    .try(Joi.string(), Joi.number())
    .required()
    .messages({
      "any.required": "กรุณาระบุสัญชาติ",
      "string.empty": "กรุณาระบุสัญชาติ",
    }),
  birth_date: Joi.date()
    .messages({
      "string.pattern.base": "กรุณากรอกวันที่ให้ถูกต้อง เช่น 21/07/1996",
      "any.required": "กรุณาระบุวันเกิด",
    })
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .messages({
      "string.empty": "กรุณาระบุวันที่",
      "any.required": "กรุณาระบุวันที่",
      "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
        defaultJoiDates.minDate,
        "dd/MM/yyyy"
      )}`,
      "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
        defaultJoiDates.forceUTC(startOfToday()),
        "dd/MM/yyyy"
      )}`,
    })
    .required(),
  ...(values.page === "add" && !values.isDuplicate
    ? {
        user: Joi.object({
          profile_signature: Joi.alternatives()
            .try(Joi.object(), Joi.string(), Joi.array())
            .required()
            .messages({
              "any.required": "กรุณาอัพโหลดไฟล์",
              "string.empty": "กรุณาอัพโหลดไฟล์",
            }),
        }),
      }
    : {
        pol_signature: Joi.alternatives()
          .try(Joi.object(), Joi.string(), Joi.array())
          .required()
          .messages({
            "any.required": "กรุณาอัพโหลดไฟล์",
            "string.empty": "กรุณาอัพโหลดไฟล์",
          }),
      }),
  ...(values.attachments &&
  values.attachments.length > 0 &&
  values.page === "add" &&
  !values.isDuplicate
    ? {
        files: Joi.object(
          (values.attachments || []).reduce((acc, el) => {
            acc["file" + el.id] =
              el.is_required === 1
                ? Joi.alternatives()
                    .try(Joi.object(), Joi.string(), Joi.array().min(1)) // ป้องกัน []
                    .required()
                    .messages({
                      "any.required": "กรุณาอัพโหลดไฟล์",
                      "array.min": "ต้องอัพโหลดไฟล์อย่างน้อย 1 ไฟล์",
                    })
                : Joi.array();
            return acc;
          }, {}) || { defaultKey: Joi.any() } // Ensure non-empty object
        ),
      }
    : values.template_attachment &&
      values.template_attachment.length > 0 &&
      (values.page === "edit" || values.isDuplicate)
    ? {
        files: Joi.object(
          (values.template_attachment || []).reduce((acc, el) => {
            acc["file" + el.id] = (
              values.isDuplicate &&
              el.is_required === 1 &&
              !values.attachments.some(
                (it) => parseInt(it.template_file_id) === el.ref_template_id
              )
                ? Joi.alternatives().required()
                : Joi.alternatives()
            )
              .try(Joi.any()) // รับทุกชนิดของข้อมูล
              .custom((value, helpers) => {
                // ตรวจสอบว่าเป็น Array และมี item ที่เป็น File object หรือ URL
                if (
                  el.is_required === 1 &&
                  Array.isArray(value) &&
                  value.length > 0
                ) {
                  const hasFileOrUrl = value.some(
                    (item) =>
                      item.url || item.url_download || item instanceof File // ตรวจสอบว่าเป็น File object หรือไม่
                  );

                  if (hasFileOrUrl) return value; // ถ้ามี File object หรือ URL ให้ยอมรับ
                }

                // ถ้าไม่พบ file หรือ URL ใน array และเป็นไฟล์ที่จำเป็นต้องใส่
                if (
                  el.is_required === 1 &&
                  (!value || (Array.isArray(value) && value.length === 0))
                ) {
                  return helpers.error("any.required"); // ถ้าไม่พบไฟล์จะขึ้น error
                }

                // ถ้าเป็น { path: 'some/path' } หรือไม่ใช่ object ที่ต้องการ
                if (
                  typeof value === "object" &&
                  !value.url &&
                  !value.url_download
                ) {
                  // ข้ามการตรวจสอบ ถ้าไม่เป็นไฟล์หรือ URL
                  return value; // คืนค่า value โดยไม่ทำการ validate
                }

                // ถ้าไม่เป็น object หรือ File ก็ให้ทำงานปกติ
                if (typeof value !== "object" || value instanceof File) {
                  return value; // คืนค่า value ตามปกติ
                }

                // ถ้าค่าไม่ตรงตามที่กำหนดจะมี error
                return helpers.error("any.invalid"); // เพิ่ม error ถ้าไม่ตรงตามเงื่อนไข
              })
              .messages({
                "any.required": "กรุณาอัพโหลดไฟล์",
                "any.invalid": "ไฟล์ไม่ถูกต้อง กรุณาตรวจสอบไฟล์ที่อัพโหลด",
              });

            return acc;
          }, {}) || { defaultKey: Joi.any() } // Ensure non-empty object
        ),
      }
    : {}),
});

// const schema = () => Joi.object(validateRules()).error(validationMsg());

const schema = (values) => Joi.object(validateRules(values));

export const mapToInit = (item) => {
  return item;
};
