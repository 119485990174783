import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

const DashboardSkeleton = () => {
  return (
    <Box sx={{ p: 2, width: "100%", height: "100vh" }}>
      <Grid container spacing={3}>
        {/* กล่องจำนวนคงค้าง ใบงานเร่งด่วน */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              borderRadius: "12px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              backgroundColor: "white",
              height: "100%",
              minHeight: "300px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              <Skeleton width="60%" animation="wave" />
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="90px"
                  animation="wave"
                  sx={{
                    borderRadius: "12px",
                    backgroundColor: "#e0e0e0",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="90px"
                  animation="wave"
                  sx={{
                    borderRadius: "12px",
                    backgroundColor: "#e0e0e0",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* กล่องจำนวนข้อมูลขอเปิดใช้บัญชี */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 2,
              borderRadius: "12px",
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              backgroundColor: "white",
              height: "100%",
              minHeight: "300px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              <Skeleton width="70%" animation="wave" />
            </Typography>

            <Skeleton
              variant="rectangular"
              width="100%"
              height="180px"
              animation="wave"
              sx={{
                borderRadius: "12px",
                backgroundColor: "#e0e0e0",
              }}
            />
          </Box>
        </Grid>

        {/* กล่องการ์ดแนวนอน */}
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 2 }}>
            {[...Array(6)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width="100%"
                height="100px"
                animation="wave"
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#e0e0e0",
                  flex: 1,
                }}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardSkeleton;
