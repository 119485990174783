import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { Field } from "react-final-form";

export default function FieldCheckBox({
  name,
  label,
  required = false,
  readOnly = false,
  controlProps = {},
  inputProps = {},
  inputLabelProps = {},
  onChange,
  labelPlacement,
}) {
  return (
    <Field name={name} type="checkbox">
      {({ input, meta }) => (
        <FormGroup>
          <FormControlLabel
            disabled={readOnly}
            control={<Checkbox {...inputProps} {...input} />}
            label={label}
            onChange={onChange}
            labelPlacement={labelPlacement}
            {...inputLabelProps}
          />
        </FormGroup>
      )}
    </Field>
  );
}
