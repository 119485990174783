import { Refresh, RemoveRedEye } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Chip,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  Snackbar,
  Alert,
  Modal,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _, { min } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../../apis";
import { usersForceReload } from "../../../../../ducks/setting";
import Hr03RequestAccountActiveDetailPage from "../Hr03RequestAccountActiveDetailPage";

const TableHistory = ({
  handleCancel,
  apiWorksheet,
  hrNumber,
  handleOpenModal,
  handleCloseModal,
}) => {
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state?.setting?.metaUsers?.loading);
  const [isLoading, setIsLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const historyRefetch = useCallback(
    async (page = 1, page_size = 10) => {
      try {
        setIsLoading(true);
        const res = await api.get(apiWorksheet, {
          params: {
            page,
            page_size,
          },
        });
        setHistoryData(res.data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [apiWorksheet]
  );

  useEffect(() => {
    apiWorksheet && historyRefetch(page, pageSize);
  }, [page, pageSize, historyRefetch, apiWorksheet]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <LoadingButton
          size="small"
          variant="outlined"
          color="info"
          loading={isLoading}
          onClick={() => historyRefetch()}
          startIcon={<Refresh />}
        >
          Reload
        </LoadingButton>
      </GridToolbarContainer>
    );
  };

  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {request_account_HR03.includes("view") && (
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  type="button"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleOpenModal(
                      <Hr03RequestAccountActiveDetailPage
                        handleCancel={handleCloseModal}
                        itemId={params?.row?.id}
                        isViewOnly
                      />
                    );
                  }}
                >
                  <RemoveRedEye />
                </IconButton>
              </div>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 100,
      width: 190,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {params.row.status === "new" ? (
                <>
                  <Chip
                    label="พงส."
                    color="warning"
                    sx={{
                      ml: 1,
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                  <Chip
                    label="รอยืนยัน"
                    color="warning"
                    sx={{
                      ml: 1,
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                </>
              ) : (
                <Chip
                  label={
                    params.row.status === "sent_to_bk"
                      ? "บก.พิจารณา"
                      : params.row.status === "bk_reject"
                      ? "บก.ปฏิเสธ"
                      : params.row.status === "sent_to_bh"
                      ? "บช.พิจารณา"
                      : params.row.status === "bh_reject"
                      ? "บช.ปฏิเสธ"
                      : params.row.status === "bh_approve"
                      ? "ตร.พิจารณา"
                      : params.row.status === "rtp_reject"
                      ? "ตร.ปฏิเสธ"
                      : params.row.status === "rtp_approve"
                      ? "ปปง.พิจารณา"
                      : params.row.status === "aml_approve"
                      ? "ผ่านการพิจารณา"
                      : params.row.status === "aml_reject_end"
                      ? "ไม่ผ่านข้อยุติพิจารณา"
                      : params.row.status === "aml_reject"
                      ? "ไม่ผ่านพิจารณา"
                      : params.row.status === "aml_reject_account"
                      ? "ไม่ผ่าน ดำเนินใหม่รายบัญชี"
                      : params.row.status === "send_to_bank"
                      ? "ผ่านการอนุมัติ"
                      : params.row.status === "rtp_approve"
                      ? "ผ่านการพิจารณา"
                      : params.row.status === "create"
                      ? "สร้างรายการ"
                      : "ยังไม่มีคำขอ"
                  }
                  color={
                    params.row.status === "sent_to_bk" ||
                    params.row.status === "sent_to_bh" ||
                    params.row.status === "create"
                      ? "warning"
                      : params.row.status === "bk_reject" ||
                        params.row.status === "bh_reject" ||
                        params.row.status === "rtp_reject" ||
                        params.row.status === "aml_reject" ||
                        params.row.status === "aml_reject_account"
                      ? "error"
                      : params.row.status === "rtp_approve"
                      ? "primary"
                      : params.row.status === "aml_approve" ||
                        params.row.status === "send_to_bank"
                      ? "success"
                      : params.row.status === "aml_reject_end"
                      ? "pink"
                      : params.row.status === "bh_approve"
                      ? "violet"
                      : "default" //grey
                  }
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                      fontFamily: "Kanit",
                    },
                  }}
                />
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 100,
    },
    {
      field: "ref_code",
      headerName: "Ref code",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.hr03_number}</>;
      },
    },
    {
      field: "short_name",
      headerName: "ประเภทคำร้อง",
      sortable: true,
      width: 150,
      renderCell: (params) => params.row?.request_form_type?.short_name ?? "-",
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.card_id ?? "-"}</>;
      },
    },
    {
      field: "full_name",
      headerName: "ชื่อ-สกุล ขอถอน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.full_name ?? "-"}</>;
      },
    },

    {
      field: "bank_id",
      headerName: "ธนาคาร",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.bank?.short_name ?? "-"}</>;
      },
    },
    {
      field: "bank_number",
      headerName: "เลขบัญชี",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.bank_number ?? "-"}</>;
      },
    },
    {
      field: "hr03.police_name.pol_name",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.fname + " " + params.row.lname ?? "-"}</>;
      },
    },
    {
      field: "ORG_ABBR",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.policestation?.ORG_ABBR ?? "-"}</>;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const masterModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    px: 4,
    pt: 5,
    overflow: "auto", // ให้ Modal เลื่อนได้เมื่อเนื้อหาเยอะ
    maxHeight: "90vh", // จำกัดความสูงสูงสุดของ Modal
    width: "80%", // กำหนดความกว้างของ Modal
    height: "auto", // ให้ Modal ปรับความสูงตามเนื้อหา
  };

  const modalStyle = {
    ...masterModalStyle,
    height: "80%", // กำหนดความสูงของ Modal
    width: "80%", // กำหนดความกว้างของ Modal
  };

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Box sx={modalStyle}>
        <Typography
          variant="h5"
          sx={{
            mb: 2,
          }}
        >
          รายการข้อมูลขอเปิดใช้งานบัญชี (Ref code : {hrNumber})
        </Typography>
        <Box
          sx={{
            height: "60vh", // กำหนดความสูงของ Box ที่ครอบตาราง
            overflow: "auto", // ให้ Box เลื่อนได้เมื่อข้อมูลมีมาก
          }}
        >
          <DataGrid
            rowCount={historyData.total ?? 0}
            disableColumnMenu
            columns={columns}
            rows={_.get(historyData, "data", [])}
            loading={loading || isLoading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page}
            pageSize={pageSize}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize) => {
              console.log({ pageSize });
              setPageSize(pageSize);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              height: "100%", // ให้ DataGrid มีความสูงเต็มที่ภายใน Box
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 20,
            right: 20,
            backgroundColor: "background.paper",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleCancel(false);
            }}
          >
            ปิด
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TableHistory;
