import {
  Alert,
  Box,
  Chip,
  Grid,
  Modal,
  Paper,
  Skeleton,
  Snackbar,
  Typography,
} from "@mui/material";
import { FORM_ERROR } from "final-form";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { hr03RequestAccountActiveCreate } from "../../../../ducks/hr03List";
import Hr03RequestActiveForm, {
  validate,
} from "./Form/Hr03RequestAccountActiveForm";
import TableHistoryUsedAccount from "./component/TableHistoryUsedAccount";
import Resizer from "react-image-file-resizer";

export default function Hr03RequestAccountActiveAddPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [dataHr03, setDataHr03] = useState();
  const [loading, setLoading] = useState(false);
  const [idFromHr03, setIdFromHr03] = useState(null);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);

  const handleCloseModal = useCallback(
    (reason) => {
      if (reason && reason.type === "error") {
        SetToastInfo(reason);
      }
      setOpenModal(false);
    },
    [dispatch]
  );

  const [openModalHistory, setOpenModalHistory] = React.useState(false);
  const handleOpenModalHistory = (page) => setOpenModalHistory(page);
  const handleCloseModalHistory = () => setOpenModalHistory(false);

  useEffect(() => {
    if (location && location.state) {
      setDataHr03(_.get(location, "state"));
    } else {
      navigate("/hr03/request-account-active");
    }
  }, [location]);

  const onSubmit = async (data) => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });
    const signatureResize = (file) => {
      const fileType = file.type === "image/png" ? "PNG" : "JPEG";
      const compress = file.type === "image/png" ? 100 : 90;
      return new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          90,
          50,
          fileType,
          compress,
          0,
          (uri) => {
            resolve(uri);
          },
          "base64"
        );
      });
    };
    const objData = { ...data };

    const formData = new FormData();

    let attachment_more = await Promise.all(
      [...objData?.receiveDataOtherFile?.data].map(async (file) => {
        const base64File = await toBase64(file.file[0]); // แปลงไฟล์เป็น Base64
        return {
          template_id: file.template_id,
          file_name: file.file_name,
          file: base64File, // ใช้ Base64 string
          original_file_name: file.original_file_name, // ชื่อไฟล์เดิม
        };
      })
    );

    formData.append("type_request", objData.type_request);
    formData.append("type", objData.type);
    formData.append("request_org", objData.policestation_org_code);
    formData.append("hr03_id", objData.hr03_id);
    formData.append("nationality", objData.nationality);
    formData.append("birth_date", objData.birth_date);
    formData.append("attachment_more", JSON.stringify(attachment_more));
    if (objData.type === 2) {
      formData.append("bank_id", objData.bank_id);
      formData.append("bank_number", objData.bank_number);
      formData.append("bank_name", objData.bank_name);
    }
    formData.append("discription_bank", objData.discription_bank);
    formData.append("is_declare_note", objData.is_declare_note);
    if (objData.is_declare_note === 1) {
      formData.append("declare_note", objData.declare_note);
    }
    formData.append("full_name", objData.fullName);
    formData.append("card_id", objData.card_id);
    formData.append("request_date", objData.date);
    formData.append("book_no", objData.no);
    if (objData.files) {
      const attachments = [];
      for (const [key, value] of Object.entries(objData.files)) {
        for (const file of value) {
          const base64File = await toBase64(file);
          attachments.push({
            template_id: parseInt(key.replace("file", ""), 10),
            file_name: file.name,
            file: base64File,
          });
        }
      }
      formData.append("attachment", JSON.stringify(attachments));
    }
    const base64Signature =
      typeof objData.user.profile_signature === "string"
        ? objData.user.profile_signature
        : await signatureResize(objData.user.profile_signature);
    formData.append("pol_signature", base64Signature);


    return dispatch(hr03RequestAccountActiveCreate(formData))
      .then(() => {
        navigate("/hr03/request-account-active");
        toast.success("สร้างสำเร็จ");
      })
      .catch(({ response }) => {
        if (response?.status === 422) {
          const errors = _.mapValues(response.data.errors, (e) => e[0]);

          // convert laravel dot notation key to object
          const normalizeKeyError = {};
          for (const [key, value] of Object.entries(errors)) {
            _.set(normalizeKeyError, key, value);
          }

          normalizeKeyError[FORM_ERROR] = response.data.message;

          // console.log(normalizeKeyError);
          return normalizeKeyError;
        } else {
          return {
            [FORM_ERROR]: "เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง",
          };
        }
      });
  };

  return (
    <>
      <Modal
        open={openModalHistory ? true : false}
        onClose={() => handleCloseModalHistory()}
        aria-labelledby="modal-modal-title-history"
        aria-describedby="modal-modal-description-history"
      >
        <div>{openModalHistory}</div>
      </Modal>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>

      <Grid item xs={12}>
        <Typography variant="h5">
          เพิ่มข้อมูลคำร้องขอเปิดใช้งานบัญชี HR03{" "}
          <Chip
            onClick={() =>
              dataHr03?.total_open_worksheet > 0 &&
              handleOpenModal(
                <TableHistoryUsedAccount
                  handleCancel={handleCloseModal}
                  apiWorksheet={dataHr03?.worksheet}
                  hrNumber={dataHr03?.ref_code}
                  handleOpenModal={handleOpenModalHistory}
                  handleCloseModal={handleCloseModalHistory}
                />
              )
            }
            disabled={dataHr03?.total_open_worksheet < 1}
            label={`เปิดไปเเล้ว ${dataHr03?.total_open_worksheet ?? 0} รายการ`}
            color="default"
            sx={{
              height: "25px",
              "& .MuiChip-label": {
                display: "block",
                whiteSpace: "normal",
                fontFamily: "Kanit",
                fontSize: "14px",
              },
            }}
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {loading ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <Form
              validate={validate}
              initialValues={dataHr03}
              onSubmit={onSubmit}
              component={Hr03RequestActiveForm}
              page="add"
              idFromHr03={(id) => {
                setIdFromHr03(id);
              }}
            />
          )}
        </Paper>
      </Grid>
    </>
  );
}
