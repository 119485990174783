import React, { useMemo, useEffect } from "react";
import { Box, Grid, Typography, Avatar } from "@mui/material";
import CountUp from "react-countup";
import _ from "lodash";

const SumCard = ({ data }) => {


  return (
    <Box
      sx={{
        my: 2,
      }}
    >
      <Grid container spacing={2}>
        {data?.map((item, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: [3, 4, 5].includes(index)
                  ? "#757575"
                  : "#000000",
                color: "white",
                padding: 2,
                minHeight: "100px", // กำหนดความสูงให้เท่ากันทุกการ์ด
                borderRadius: "12px",
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                position: "relative",
                overflow: "hidden",
                transition: "transform 0.3s",
                width: "100%", // ให้กว้างเต็ม 100% ของ Grid
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "center",
                  fontFamily: "Kanit, sans-serif",
                  lineHeight: "1.2",
                  overflow: "hidden",
                  width: "100%", // ให้ข้อความกว้างเท่ากับการ์ด
                }}
                title={item?.name} // แสดงข้อความเต็มเมื่อ hover
              >
                {item?.name}
              </Typography>

              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                <CountUp
                  separator=","
                  start={0}
                  end={_.isNil(item.total) ? 0 : item.total}
                />
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SumCard;
