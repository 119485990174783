import { Button, Grid, Stack } from "@mui/material";
import { FORM_ERROR } from "final-form";
import Joi from "joi";
import React from "react";
import letterTypes from "../../../data/selects/letterTypes";
import _ from "lodash";
import validationMsg from "../../../helpers/validationMsg";
import FieldAsyncSelect from "../../form-fields/FieldAsyncSelect";
import FieldAutoComplete from "../../form-fields/FieldAutoComplete";
import SubmitBtn from "../../SubmitBtn";
import { Search } from "@mui/icons-material";
import FieldDatePicker from "../../form-fields/FieldDatePicker";
import { format, isAfter, parseISO, startOfToday } from "date-fns";
import requestStatusSelect from "../../../data/selects/requestStatus";
import { defaultJoiDates } from "../../../helpers/dateHelper";
import FieldInput from "../../form-fields/FieldInput";

export default function SearchForm({
  loading, //mycustom
  onReset, //mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
  modifiedSinceLastSubmit,
}) {
  // const viewerOrg = useSelector((state) => state.auth.user.viewerOrg);

  // const orgOptions = useQuery(
  //   policeStationObjQuery({ bh_code: viewerOrg.ORG_CODE })
  // );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container sx={{ mb: 3 }} spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={2}>
          <FieldAutoComplete
            name="status"
            label="สถานะ"
            options={requestStatusSelect}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            // inputProps={{ sx: { fullWidth: true } }}
            // controlProp={{ sx: { fullWidth: true } }}
          />
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAutoComplete
            name="policestation_org_code"
            label="หน่วยงาน"
            required
            controlProp={{ fullWidth: true }}
            inputProps={{
              sx: { width: "100%" },
              loading: orgOptions.isLoading || orgOptions.error ? true : false,
              loadingText: orgOptions.error ? "Error" : "Loading…",
            }}
            options={orgOptions.data}
          />
        </Grid> */}
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAsyncSelect
            name="bank_id"
            label="ธนาคาร"
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            basePath={`/api/banks`}
            filter={{ type: "bank" }}
            itemFormat={(row) => {
              return {
                value: row.id,
                label: `${row.name} (${row.short_name})`,
              };
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <FieldAutoComplete
            name="type_id"
            label="ประเภทคำขอ"
            options={letterTypes}
            controlProp={{
              sx: {
                width: "100%",
              },
            }}
            //   inputProps={{ sx: { fullWidth: true } }}
          />
        </Grid>
        <Grid item xs={12} xl={4}>
          <Stack
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            }}
            spacing={2}
          >
            <FieldDatePicker
              name="start_date"
              label="ตั้งแต่วันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
            <FieldDatePicker
              name="end_date"
              label="ถึงวันที่"
              pickerProps={{
                inputFormat: "dd/MM/yyyy",
                disableFuture: true,
                openTo: "year",
                views: ["year", "month", "day"],
                allowSameDateSelection: true,
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <FieldInput
            name="search"
            label="คำค้นหา"
            controlProps={{ fullWidth: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center">
            <SubmitBtn
              variant="contained"
              startIcon={<Search />}
              submitting={loading}
              pristine={pristine && !modifiedSinceLastSubmit}
            >
              ค้นหา
            </SubmitBtn>
            <Button
              variant="outlined"
              // disabled={loading || (pristine && !modifiedSinceLastSubmit)}
              disabled={loading || _.isEmpty(values)}
              onClick={(event) => {
                form.reset();
                if (onReset) onReset(event);
              }}
            >
              Reset
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {/* <Stack
        direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
        spacing={2}
        sx={{ mb: 2 }}
      >
        <FieldAsyncSelect
          name="bank_id"
          label="ธนาคาร"
          controlProp={{
            sx: { width: { sm: "auto", md: "auto", lg: "30%" } },
          }}
          basePath={`/api/banks`}
          // filter={{ search: "xxxx"}}
          itemFormat={(row) => {
            return {
              value: row.id,
              label: `${row.name} (${row.short_name})`,
            };
          }}
        />
        <FieldAutoComplete
          name="type_id"
          label="ประเภทคำขอ"
          options={letterTypes}
          controlProp={{
            sx: { width: { sm: "auto", md: "auto", lg: "30%" } },
          }}
          //   inputProps={{ sx: { fullWidth: true } }}
        />
        <FieldDatePicker
          name="start_date"
          label="ตั้งแต่วันที่"
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
        />
        <FieldDatePicker
          name="end_date"
          label="ถึงวันที่"
          pickerProps={{
            inputFormat: "dd/MM/yyyy",
            disableFuture: true,
            openTo: "year",
            views: ["year", "month", "day"],
            allowSameDateSelection: true,
          }}
        />
        <SubmitBtn
          variant="contained"
          startIcon={<Search />}
          submitting={loading}
          pristine={pristine && !modifiedSinceLastSubmit}
        >
          ค้นหา
        </SubmitBtn>
        <Button
          variant="outlined"
          disabled={loading}
          onClick={(event) => {
            form.reset();
            if (onReset) onReset(event);
          }}
        >
          Reset
        </Button>
      </Stack> */}
    </form>
  );
}
export const validate = (values, props) => {
  const errors = {};
  const vResult = schema.validate(values, {
    abortEarly: false,
    allowUnknown: false,
  });

  // console.log(vResult);
  // console.log(schema);

  if (vResult.error) {
    let details = vResult.error.details;
    details.forEach(({ context, message }) => {
      _.set(errors, context.label, message);
      // return (errors[context.label] = message);
      errors[FORM_ERROR] = "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบการกรอกข้อมูล";
    });
  }

  const now = new Date();
  if (!errors.start_date && values.start_date) {
    const startDate = parseISO(values.start_date);
    if (isAfter(startDate, now)) {
      errors.start_date = "มากกว่าเวลาปัจจุบัน";
    }
  }

  if (!errors.end_date && values.end_date) {
    const endDate = parseISO(values.end_date);
    if (isAfter(endDate, now)) {
      errors.end_date = "มากกว่าเวลาปัจจุบัน";
    }
  }

  if (
    !errors.start_date &&
    !errors.end_date &&
    values.start_date &&
    values.end_date &&
    isAfter(parseISO(values.start_date), parseISO(values.end_date))
  ) {
    errors.start_date = "ไม่ถูกต้อง";
  }

  // console.log(errors);

  return errors;
};

export const validateRules = {
  type_id: Joi.number().min(1).max(9).allow(null),
  bank_id: Joi.number().allow(null),
  start_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .messages({
            "string.empty": "กรุณาระบุวันที่",
            "any.required": "กรุณาระบุวันที่",
            "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.minDate,
              "dd/MM/yyyy"
            )}`,
            "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.forceUTC(startOfToday()),
              "dd/MM/yyyy"
            )}`,
          })
    .allow(null),
  end_date: Joi.date()
    .min(defaultJoiDates.minDate)
    .max(defaultJoiDates.forceUTC(startOfToday()))
    .messages({
            "string.empty": "กรุณาระบุวันที่",
            "any.required": "กรุณาระบุวันที่",
            "date.min": `ต้องมากกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.minDate,
              "dd/MM/yyyy"
            )}`,
            "date.max": `ต้องน้อยกว่าหรือเท่ากับวันที่ ${format(
              defaultJoiDates.forceUTC(startOfToday()),
              "dd/MM/yyyy"
            )}`,
          })
    .allow(null),
  status: Joi.string().allow(null),
  search: Joi.string().max(50).allow(null),
  policestation_org_code: Joi.object({
    org_code: Joi.string()
      .pattern(/^\d{5}$/)
      .required(),
    org_type: Joi.string().uppercase().length(2).required(),
  }),
};

const schema = Joi.object(validateRules).error(validationMsg());
