import { Refresh, RemoveRedEye, Rule } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import {
  usersClearResult,
  usersForceReload,
  usersSetFilter,
} from "../../../../ducks/setting";
import Hr03VerifyAccountActiveSearchForm from "./Hr03VerifyAccountActiveSearchForm";
import Hr03VerifyAccountActiveDetailPage from "./Hr03VerifyAccountActiveDetailPage";

export default function Hr03VerifyAccountActive() {
  const verify_request_account_HR03_process = useSelector(
    (state) =>
      state.auth.user.permissions.verify_request_account_HR03_process ?? []
  );
  const dispatch = useDispatch();
  const { status: reqStatus } = useParams();
  const navigate = useNavigate();

  const isValidStatus = useMemo(() => {
    return (
      reqStatus === undefined ||
      ["sent_to_contact_person", "checked"].includes(reqStatus)
    );
  }, [reqStatus]);

  useEffect(() => {
    return () => {
      dispatch(usersClearResult());
      dispatch(usersSetFilter({}));
    };
  }, [dispatch, reqStatus]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  // const fetchIdRef = React.useRef(0);
  // const rows = useSelector((state) => state?.setting?.users?.data);
  // const total = useSelector((state) => state?.setting?.metaUsers?.total);
  const loading = useSelector((state) => state?.setting?.metaUsers?.loading);
  // const forceReload = useSelector(
  //   (state) => state?.setting?.metaUsers?.forceReload
  // );
  // const page = useSelector((state) => state?.setting?.metaUsers?.page);
  // const pageSize = useSelector((state) => state?.setting?.metaUsers?.pageSize);
  const filter = useSelector((state) => state?.setting?.metaUsers?.filter);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [openModal, setOpenModal] = React.useState(false);
  const [usersData, setUsersData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleOpenModal = (page) => setOpenModal(page);
  const [reload, setReload] = useState(0);

  const handleCloseModal = useCallback(
    (reason) => {
      if (reason && reason.type === "error") {
        SetToastInfo(reason);
      }
      if (reason === "closeModal") {
        setOpenModal(false);
        return;
      }

      usersRefetch(page, pageSize, filter);
      setOpenModal(false);
    },
    [page, pageSize, filter]
  );

  const usersRefetch = useCallback(
    async (page = 1, page_size = 10, filter = {}) => {
      try {
        setIsLoading(true);
        const res = await api.get(`/api/openbankaccount/process`, {
          params: {
            ...filter,
            page,
            page_size,
          },
        });
        setUsersData(res.data);
      } catch (error) {
        console.log({ error });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const [cfDel, setCfDel] = useState(false);
  const handleCfDelClose = () => setCfDel(false);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <LoadingButton
          size="small"
          variant="outlined"
          color="info"
          loading={isLoading}
          onClick={() => usersRefetch()}
          startIcon={<Refresh />}
        >
          Reload
        </LoadingButton>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    usersRefetch(page, pageSize, filter);
  }, [page, pageSize, filter, usersRefetch, reload]);

  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <>
            {(verify_request_account_HR03_process.includes("view") ||
              verify_request_account_HR03_process.includes("edit")) && (
              <stack spacing={1} direction="row" alignItems>
                {params.row.approve || params.row.reject ? (
                  <IconButton
                    size="small"
                    sx={{ boxShadow: 1 }}
                    onClick={() => {
                      handleOpenModal(
                        <>
                          <Hr03VerifyAccountActiveDetailPage
                            id={params?.row?.id}
                            handleCloseModal={handleCloseModal}
                            setReload={setReload}
                            reload={reload}
                            isViewOnly={false}
                          />
                        </>
                      );
                    }}
                  >
                    <Rule color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleOpenModal(
                        <>
                          <Hr03VerifyAccountActiveDetailPage
                            id={params?.row?.id}
                            handleCloseModal={handleCloseModal}
                            setReload={setReload}
                            reload={reload}
                            isViewOnly={true}
                          />
                        </>
                      );
                    }}
                  >
                    <RemoveRedEye />
                  </IconButton>
                )}
              </stack>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "สถานะ",
      sortable: true,
      minWidth: 100,
      width: 190,
      renderCell: (params) => {
        return (
          <Stack spacing={1} alignItems="center">
            <Stack direction="row" spacing={1}>
              {params.row.status === "new" ? (
                <>
                  <Chip
                    label="พงส."
                    color="warning"
                    sx={{
                      ml: 1,
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                  <Chip
                    label="รอยืนยัน"
                    color="warning"
                    sx={{
                      ml: 1,
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                </>
              ) : (
                <Tooltip
                  placement="bottom"
                  arrow
                  title={
                    params?.row?.last_edit_note
                      ? params?.row?.last_edit_note
                      : ""
                  }
                >
                  <Chip
                    label={
                      params.row.status === "sent_to_bk"
                        ? "บก.พิจารณา"
                        : params.row.status === "bk_reject"
                        ? "บก.ปฏิเสธ"
                        : params.row.status === "sent_to_bh"
                        ? "บช.พิจารณา"
                        : params.row.status === "bh_reject"
                        ? "บช.ปฏิเสธ"
                        : params.row.status === "bh_approve"
                        ? "ตร.พิจารณา"
                        : params.row.status === "rtp_reject"
                        ? "ตร.ปฏิเสธ"
                        : params.row.status === "rtp_approve"
                        ? "ปปง.พิจารณา"
                        : params.row.status === "send_to_bank"
                        ? "ผ่านการอนุมัติ"
                        : params.row.status === "aml_approve"
                        ? "ผ่านการพิจารณา"
                        : params.row.status === "aml_reject_end"
                        ? "ไม่ผ่านข้อยุติพิจารณา"
                        : params.row.status === "aml_reject"
                        ? "ไม่ผ่านพิจารณา"
                        : params.row.status === "aml_reject_account"
                        ? "ไม่ผ่าน ดำเนินใหม่รายบัญชี"
                        : params.row.status === "create"
                        ? "สร้างรายการ"
                        : "ยังไม่มีคำขอ"
                    }
                    color={
                      params.row.status === "sent_to_bk" ||
                      params.row.status === "sent_to_bh" ||
                      params.row.status === "create"
                        ? "warning"
                        : params.row.status === "bk_reject" ||
                          params.row.status === "bh_reject" ||
                          params.row.status === "rtp_reject" ||
                          params.row.status === "aml_reject" ||
                          params.row.status === "aml_reject_account"
                        ? "error"
                        : params.row.status === " " ||
                          params.row.status === "rtp_approve"
                        ? "primary"
                        : params.row.status === "aml_approve" ||
                          params.row.status === "send_to_bank"
                        ? "success"
                        : params.row.status === "aml_reject_end"
                        ? "pink"
                        : params.row.status === "bh_approve"
                        ? "violet"
                        : "default" //grey
                    }
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        fontFamily: "Kanit",
                      },
                    }}
                  />
                </Tooltip>
              )}
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 100,
    },
    {
      field: "hr03_number",
      headerName: "Ref code",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.hr03_number}</>;
      },
    },
    {
      field: "short_name",
      headerName: "ประเภทคำร้อง",
      sortable: true,
      width: 150,
      renderCell: (params) => params.row?.request_form_type?.short_name ?? "-",
    },
    {
      field: "card_id",
      headerName: "เลขประจำตัวฯ/passports",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.card_id ?? "-"}</>;
      },
    },
    {
      field: "full_name",
      headerName: "ชื่อ-สกุล ขอถอน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.full_name ?? "-"}</>;
      },
    },

    {
      field: "bank_id",
      headerName: "ธนาคาร",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params?.row?.bank?.short_name ?? "-"}</>;
      },
    },
    {
      field: "bank_number",
      headerName: "เลขบัญชี",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.bank_number ?? "-"}</>;
      },
    },
    {
      field: "hr03.police_name.pol_name",
      headerName: "ชื่อ พงส.",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.fname + " " + params.row.lname ?? "-"}</>;
      },
    },
    {
      field: "ORG_ABBR",
      headerName: "หน่วยงาน",
      sortable: true,
      width: 150,
      renderCell: (params) => {
        return <>{params.row.policestation?.ORG_ABBR ?? "-"}</>;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];

  const onSearchSubmit = (formValues) => {
    dispatch(usersSetFilter(formValues));
  };

  if (!isValidStatus) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Snackbar
        open={toastInfo ? true : false}
        autoHideDuration={6000}
        onClose={handleToastInfoClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {toastInfo?.type && (
          <Alert
            onClose={handleToastInfoClose}
            severity={toastInfo?.type || "warning"}
            sx={{ width: "100%" }}
          >
            {toastInfo?.message || ""}
          </Alert>
        )}
      </Snackbar>
      <Modal
        open={openModal ? true : false}
        onClose={() => handleCloseModal("closeModal")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>{openModal}</div>
      </Modal>

      <Grid item xs={12}>
        <Typography variant="h5">ตรวจสอบรายการ HR03/ม้าเทา</Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minHeight: "500px",
            width: "100%",
          }}
        >
          <Form
            // validate={validate}
            onSubmit={onSearchSubmit}
            component={Hr03VerifyAccountActiveSearchForm}
            loading={loading}
            onReset={() => dispatch(usersSetFilter({}))}
            showStatusField={reqStatus ? false : true}
            usersData={usersData}
            type="verify"
            filter={filter}
            total={usersData.total}
          />
          <DataGrid
            // density="compact"
            rowCount={usersData.total ?? 0}
            disableColumnMenu
            columns={columns}
            rows={_.get(usersData, "data", [])}
            loading={loading || isLoading}
            rowsPerPageOptions={[5, 10, 15, 20]}
            pagination
            paginationMode="server"
            page={page - 1}
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(pageSize) => {
              console.log("ssss");
              console.log({ pageSize });
              setPageSize(pageSize);
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              height: "100%",
              minHeight: "500px",
              width: "100%",
              mb: 2,
            }}
          />
        </Paper>
      </Grid>
    </>
  );
}
