import { Grid, Typography } from "@mui/material";
import React from "react";
import FieldInput from "../../form-fields/FieldInput";

export default function PersonnelSearchForm({
  onCancel, //mycustom
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  form,
  values,
}) {
  return (
    <form onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            ตรวจสอบข้อมูลรายละเอียดพนักงาน จากข้อมูลกำลังพล
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="rank_abbr"
            label="ยศ"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="level_name"
            label="ตำแหน่ง"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="name"
            label="ชื่อ"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="sname"
            label="สกุล"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="card_id"
            label="เลขบัตรประชาชน"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="org_desc"
            label="หน่วยงานที่สังกัด"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="bh_name"
            label="หน่วยงาน บช"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="bk_name"
            label="หน่วยงาน บก"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="kk_name"
            label="หน่วยงาน กก"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInput
            name="status"
            label="สถานะ"
            required
            controlProps={{ fullWidth: true }}
            readOnly
          />
        </Grid>
      </Grid>
    </form>
  );
}

export const mapToInit = (item) => {
  return item;
};
