import React, { useEffect, useState } from "react";
import { useField, useForm } from "react-final-form";
import { useQuery } from "react-query";
import { authQuery } from "../../../../../data/queries/user";
import SignaturePreview from "../SignaturePreview";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import FieldSingleFileDropZone from "../../../../form-fields/FieldSingleFileDropZone";
import { Cancel, InfoOutlined, ReplayCircleFilled } from "@mui/icons-material";

export default function ReuseSignatureH({ name, readOnly }) {
  const sign = useField(name);
  const form = useForm();

  const [signMode, setSignMode] = useState("predefined");

  const handleSignMode = (mode) => {
    setSignMode(mode);
  };

  const signData = useQuery({
    ...authQuery(),
    enabled: !readOnly,
    select: (data) => data.profile_signature,
  });

  useEffect(() => {
    if (readOnly) return undefined;

    if (signData.isSuccess && signMode === "predefined") {
      form.change(name, signData.data);
    } else if (signMode === "upload" && !(sign.input.value instanceof File)) {
      form.change(name, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signData.isSuccess, signMode, sign.meta.pristine]);

  const renderSignature = () => {
    if (readOnly) return <SignaturePreview name="signature" />;

    if (signData.error)
      return (
        <Typography variant="subtitle1" color="error" textAlign="center">
          ! เกิดข้อผิดพลาดเกี่ยวกับลายเซ็น กรุณาลองใหม่อีกครั้งหรือติดต่อ admin
        </Typography>
      );

    if (signData.isLoading)
      return <Typography variant="subtitle1">Loading...</Typography>;

    if (signMode === "predefined") return <SignaturePreview name="signature" />;
    else
      return (
        <FieldSingleFileDropZone
          name="signatureH"
          accept="image/jpeg, image/png"
          placeholder={
            <>
              ลากวางไฟล์ลายเซ็นของท่านลงที่นี่, หรือกดที่นี่เพื่อเลือกไฟล์
              <br />
              รองรับไฟล์ประเภท (*.jpg,*.gif,*.png) เท่านั้น
            </>
          }
          ishowContext
        />
      );
  };

  const renderToggleBtn = () => {
    if (readOnly) return null;

    if (signMode === "predefined")
      return (
        <Tooltip placement="top" arrow title="นำลายเซ็นออก">
          <IconButton color="primary" onClick={() => handleSignMode("upload")}>
            <Cancel />
          </IconButton>
        </Tooltip>
      );

    return (
      <Tooltip placement="top" arrow title="ใช้ลายเซ็นจากระบบ">
        <IconButton
          color="primary"
          onClick={() => handleSignMode("predefined")}
        >
          <ReplayCircleFilled />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Stack
      spacing={1}
      border={1}
      borderColor={"lightgray"}
      p={2}
      borderRadius={2}
    >
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="body"
          sx={{ flexGrow: 1, alignItems: "center", display: "flex" }}
        >
          ลายเซ็น &nbsp;&nbsp;
          {!readOnly && (
            <Tooltip
              placement="top"
              arrow
              title="ผู้ใช้สามารถใช้ลายเซ็นเดิมที่เคยให้กับระบบไว้ หรือ กำหนดใหม่หากต้องการเปลี่ยน"
            >
              <InfoOutlined fontSize="small" color="primary" />
            </Tooltip>
          )}
        </Typography>
        {renderToggleBtn()}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {renderSignature()}
      </Box>
    </Stack>
  );
}
