import {
  BackupTable,
  Download,
  Pending,
  PictureAsPdf,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { compareDesc, format, isValid, parseISO } from "date-fns";
import fileDownload from "js-file-download";
import React, { useMemo, useState } from "react";
import api from "../../apis";

const masterModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

export default function ResponseList({ items = [] }) {
  const [pageSize, setPageSize] = useState(5);
  const rows = useMemo(
    () =>
      items
        .sort((a, b) =>
          compareDesc(
            parseISO(a.response?.created_at || ""),
            parseISO(b.response?.created_at || "")
          )
        )
        .map((item, index) => ({ ...item, id: index })),
    [items]
  );
  const columns = [
    {
      field: "actions",
      headerName: "actions",
      sortable: false,
      width: 180,
      renderCell: (params) => {
        if (!params.row.link) {
          return undefined;
        }

        if (
          params.row.link?.match(/^\/api\/responses\/\d+\/tp04_download\/\d+$/)
        ) {
          return (
            <Stack
              spacing={1}
              direction="row"
              justifyContent="left"
              sx={{ width: "100%" }}
            >
              <Tp04DownloadButton
                id={params.row.setid}
                link={params.row.link}
                label="ผลการตอบกลับ"
              />
            </Stack>
          );
        }

        return (
          <Stack
            spacing={1}
            direction="row"
            justifyContent="left"
            sx={{ width: "100%", alignItems: "center" }}
          >
            <DownloadButton link={params.row.link} label="ผลการตอบกลับ" />
          </Stack>
        );
      },
    },
    {
      field: "name",
      headerName: "ชื่อ",
      sortable: false,
      width: 400,
      valueGetter: (params) => {
        return params.row.response?.response_note;
      },
    },
    {
      field: "recived_at",
      headerName: "วันที่ได้รับ",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        if (!params.row.response?.recived_at) {
          return undefined;
        }

        const date = parseISO(params.row.response?.recived_at);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
    {
      field: "created_at",
      headerName: "วันที่ upload",
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        if (!params.row.response?.created_at) {
          return undefined;
        }

        const date = parseISO(params.row.response?.created_at);

        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ];
  return (
    <DataGrid
      columns={columns}
      disableColumnMenu
      rows={rows}
      rowsPerPageOptions={[5, 10]}
      pageSize={pageSize}
      // pagination
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      autoHeight
    />
  );
}

const DownloadButton = ({ link, label }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={1} // ระยะห่างระหว่างองค์ประกอบ
        onClick={() => {
          setLoading(true);
          api
            .get(link, {
              responseType: "arraybuffer",
            })
            .then((response) => {
              const contentDisposition =
                response.headers["content-disposition"];
              const filename = /filename=.+/.exec(contentDisposition)
                ? decodeURI(/filename="?([^"]+)"?/.exec(contentDisposition)[1])
                : "download";
              fileDownload(response.data, filename);
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        <IconButton
          disabled={loading}
          type="button"
          variant="contained"
          size="small"
        >
          {loading ? <Pending /> : <Download color="primary" />}
        </IconButton>
        {label && (
          <Typography
            variant="body2"
            sx={{
              display: "block",
              cursor: "pointer",
              color: loading ? "gray" : "#1976d2",
              textDecoration: "underline",
            }}
          >
            ผลการตอบกลับ
          </Typography>
        )}
      </Box>
    </>
  );
};

const Tp04ToolTip = ({ title, children, ...props }) => {
  return title ? (
    <Tooltip title={title} {...props}>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

const Tp04DownloadButton = ({ link, label }) => {
  const modalStyle = {
    ...masterModalStyle,
    width: "65%",
  };
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalDulation, setOpenModalDulation] = useState(false);
  const [startElement, setStartElement] = useState("");
  const [endElement, setEndElement] = useState("");

  const openActionMenu = Boolean(anchorEl);
  const handleActionMenuClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModalDulation = () => {
    setOpenModalDulation(false);
    setStartElement("");
    setEndElement("");
  };

  const handleDownload = (dlLink) =>
    api
      .get(dlLink, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? decodeURI(/filename="?([^"]+)"?/.exec(contentDisposition)[1])
          : "download";
        fileDownload(response.data, filename);
      });

  const handleDownloadDulation = () =>
    api
      .get(`${link}?start_element=${startElement}&end_element=${endElement}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = /filename=.+/.exec(contentDisposition)
          ? decodeURI(/filename="?([^"]+)"?/.exec(contentDisposition)[1])
          : "download";
        fileDownload(response.data, filename);
      })
      .finally(() => {
        setOpenModalDulation(false);
        setStartElement("");
        setEndElement("");
      });

  let noPdfLink = link;
  // Ensure existingPath ends with a slash
  if (!noPdfLink?.endsWith("/")) {
    noPdfLink += "/"; // Add a slash if it's not already present
  }
  noPdfLink += "no_pdf";

  return (
    <>
      <Modal
        open={openModalDulation ? true : false}
        onClose={() => handleCloseModalDulation()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <Box sx={modalStyle}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography id="modal-modal-title" variant="h6" component="div">
                  กรุณาระบุช่วงข้อมูล
                </Typography>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label="จำนวนแถวเริ่มต้น"
                  value={startElement}
                  onChange={(e) => setStartElement(e.target.value)}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  fullWidth
                  label="จำนวนแถวสิ้นสุด"
                  value={endElement}
                  onChange={(e) => setEndElement(e.target.value)}
                />
              </Grid>
              <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                <Button
                  disabled={loading || !startElement || !endElement}
                  type="button"
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setLoading(true);
                    handleDownloadDulation().finally(() => {
                      setLoading(false);
                    });
                  }}
                >
                  ดาวน์โหลด
                </Button>
              </Stack>
            </Grid>
          </Box>
        </div>
      </Modal>
      <Tp04ToolTip title={loading ? "โปรดรอ" : null} placement="top" arrow>
        <Box
          display="flex"
          alignItems="center"
          gap={1} // ระยะห่างระหว่างองค์ประกอบ
          aria-controls={openActionMenu ? "action-menu-button" : undefined}
          aria-haspopup="true"
          aria-expanded={openActionMenu ? "true" : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <IconButton
            disabled={loading}
            type="button"
            variant="contained"
            size="small"
          >
            {loading ? <Pending /> : <Download color="primary" />}
          </IconButton>
          {label && (
            <Typography
              variant="body2"
              sx={{
                cursor: "pointer",
                color: loading ? "gray" : "#1976d2",
                textDecoration: "underline",
                whiteSpace: "nowrap", // ป้องกันข้อความขึ้นบรรทัดใหม่
              }}
            >
              ผลการตอบกลับ
            </Typography>
          )}
        </Box>
      </Tp04ToolTip>

      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={openActionMenu}
        onClose={handleActionMenuClose}
        MenuListProps={{
          "aria-labelledby": "action-menu-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleActionMenuClose();
            setLoading(true);
            handleDownload(noPdfLink).finally(() => {
              setLoading(false);
            });
          }}
        >
          <ListItemIcon>
            <BackupTable fontSize="small" />
          </ListItemIcon>
          <ListItemText>ข้อมูล xlsx</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleActionMenuClose();
            setLoading(true);
            handleDownload(link).finally(() => {
              setLoading(false);
            });
          }}
        >
          <ListItemIcon>
            <PictureAsPdf fontSize="small" />
          </ListItemIcon>
          <ListItemText>ข้อมูล pdf (อาจใช้เวลานาน)</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleActionMenuClose();
            setOpenModalDulation(true);
          }}
        >
          <ListItemIcon>
            <PictureAsPdf fontSize="small" />
          </ListItemIcon>
          <ListItemText>ข้อมูล pdf แบบระบุช่วงข้อมูล</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
