import { Refresh } from "@mui/icons-material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Typography } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import { format, isValid, parseISO } from "date-fns";
import _, { max, min } from "lodash";
import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import Hr03ModalUpload from "./Hr03ModalUpload";
import api from "../../../../../apis";
import { useRef } from "react";
import Tooltip from "@mui/material/Tooltip"; // นำเข้า Tooltip
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";

const TableOtherFiles = ({
  handleCancel,
  apiWorksheet,
  hrNumber,
  handleOpenModal,
  handleCloseModal,
  typeCurrent,
  historyData,
  receiveData,
  values,
  isViewOnly,
  type_request,
}) => {
  const location = useLocation();
  const isFirstLoad = useRef(true);
  const request_account_HR03 = useSelector(
    (state) => state.auth.user.permissions.request_account_HR03 ?? []
  );
  const verify_request_account_HR03_process = useSelector(
    (state) =>
      state.auth.user.permissions.verify_request_account_HR03_process ?? []
  );

  let request = location?.pathname.includes("request");
  let verify = location?.pathname.includes("verify");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const loading = useSelector((state) => state?.setting?.metaUsers?.loading);
  const [isLoading, setIsLoading] = useState(false);
  const [toastInfo, SetToastInfo] = useState(false);
  const [dataOtherFiles, setDataOtherFiles] = useState({
    total: 0,
    data: [],
  });
  const [openAddFile, setOpenAddFile] = useState(false);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false; // ครั้งแรกไม่ต้องเคลียร์
      return;
    }

    // ถ้า `type_request` มีการเปลี่ยนแปลง (ไม่ใช่ครั้งแรก) ให้เคลียร์ข้อมูล
    setDataOtherFiles({
      total: 0,
      data: [],
    });
  }, [type_request]); //Trigger เฉพาะเมื่อ type_request เปลี่ยน

  // ตรวจสอบ values และอัปเดต dataOtherFiles
  useEffect(() => {
    console.log("values=>", values?.attachments);
    if (Array.isArray(values?.attachments) && values?.attachments?.length > 0) {
      setDataOtherFiles((prevData) => {
        // กรองข้อมูลใหม่ที่ยังไม่มีใน dataOtherFiles
        const newFiles = values.attachments
          .filter(
            (item) =>
              item.is_more &&
              !prevData.data.some((existingFile) => existingFile.id === item.id) // ตรวจสอบว่า id ยังไม่มีใน dataOtherFiles
          )
          .map((file) => ({
            id: file.id,
            typeFile: { title: file.template_attachment.title },
            file_name: file.file_name,
            size: file.file_size_label,
            fname: file.fname,
            lname: file.lname,
            policestation: { ORG_ABBR: file.policestation.ORG_ABBR },
            created_at: file.created_at,
            is_more: file.is_more,
            is_delete: file.is_delete,
            is_add: false,
            url_download: file.url_download,
          }));

        if (newFiles.length > 0) {
          return {
            total: prevData.total + newFiles.length,
            data: [...prevData.data, ...newFiles], // เพิ่มข้อมูลใหม่
          };
        }

        // ไม่มีข้อมูลใหม่
        return prevData;
      });
    }
  }, [values?.attachments]);

  useEffect(() => {
    console.log("request_account_HR03=>", request_account_HR03);
  }, [request_account_HR03]);

  // เพิ่มไฟล์ใหม่
  const handleAddFile = (newFile) => {
    setDataOtherFiles((prevData) => {
      if (prevData.data.some((file) => file.id === newFile.id)) {
        console.warn("File already exists:", newFile.id);
        return prevData; // ไม่ทำอะไรหากข้อมูลมีอยู่แล้ว
      }

      return {
        total: prevData.total + 1,
        data: [
          ...prevData.data,
          {
            id: newFile.id, // ใช้ id จาก newFile
            is_add: true,
            ...newFile,
          },
        ],
      };
    });
  };

  // ลบไฟล์
  const handleDeleteFile = (index) => {
    setDataOtherFiles((prevData) => {
      const newData = [...prevData.data];
      newData.splice(index, 1); // ลบแถวที่ index ที่ระบุ

      return {
        total: prevData.total - 1,
        data: newData,
      };
    });

    // ทำให้ DataGrid รีโหลดโดยตั้งค่าข้อมูลเป็นว่างก่อน
    setTimeout(() => {
      setDataOtherFiles((prevData) => ({
        total: prevData.data.length,
        data: [...prevData.data],
      }));
    }, 0);
  };

  const previewFiles = useCallback(async (fileUrl, file_name) => {
    try {
      const res = await api.get(fileUrl, {
        responseType: "blob",
      });

      // ดึง content-type จาก header
      const contentType = res.headers["content-type"];
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: contentType })
      );

      console.log("fileUrl=>", file_name);

      // ตรวจสอบประเภทไฟล์จาก content-type
      if (contentType === "application/pdf") {
        // กรณีเป็น PDF
        const pdfWindow = window.open(url);
        pdfWindow.onload = () => {
          pdfWindow.print();
        };
      } else {
        // กรณีเป็นไฟล์ประเภทอื่น
        const link = document.createElement("a");
        link.href = url;

        // กำหนดนามสกุลไฟล์ตาม content-type
        // let extension = "";
        // switch (contentType) {
        //   case "image/jpeg":
        //     extension = ".jpg";
        //     break;
        //   case "image/png":
        //     extension = ".png";
        //     break;
        //   case "application/msword":
        //     extension = ".doc";
        //     break;
        //   case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        //     extension = ".docx";
        //     break;
        //   case "application/vnd.ms-excel":
        //     extension = ".xls";
        //     break;
        //   case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        //     extension = ".xlsx";
        //     break;
        //   default:
        //     extension = "";
        // }

        const fileName = `${file_name}`; // สามารถเปลี่ยนชื่อไฟล์ตามต้องการ
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("error=>", error);
    }
  }, []);

  // คอลัมน์ของ DataGrid
  const columns = [
    !isViewOnly
      ? {
          field: "actions",
          headerName: "actions",
          sortable: false,
          width: 80,
          renderCell: (params) => {
            if (!params || !params.row) {
              return null; // หรือ return <Typography>-</Typography>;
            }

            const index = params.api.getRowIndex(params.row.id);
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    title="ไม่มีสิทธิ์ลบรายการนี้ เนื่องจากไม่ใช่เจ้าของไฟล์"
                    arrow
                    disableHoverListener={params.row.is_delete}
                  >
                    <IconButton
                      type="button"
                      variant="contained"
                      size="small"
                      onClick={() => {
                        if (params.row.is_delete && !isViewOnly) {
                          handleDeleteFile(index);
                        }
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          color:
                            params.row.is_delete && !isViewOnly
                              ? "red"
                              : "gray",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            );
          },
        }
      : null,
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 100,
      renderCell: (params) => {
        return <>{params?.row?.is_add ? "-" : params?.row?.id}</>;
      },
    },
    {
      field: "typeFile",
      headerName: "ประเภทไฟล์",
      sortable: true,
      maxWidth: 220,
      minWidth: 220,
      renderCell: (params) => {
        // return <>{params?.row?.typeFile?.title ?? "-"}</>;
        return (
          <Typography variant="body2">
            {params?.row?.typeFile?.title ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "file_name",
      headerName: "ชื่อไฟล์",
      sortable: true,
      maxWidth: 220,
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            onClick={async () => {
              if (params?.row.url_download) {
                await previewFiles(
                  params?.row.url_download,
                  params?.row?.file_name
                );
              }
            }}
            sx={{
              cursor: "pointer",
              color: params?.row.url_download ? "primary.main" : "black",
              textDecoration: params?.row.url_download ? "underline" : "none",
              "&:hover": {
                opacity: 0.8,
              },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "200px", // หรือกำหนดขนาดตามที่ต้องการ
            }}
            title={params?.row?.file_name ?? "-"} // แสดงชื่อเต็มเมื่อ hover
          >
            {params?.row?.file_name ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "size",
      headerName: "ขนาดไฟล์",
      sortable: true,
      width: 100,
      renderCell: (params) => {
        return <>{params?.row?.size ?? "-"}</>;
      },
    },
    {
      field: "full_name",
      headerName: "ชื่อ พงส.ที่อัพไฟล์",
      sortable: true,
      maxWidth: 220,
      minWidth: 220,
      renderCell: (params) => {
        return <>{params?.row?.fname + " " + params?.row?.lname ?? "-"}</>;
      },
    },
    {
      field: "policestation.ORG_ABBR",
      headerName: "หน่วยงาน",
      sortable: true,
      minWidth: 150, // ความกว้างต่ำสุด 150 พิกเซล
      flex: 1, // ขยายตามเนื้อหาและพื้นที่ที่เหลือ
      headerAlign: "left", // จัดหัวคอลัมน์ชิดซ้าย
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
            }}
          >
            {params.row.policestation?.ORG_ABBR ?? "-"}
          </Typography>
        );
      },
    },
    {
      field: "created_at",
      headerName: "วันที่บันทึก",
      sortable: true,
      width: 150,
      valueFormatter: ({ value }) => {
        if (!value) {
          return undefined;
        }
        const date = parseISO(value);
        return isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : undefined;
      },
    },
  ]?.filter(Boolean);

  // ส่งข้อมูลกลับไปยัง parent component
  useEffect(() => {
    receiveData && receiveData(dataOtherFiles);
  }, [dataOtherFiles]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <LoadingButton
          size="small"
          variant="outlined"
          color="info"
          loading={isLoading}
          // onClick={() => historyRefetch()}
          startIcon={<Refresh />}
        >
          Reload
        </LoadingButton>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Box sx={{ height: "90%" }}>
        {((request && request_account_HR03.includes("upload")) ||
          (verify && verify_request_account_HR03_process.includes("upload"))) &&
        !isViewOnly ? (
          <Box sx={{ float: "right", mb: 2 }}>
            <Button
              variant="contained"
              startIcon={<NoteAddIcon />}
              color="primary"
              onClick={() => setOpenAddFile(true)}
              disabled={isViewOnly}
            >
              เพิ่มไฟล์
            </Button>
          </Box>
        ) : (
          ""
        )}

        <DataGrid
          key={dataOtherFiles?.data?.length}
          rowCount={dataOtherFiles?.data?.length ?? 0}
          disableColumnMenu
          columns={columns}
          rows={dataOtherFiles?.data?.filter((row) => row.id !== undefined)}
          loading={loading || isLoading}
          rowsPerPageOptions={[5, 10, 15, 20]}
          pagination
          paginationMode="client"
          page={page - 1}
          getRowId={(row) => row.id || Math.random()}
          pageSize={pageSize}
          onPageChange={(newPage) => setPage(newPage + 1)}
          onPageSizeChange={(pageSize) => setPageSize(pageSize)}
          components={{
            Toolbar: CustomToolbar,
          }}
          sx={{
            height: "100%",
            minHeight: "500px",
            width: "100%",
            mb: 2,
          }}
        />
      </Box>

      <Hr03ModalUpload
        open={openAddFile}
        setOpen={setOpenAddFile}
        handleCloseModal={() => setOpenAddFile(false)}
        loading={loading}
        typeCurrent={typeCurrent}
        handleAddFile={handleAddFile}
      />
    </>
  );
};

export default TableOtherFiles;
