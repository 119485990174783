import { Mail } from "@mui/icons-material";
import {
  Alert,
  Button,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../apis";
import FieldInput from "../../../form-fields/FieldInput";
import FieldSelect from "../../../form-fields/FieldSelect";
import SubmitBtn from "../../../SubmitBtn";

import { useDispatch } from "react-redux";
import { preAccountsActionsListBooking } from "../../../../ducks/requestAccountsPickerField";
import FieldAsyncAutoComplete from "../../../form-fields/FieldAsyncAutoComplete";
import FieldsignatureFileDropZone from "../../../form-fields/FieldsignatureFileDropZone";
import SignaturePreview from "./SignaturePreview";
import AccountRequestAMany from "./AccountRequestMany";
import letterTypes from "../../../../data/selects/letterTypes";

export default function RequestNewFormAMany({
  readOnly = false, // mycustom
  handleSubmit,
  errors,
  error,
  submitError,
  submitting,
  pristine,
  initialValues,
  submitFailed,
  modifiedSinceLastSubmit,
  form,
  values,
}) {
  const params = useParams();
  const caseId = parseInt(params.id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [bankAccounts, setBankAccounts] = useState([]);

  const [toastInfo, SetToastInfo] = useState(false);
  const handleToastInfoClose = (event, reason) => {
    SetToastInfo(false);
  };

  const bankOptions = useMemo(() => {
    const items = bankAccounts.map((item) => {
      return {
        value: item.bank?.id,
        label: `${item.bank?.name} (${item.bank?.short_name})`,
      };
    });
    return _.uniqBy(items, "value");
  }, [bankAccounts]);

  const sortBankAccounts = useMemo(() => {
    const bank = bankAccounts.filter((item) => item.bank_id === values.bank_id);
    const banks = bank.map((item) => {
      return {
        value: item.id,
        label: `${item.bank?.short_name} : ${item.number} : ${item.name}${
          item.type && item.type.type ? " (" + item.type.type + ")" : ""
        }`,
      };
    });
    return banks;
  }, [bankAccounts, values.bank_id]);

  const sortShowBank = useMemo(() => {
    return bankAccounts.filter((item) => item.bank_id === values.bank_id);
  }, [bankAccounts, values.bank_id]);

  const handleBankAccountChange = (value) => {
    let data = {
      sortBankAccounts,
      select: value,
    };
    dispatch(preAccountsActionsListBooking(data));
  };

  useEffect(() => {
    api.get(`/api/cases/${caseId}/bank_accounts`).then(({ data }) => {
      setBankAccounts(data.rows);
    });
  }, [caseId]);
  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Snackbar
          open={toastInfo ? true : false}
          autoHideDuration={6000}
          onClose={handleToastInfoClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {toastInfo?.type && (
            <Alert
              onClose={handleToastInfoClose}
              severity={toastInfo?.type || "warning"}
              sx={{ width: "100%" }}
            >
              {toastInfo?.message || ""}
            </Alert>
          )}
        </Snackbar>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Stack spacing={2} direction="row">
              <FieldSelect
                name="request_type_id"
                label="ประเภทคำขอ"
                options={letterTypes}
                readOnly
                required
                controlProp={{ sx: { width: "50%" } }}
              />
              <FieldSelect
                name="bank_id"
                label="ธนาคาร"
                options={bankOptions}
                readOnly={readOnly}
                required
                controlProp={{ sx: { width: "100%" } }}
                onChange={handleBankAccountChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider textAlign="center"></Divider>
          </Grid>
        </Grid>
        <Grid item ml={2} mt={2} justifyContent="rigth">
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="center">
              <AccountRequestAMany
                readOnly={readOnly}
                bankAccounts={sortShowBank}
                hiddenUserForm={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider textAlign="center" sx={{ mt: 2 }}>
                ข้อมูลพนักงานสอบสวน
              </Divider>
            </Grid>
            <Grid item xs={12} md={2}>
              <FieldInput
                name="rank"
                label="ยศ"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FieldInput
                name="fname"
                label="ชื่อ"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <FieldInput
                name="lname"
                label="สกุล"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="job_title"
                label="ตำแหน่ง"
                controlProps={{ fullWidth: true }}
                required
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldAsyncAutoComplete
                name="policestation_org_code"
                label="หน่วยงาน"
                required
                controlProp={{ fullWidth: true }}
                readOnly
                basePath="/api/policestations"
                requestParams={{ page_size: undefined, page: undefined }}
                // itemFilter={(row) =>
                //   !row.ORG_CODE?.startsWith("0") &&
                //   !row.ORG_CODE?.startsWith("1")
                // }
                itemFormat={(row) => {
                  return { value: row.ORG_CODE, label: row.ORG_ABBR };
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="email"
                label="email"
                required
                controlProps={{ fullWidth: true }}
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FieldInput
                name="pol_tel"
                label="โทรศัพท์"
                required
                controlProps={{ fullWidth: true }}
                readOnly
              />
            </Grid>
            <Grid item xs={12}>
              <FieldInput
                name="address"
                label="ที่อยู่"
                required
                controlProps={{ fullWidth: true }}
                readOnly={readOnly}
              />
            </Grid>
            <Grid container item xs={12} justifyContent={"center"}>
              <Grid item xs={12} lg={6}>
                <Typography variant="subtitle1">ลายเซ็น</Typography>
                {readOnly ? (
                  <SignaturePreview name="profile_signature" />
                ) : (
                  <FieldsignatureFileDropZone
                    name="profile_signature"
                    accept="image/jpeg, image/png, image/gif"
                    placeholder={
                      <>
                        Drag 'n' drop some files here, or click to select files{" "}
                        <br />
                        (*.jpg,*.gif,*.png) only
                      </>
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} margin={3}>
            <Divider textAlign="center"></Divider>
          </Grid>
          <Grid item md={12} sx={{ mt: 2 }}>
            <Stack direction="row" spacing={2}>
              {!readOnly && (
                <SubmitBtn
                  variant="contained"
                  startIcon={<Mail />}
                  submitting={submitting}
                  pristine={pristine}
                >
                  สร้างคำขอ
                </SubmitBtn>
              )}
              <Button
                type="button"
                variant="outlined"
                disabled={submitting}
                onClick={() => {
                  navigate(`/cases/${caseId}/mgt`);
                }}
              >
                {readOnly ? "ปิด" : "ยกเลิก"}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export const mapToInit = (item) => {
  return item;
};
